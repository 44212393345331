import React, { useEffect, useState } from 'react'
import { useModuleContext } from '../../context/ModuleProvider'
import axios from 'axios';
import './Instructors.css'

function Instructors() {
    let {url} = useModuleContext();
  let server = process.env.REACT_APP_SERVER_URL;
    const [instructors, setInstructors] = useState([])
  const [imgErrorCount, setImgErrorCount] = useState(0);

    useEffect(() => {

        axios({
          method: 'get',
          url: `${url}/teacher/teacher?limit=1000`,
          withCredentials: true,
        }).then((res) => {
          let instructors = res.data.data;
          instructors && setInstructors(instructors);
        });
      }, [url]);

      
  return (
  <div className='container'>
      <div className="row instructor_card-wrapper gx-2 gy-2 ">
       { instructors.length ? (
          instructors.map((instructor)=> {
            return (
              <div className="instructor_card col-md-4 col-sm-6 pb-4 mb-2">
                <div className="details d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={`${server}/images/instructor/${instructor.image_id}`}
                    className="instructor-image"
                    alt={instructor.name}
                    onError={({ currentTarget }) => {
                      if (imgErrorCount < 4) {
                        currentTarget.onerror = null;
                        currentTarget.src = `/images/instructor.png`;
                        setImgErrorCount((pre) => pre + 1);
                      }
                    }}
                  />
                </div>
                <div className='d-flex justify-content-center'>
                <h6 className="mb-0 mt-3">{`${instructor.first_name} ${instructor.middle_name} ${instructor.last_name} - ${instructor.subject_name}`}</h6>
                </div>
              </div>
            )
          })
       ) : ( <div>No Instructors</div>)
}
    </div>
  </div>

  )
}

export default Instructors