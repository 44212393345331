import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { useModuleContext } from "../../context/ModuleProvider";

function ZoomEsewaSuccess({ location }) {
  const [verifing, setVerifing] = useState(false);
  const [message, setMessage] = useState("");
  const { oid, amt, refId } = queryString.parse(location.search);
  let { moduleId, url } = useModuleContext();
  let history = useHistory();

  let datas = String(oid).split("-");

  var params = {
    amt: amt,
    rid: refId,
    pid: oid,
    referralCode: datas[datas.length - 1],
  };

  const verifyPayment = () => {
    setVerifing(true);
    axios({
      method: "post",
      url: `${url}/zoom-plan-payment/esewa-pay-zoom`,
      data: params,
      withCredentials: true,
    })
      .then((res) => {
        setVerifing(false);
        if (res.data.error) {
          setMessage(res.data.message);
        } else {
          let paymentId = res.data.payment && res.data.payment.id;
          history.replace(
            `/${moduleId}/payment/detail?zoomPaymentId=${paymentId}`,
            {}
          );
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setVerifing(false);
      });
  };

  useEffect(() => {
    if (oid && amt && refId) {
      verifyPayment();
    }
  }, []);

  return (
    <div>
      {verifing && (
        <div>
          Please wait verifing the payment.
          {message && (
            <Link to={`/${moduleId}/payment/esewa`}>
              <button>Please try again </button>
            </Link>
          )}
        </div>
      )}
      <div>{message}</div>
    </div>
  );
}

export default withRouter(ZoomEsewaSuccess);
