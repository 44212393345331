import React, { Suspense } from 'react';
import { NavFooter } from './Wrappers';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Instructors from '../Instructors/Instructors';
import Practice from '../Practice/Practice';
import Test from '../Test/Test';
import Discussion from '../Discussion/Main/Discussion';
import Profile from '../Profile/Profile';
import NoMatch from './NoMatch';
import Preloader from '../Preloader/Preloader';
import ModuleHome from '../ModuleHome';
import DashboardQuestion from '../Dashboard/DashboardQuestion';
import AddDiscussion from '../Discussion/AddDiscussion';
import PracticeQuestion from '../Practice/PracticeQuestion';
import PracticeUnit from '../Practice/PracticeUnit';
import Revision from '../Revision/Revision';
import TestInstruction from '../Test/TestInstruction';
import QuestionDayPractice from '../Practice/QuestionDayPractice';
import AR from '../AR/AR';
import ARDetail from '../AR/ARDetail';
import VideoMenu from '../Video/VideoMenu';
import VideoSingle from '../Video/VideoSingle';
import VideoCategory from '../Video/VideoCategory';
import VideoLive from '../Video/VideoLive';
import PDFSingle from '../PDF/PDFSingle';
import SeeChapter from '../Practice/SeeChapter';
import DiscussionCategory from '../Discussion/Category/DiscussionCategory';
import DiscussionRef from '../Discussion/DiscussionRef';
import Feedback from '../ui//Feedback';
import TestResult from '../Test/TestResult';
// import DemoStreaming from "../TeacherPoratal/Video/DemoStreaming";
import UserResult from '../Test/UserResult';
import SeeUnit from '../Practice/SeeUnit';
import Announcement from '../Announcement/Announcement';
import AnnouncementDetail from '../Announcement/AnnouncementDetail';
import Khalti from '../Khalti';
import Esewa from '../Esewa';
import YoutubeLive from '../Video/YoutubeLive';
import CourseDetail from '../Practice/CourseDetail';
import UnitDetail from '../Practice/UnitDetail';
import PracticeArchive from '../Practice/PracticeArchive';
import Payment from '../Payment/Payment';
import QBank from '../QBank/QBank';
import QBankQuestion from '../QBank/QBankQuestion';
import VideoQuestion from '../Video/VideoQuestion';
import VideoQuestionArchive from '../Video/VideoQuestionArchive';
import PaymentList from '../Payment/PaymentList';
import EsewaSuccess from '../Payment/EsewaSuccess';
import KhaltiSuccess from '../Payment/KhaltiSuccess';
import PaymentDetail from '../Payment/PaymentDetail';
import AccountSettings from '../Profile/AccountSettings';
import NotificationTab from '../Profile/NotificationTab';
import ActivityTab from '../Profile/ActivityTab';
import FAQTab from '../Profile/FAQTab';
import Notification from '../Notification/Notification';
import Live from '../Live/Live';
import ZoomSDK from '../Live/ZoomSDK';
import ZoomPayment from '../Payment/ZoomPayment';
import ZoomEsewaSuccess from '../Payment/ZoomEsewaSuccess';
import ZoomKhaltiSuccess from '../Payment/ZoomKhaltiSuccess';
import TestPayment from '../Payment/TestPayment';
import TestKhaltiSuccess from '../Payment/TestKhaltiSuccess';
import TestEsewaSuccess from '../Payment/TestEsewaSuccess';
import NotificationList from '../Notification/NotificationList';
import NotificationDetail from '../Notification/NotificationDetail';
import LiveVideos from '../LiveVideos/LiveVideos';
import PlayVideo from '../LiveVideos/PlayVideo';

function HomeRoute() {
  return (
    <Suspense fallback={<Preloader />}>
      <Switch>
        <Route exact path={`/:moduleId`}>
          <NavFooter mainroute title="Home">
            <ModuleHome />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/notification`}>
          <NavFooter mainroute title="Notification">
            <Notification />
          </NavFooter>
        </Route>

        <Route exact path={`/:moduleId/khalti`}>
          <NavFooter mainroute title="Home">
            <Khalti />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/esewa`}>
          <NavFooter mainroute title="Home">
            <Esewa />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/esewa/success`}>
          <NavFooter mainroute title="Home">
            <EsewaSuccess />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/esewa/test-success`}>
          <NavFooter mainroute title="Home">
            <TestEsewaSuccess />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/esewa/zoom-success`}>
          <NavFooter mainroute title="Home">
            <ZoomEsewaSuccess />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/khalti/success`}>
          <NavFooter mainroute title="Home">
            <KhaltiSuccess />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/khalti/test-success`}>
          <NavFooter mainroute title="Home">
            <TestKhaltiSuccess />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/khalti/zoom-success`}>
          <NavFooter mainroute title="Home">
            <ZoomKhaltiSuccess />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/payment`}>
          <Payment />
        </Route>
        <Route exact path={`/:moduleId/test-payment`}>
          <TestPayment />
        </Route>
        <Route exact path={`/:moduleId/zoom-payment`}>
          <ZoomPayment />
        </Route>
        <Route exact path={`/:moduleId/payment/detail`}>
          <PaymentDetail />
        </Route>
        <Route exact path={`/:moduleId/payment/list`}>
          <PaymentList />
        </Route>
        {/* <Route exact path={`/:moduleId/demoStreaming`}>
          <NavFooter title="Dashboard">
            <DemoStreaming />
          </NavFooter>
        </Route> */}
        <Route exact path={`/:moduleId/dashboard`}>
          <NavFooter mainroute title="Dashboard">
            <Dashboard />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/instructors/all`}>
          <NavFooter title="Our Instructors">
            <Instructors />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/announcement`}>
          <NavFooter mainroute title="Announcements">
            <Announcement />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/announcement/detail`}>
          <NavFooter title="Announcement">
            <AnnouncementDetail />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/notification-list`}>
          <NavFooter mainroute title="Notifications">
            <NotificationList />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/notification/detail`}>
          <NavFooter title="Notification">
            <NotificationDetail />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/dashboard/:type`}>
          <NavFooter title="Question">
            <DashboardQuestion />
          </NavFooter>
        </Route>

        <Route exact path={`/:moduleId/profile/share`}>
          <NavFooter mainroute title="Profile">
            <Profile />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/profile`}>
          <NavFooter mainroute title="Profile">
            <Profile />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/qbank/`}>
          <NavFooter mainroute title="Question Bank">
            <QBank />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/qbank/start/`}>
          <NavFooter title="Question Bank">
            <QBankQuestion />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/discussion/`}>
          <NavFooter mainroute title="Discussion">
            <Discussion />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/discussion/detail`}>
          <NavFooter title="Discussion Detail">
            <DiscussionCategory />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/discussion/ref`}>
          <NavFooter title="smartacademy">
            <DiscussionRef />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/discussion/add`}>
          <NavFooter title="Add Discussion">
            <AddDiscussion />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/practice/`}>
          <NavFooter mainroute title="Practice">
            <Practice />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/start/`}>
          <NavFooter title="Practice">
            <PracticeQuestion />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/subject/`}>
          <NavFooter title="Practice">
            <PracticeUnit />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/seechapter/`}>
          <NavFooter title="Practice">
            <SeeChapter />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/unitDetail/`}>
          <NavFooter title="Practice">
            <UnitDetail />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/chapter/detail`}>
          <NavFooter title="Practice">
            <CourseDetail />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/archive`}>
          <NavFooter title="Practice">
            <PracticeArchive />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/seeUnit/`}>
          <NavFooter title="Practice">
            <SeeUnit />
          </NavFooter>
        </Route>
        <Route path={`/:moduleId/practice/questionofday/`}>
          <NavFooter title="Practice">
            <QuestionDayPractice />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/test/`}>
          <NavFooter mainroute title="Test">
            <Test />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/test/result`}>
          <NavFooter title="Test Result">
            <TestResult />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/test/userResult`}>
          <NavFooter title="User Test Result">
            <UserResult />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/test/instruction/`}>
          <NavFooter title="Test Instruction">
            <TestInstruction />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/revision`}>
          <NavFooter title="Revision">
            <Revision />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/live`}>
          <NavFooter title="Live">
            <Live />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/live-videos`}>
          <NavFooter title="Live Videos">
            <LiveVideos />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/recording`}>
          <NavFooter title="Live Recording">
            <PlayVideo />
          </NavFooter>
        </Route>
        <Route exact path={`/live/meeting`}>
          <ZoomSDK />
        </Route>
        <Route exact path={`/:moduleId/recording`}>
          <NavFooter title="Live Recording">
            <PlayVideo />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/live/meeting`}>
          <ZoomSDK />
        </Route>
        <Route exact path={`/:moduleId/ar`}>
          <NavFooter>
            <AR />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/ar/fullpage`}>
          <NavFooter>
            <ARDetail />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/video`}>
          <NavFooter mainroute title="Video">
            <VideoMenu />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/video/single`}>
          <NavFooter title="Video">
            <VideoSingle />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/pdf/single`}>
          <NavFooter title="PDF">
            <PDFSingle />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/video/question`}>
          <NavFooter mainroute title="Video">
            <VideoQuestion />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/video/question/archive`}>
          <NavFooter title="Video">
            <VideoQuestionArchive />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/video/youtube-live`}>
          <NavFooter>
            <YoutubeLive />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/video/live`}>
          <NavFooter>
            <VideoLive />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/video/category`}>
          <NavFooter>
            <VideoCategory />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/profile/feedback`}>
          <NavFooter title="Feedback">
            <Feedback />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/profile/setting`}>
          <NavFooter title="Setting">
            <AccountSettings />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/profile/activity`}>
          <NavFooter title="Activity">
            <ActivityTab />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/profile/notification`}>
          <NavFooter title="Notification">
            <NotificationTab />
          </NavFooter>
        </Route>
        <Route exact path={`/:moduleId/profile/faqs`}>
          <NavFooter title="FAQ">
            <FAQTab />
          </NavFooter>
        </Route>
        <Route path="*">
          <NavFooter>
            <NoMatch />
          </NavFooter>
        </Route>
      </Switch>
    </Suspense>
  );
}

HomeRoute.displayName = 'smartacademy';

export default HomeRoute;
