import React from "react";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContextProvider";
import { useModuleContext } from "../../context/ModuleProvider";
import { ProfileImg } from "../helper";

function NavHeader({ title, mainroute }) {
  let history = useHistory();
  return (
    <div
      className="main-header navbar navbar-expand bg-white navbar-light border-bottom"
      style={{ height: "68px" }}
    >
      <table>
        <tbody>
          <tr>
            <td className="d1">
              {mainroute ? (
                <Link
                  to="#"
                  className="nav-lin k d-lg-none"
                  data-widget="pushmenu"
                >
                  <i
                    style={{ marginLeft: "-20px", marginRight: "25px" }}
                    className="flaticon-sidebar"
                  ></i>
                </Link>
              ) : (
                <div onClick={() => history.goBack()} className="nav-link">
                  <i
                    style={{ marginLeft: "-8px" }}
                    className="fa fa-chevron-left"
                  ></i>
                </div>
              )}
            </td>
            <td className="d2">
              <strong style={{ marginLeft: "-15px" }}>
                {title || "smart"}
              </strong>
            </td>
            <td className="d3">
              <Switch>
                <Route path="/:moduleId/dashboard/calendar">
                  <UserProfile />
                </Route>
                <Route path="/:moduleId/dashboard/:type">
                  <FilterButton />
                </Route>
                <Route path="*">
                  <UserProfile />
                </Route>
              </Switch>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function FilterButton() {
  return (
    <Link
      to="#"
      className="btn-link btn-success btn-sm py-1 px-3 mt-1"
      data-toggle="modal"
      data-target="#exampleModal"
      style={{ fontSize: "20px", boxShadow: "none" }}
    >
      <i className="ion-ios-settings" />
    </Link>
  );
}

function UserProfile() {
  let { currentUser } = useAuthContext();
  let { moduleId } = useModuleContext();
  let { imageId } = currentUser;

  return (
    <Link to={`/${moduleId}/profile`}>
      <ProfileImg
        src={imageId}
        alt="User Profile "
        className="brand-image img-circle elevation-3 div-only-mobile bg-cover"
        style={{
          marginTop: "0px",
          height: "35px",
          width: "35px",
        }}
      />
    </Link>
  );
}

export default NavHeader;
