async function getAvailableModule() {
  return await fetch(`${process.env.REACT_APP_SERVER_URL}/course-list/available`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data.array;
    });
}

export let availableModule = getAvailableModule();

export let name = 'paradise';
