import React, { useEffect } from 'react';
import Module from './Components/routes/Module';
import bootbox from 'bootbox';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { loadProgressBar } from 'axios-progress-bar';
import AuthContextProvider from './context/AuthContextProvider';
import NetworkContextProvider from './context/NetworkContextProvider';
import Login from './Components/Login/Login';
import Verify from './Components/Verify';
import UserRoute from './Components/routes/UserRoute';
import Home from './Components/Home/Home';
import CourseMain from './Components/Home/CourseMain';
import Enroll from './Components/Home/Enroll';
import CourseSelect from './Components/Home/CourseSelect';
import Landing from './Components/Home/Landing';
import LiscenseExam from './Components/Home/LiscenseExam';
import { Analytics } from './Components/Analytics';
import axios from './axiosapi';
import 'axios-progress-bar/dist/nprogress.css';
// import { MeetingContextProvider } from './context/MeetingContextProvider';

// import { ZoomMtg } from '@zoomus/websdk';

// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();

function App() {
  const handleLocationChange = (m, callback) => {
    bootbox.confirm({
      size: 'small',
      title: "You're quiting. Are you sure?",
      message: m,
      callback: function (result) {
        callback(result);
      },
    });
  };
  useEffect(() => {
    loadProgressBar();
  });
  return (
    <NetworkContextProvider>
      <AuthContextProvider>
        {/* <MeetingContextProvider> */}
        <Router getUserConfirmation={(m, callback) => handleLocationChange(m, callback)}>
          <Switch>
            <UserRoute exact path="/">
              <Home />
            </UserRoute>
            <UserRoute exact path="/a/:userId">
              <Analytics />
            </UserRoute>

            <Route path={`/login/`}>
              <Login />
            </Route>
            <Route path={`/verify/`}>
              <Verify />
            </Route>
            <Route path={`/courseMain`}>
              <CourseMain />
            </Route>
            <Route path={`/enroll`}>
              <Enroll />
            </Route>
            <Route path={`/courseSelect/`}>
              <CourseSelect />
            </Route>
            <Route path={`/landing/`}>
              <Landing />
            </Route>
            <Route path={`/liscenseExam/`}>
              <LiscenseExam />
            </Route>
            <UserRoute path="/:moduleId">
              <Module />
            </UserRoute>
            <Route path={'*'}>
              <Home />
            </Route>
          </Switch>
        </Router>
        {/* </MeetingContextProvider> */}
      </AuthContextProvider>
    </NetworkContextProvider>
  );
}

export default App;
