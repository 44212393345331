import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Notification.css';
import { useModuleContext } from '../../context/ModuleProvider';

function NotificationList() {
  let { moduleId, url } = useModuleContext();
  let [announcement, setAnnouncement] = useState(null);
  let [loading, setLoading] = useState(false);
  console.log(announcement, 'announcement');
  useEffect(() => {
    setLoading(true);
    axios({
      method: 'get',
      url: `${url}/announcement/notification`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      setAnnouncement(res.data.notification);
    });
  }, []);

  let colors = ['pink', 'green', 'orange'];
  return (
    <div className="w-100 px-sm-3 px-2" style={{ marginTop: '-17px' }}>
      <div className="key__dates">
        <div className="header">
          <div className="color-overlay">
            <div>
              <div className="day-number">{moment().format('DD')}</div>
              <div className="date-right">
                <div className="day-name">{moment().format('dddd')}</div>
                <div className="month px-0">{moment().format('MMM YYYY')}</div>
              </div>
              <div className="today display-3 d-inline-block">Today</div>
            </div>
            <h5 className="text-gray-dark px-1">Key Dates</h5>
          </div>
        </div>
        <div className="timeline">
          <ul>
            {!loading ? (
              announcement && announcement.lenth !== 0 ? (
                announcement.map((a) => {
                  let { id, title, about, eventDate } = a;
                  return (
                    <li key={id}>
                      <div className={`bullet ${colors[id % 3]}`} />
                      <div className="time">{moment(eventDate).format('MMMM Do YYYY')}</div>
                      <div className="desc">
                        <h3>
                          <Link className="text-primary" to={`/${moduleId}/notification/detail?id=${id}`}>
                            {title}
                          </Link>
                        </h3>
                        <h4>{about}</h4>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="center">Notifications not Found. Please check later.</div>
              )
            ) : (
              <div>Loading...</div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NotificationList;
