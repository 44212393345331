import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ProfileImg } from '../helper';
import './PollCard.css';

function PollCard({ poll, votePoll, userId, deletePoll }) {
  let {
    user: { name, imageId },
    expireDate,
    date,
    total,
    liked,
  } = poll;
  let names = ['A', 'B', 'C', 'D'];

  return (
    <div className="pollInstructions bg-white px-sm-3 px-1 pt-sm-3 pt-1">
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex ">
          <div>
            <ProfileImg src={imageId} alt="profile" className="img-circle comment-photo mr-2" />
          </div>
          <div>
            <p className="mb-0 name__is">{name}</p>
            <p className="small text-muted mb-0 d-block pl-0">{moment(date).format('MMM DD YYYY')}</p>
          </div>
        </div>

        <div className="dropdown first-list-single">
          <button
            className="btn btn-transparent dropdown-toggle"
            type="button"
            id={`poll${poll.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-ellipsis-v" />
          </button>
          <div className="dropdown-menu" aria-labelledby={`poll${poll.id}`}>
            {parseInt(userId) === parseInt(poll.userId) && (
              <div className="dropdown-item" onClick={(e) => deletePoll(e, poll.id)}>
                delete
              </div>
            )}
            <Link className="dropdown-item" to="#">
              report
            </Link>
          </div>
        </div>
      </div>
      <div className="poll__discus">
        <div className="votes__on">
          <div className="poll">
            <div className="p-title">
              <h6 className="d-inline-block">{poll.question}</h6>
            </div>
            {poll.options.map((o, index) => {
              let percent = total && total !== 0 ? ((o.vote * 100) / total).toFixed(0) : 0;
              let width = percent / 2;
              return (
                <div key={o.id} className="poll-item clearfix">
                  <button
                    onClick={() => votePoll(poll.id, o.id)}
                    className={`btn  markee p${index + 1}  ${o.liked ? 'btn-voted disabled' : 'btn-outline-dark'}`}
                  >
                    {names[index]}
                  </button>
                  <label>{o.option}</label>
                  <span className={`p${index + 1}`} style={{ width: `${width}%`, minWidth: '38px' }}>
                    {percent}%
                  </span>
                </div>
              );
            })}
            <div className="poll__expire">
              <p className="small text-muted d-inline"></p>
              <div className="float-right">
                <p className="text-main" id="vote-count">
                  {total} Students Voted
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PollCard;
