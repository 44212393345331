import React, {useEffect, useState} from 'react'
import { Document, Page } from 'react-pdf';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import { pdfjs } from 'react-pdf';
import './PDFSingle.css';
import { useModuleContext } from '../../context/ModuleProvider';
import axios from 'axios';
import Preloader from '../Preloader/Preloader';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function PDFSingle({location}) {
  const {moduleId, url} = useModuleContext();
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfList, setPdfList] =useState([])
  const [pdfLink, setPdfLink] = useState('');
  const {id, subjectId} = queryString.parse(location.search);
  const [pdfId, setPdfId] = useState(id)
  const [totalPages, setTotalPages] = useState(0);

  function onDocumentLoadSuccess(response) {
    setTotalPages(response.numPages);
    setPageNumber(1);
  }
  function handleNext() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }
  function handlePrevious() {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  }
useEffect(()=> {
  axios({
    method: 'get',
    url: `${url}/file/single?id=${pdfId}`,
    withCredentials: true,
  }).then((res) => {
    setPdfLink(res.data.signedUrl)
  })
},[pdfId])
useEffect(()=> {
  axios({
    method: 'get',
    url : `${url}/file/resource?moduleId=${moduleId}&unitId=${subjectId}`,
    withCredentials: true,
  }).then((res)=> {
    setPdfList(res.data.file);
  })
}, [])
const handleClick = (id) => {
  if(id) {
   setPdfId(id)
  }
}
  return (
   <div className='row'>
    <div className='col-sm-12 col-12 px-1'>
        <div className='row'>
          <div className='col-sm-8 px-0 col-12 pdf_container' style={{height: '100vh', overflowY: 'scroll'}}>
          {
            totalPages > 0 && 
            <React.Fragment>
              <p>
                Page {pageNumber} of {totalPages}
              </p>
              <div className="pb-2">
                <button onClick={handlePrevious} className={`btn btn-primary  py-1 w-10 radius-5 mt-1 mt-sm-0 mr-3  ${pageNumber === 1 && `disabled`} `} disabled={pageNumber === 1}>
                  ‹ Previous
                </button>
                <button onClick={handleNext} className={`btn btn-primary py-1 w-10 radius-5 mt-1 mt-sm-0 ${pageNumber === totalPages && `disabled`}`} disabled={pageNumber === totalPages}>
                  Next ›
                </button>
              </div>
            </React.Fragment>

          }
            <Document onLoadSuccess={onDocumentLoadSuccess}  file={pdfLink} style={{width:"100%"}} loading={<Preloader />  } noData=''>
          <Page pageNumber={pageNumber} />
          </Document>
          </div>
          {window.innerWidth > 574 && (
              <div className="pdf-sidebar col-sm-4  px-sm-2 px-0">
                <h5 className='pdf-header mb-3'>All PDFs</h5>
                {
                  pdfList.map(item => {
                    return (
                      <div className='pdf_list-container d-flex align-items-center mb-2 rounded-4 p-1 cursor-pointer' onClick={()=>handleClick(item.id)}>
                        <div>
                          <img src="/images/pdf-thumbnail.png" className='mr-1' width={40} alt="img" />
                        </div>
                        <div>
                          <p>{item.title}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )}
        
        </div>
    </div>
   </div>
  )
}

export default withRouter(PDFSingle);