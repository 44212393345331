import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import TestNotFound from "./TestNotFound";
import Preloader from "../Preloader/Preloader";
import queryString from "query-string";
import ResultQuestionCard from "./ResultQuestionCard";
import { useModuleContext } from "../../context/ModuleProvider";
import "./Test.css";

function UserResult({ location }) {
  const { url } = useModuleContext();
  const [questions, setQuestions] = useState(null);
  let testId = queryString.parse(location.search).testId;
  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/test/userResult?testId=${testId}`,
      withCredentials: true,
    }).then((res) => {
      setQuestions(res.data.questions || []);
    });
  }, [url]);
  return (
    <section className="content p-1">
      {questions ? (
        <div className="container-fluid">
          <div className="row">
            {questions.length !== 0 ? (
              questions.map((question, index) => (
                <ResultQuestionCard
                  key={question.id}
                  question={question}
                  count={questions.length}
                  index={index + 1}
                />
              ))
            ) : (
              <TestNotFound />
            )}
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </section>
  );
}

export default withRouter(UserResult);
