import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/AuthContextProvider";
import { useModuleContext } from "../../context/ModuleProvider";

function ProfileUpdate() {
  let { currentUser, updateProfile } = useContext(AuthContext);
  let { moduleId } = useModuleContext();
  const { register, handleSubmit, errors } = useForm();
  let [error, setError] = useState("");
  let [info, setInfo] = useState("");

  const submitForm = async (data) => {
    let res = await updateProfile(data, moduleId);
    if (res.error) {
      setError(res.error);
      setInfo("");
    } else if (res.message) {
      setInfo(res.message);
      setError("");
    }
    if (res && res.user) {
    }
  };
  return (
    <div className="row">
      <div className="col-md-6 col-sm-6 col-12">
        <form
          onSubmit={handleSubmit(submitForm)}
          className="px-sm-3 px-0 py-sm-2 py-0"
        >
          <div className="text-danger text-left px-2 py-1">{error}</div>
          <div className="form-group">
            <label className="col-sm-2 control-label" htmlFor="name">
              Name
            </label>
            <div className="col-sm-12">
              <input
                ref={register({
                  required: "Name is required",
                  minLength: {
                    value: 4,
                    message: "Name should be at least of length 4",
                  },
                })}
                defaultValue={currentUser.name}
                className="form-control"
                name="name"
                type="text"
                id="name"
                placeholder="Name"
              />
            </div>
            <div className="text-danger ml-2">
              {errors.name && errors.name.message}
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label" htmlFor="email">
              Email
            </label>
            <div className="col-sm-12">
              <input
                className="form-control"
                disabled
                id="email"
                name="email"
                type="email"
                defaultValue={currentUser.email}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label" htmlFor="phone">
              Mobile
            </label>
            <div className="col-sm-12">
              <input
                className="form-control"
                ref={register({
                  required: "Phone number is required",
                  minLength: {
                    value: 8,
                    message: "Invalid phone number",
                  },
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    message: "Invalid phone number",
                  },
                })}
                defaultValue={currentUser.phone}
                name="phone"
                type="phone"
                disabled={true}
              />
            </div>
            <div className="text-danger ml-2">
              {errors.phone && errors.phone.message}
            </div>
          </div>
          {info && <div className="text-success p-info px-2 pb-1">{info}</div>}
          <div className="form-group col-12">
            <div className="profile__page-card-ctr text-right">
              <button className="btn btn-dark w-100" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* <div className="col-md-4 col-sm-4 col-12">
        <img src="/images/profile/account.png" alt="profile" className="img-fluid  hide__img" />
      </div> */}
    </div>
  );
}

export default ProfileUpdate;
