import React, { useContext, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import { useForm } from 'react-hook-form';
// import bootbox from 'bootbox';

function LoginFrom({ history }) {
  const {
    login,
    // verifyUser
  } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm();
  // const [error, setError] = useState(null);
  // const [send, setSend] = useState(false);

  const submitForm = async (data) => {
    await login(data, history);
  };

  const [showPass, setShowPass] = useState(false);

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  return (
    <form className="authForm" id="loginForm" onSubmit={handleSubmit(submitForm)}>
      <div className="instructions__login">
        <div className="overlay__img d-lg-none d-md-none d-none">
          <Link to="/">
            <img style={{ position: 'relative' }} alt="smart Logo" src="/images/logo.png" />
          </Link>
        </div>

        <div className="overlay__img d-sm-none" style={{ marginTop: '-120px', marginBottom: '50px' }}>
          <Link to="/">
            <img style={{ position: 'relative', width: '300px' }} alt="smart Logo" src="/images/paradise.svg" />
          </Link>
          <br />
          <p style={{ fontSize: '16px', marginLeft: '1.2em' }}>Leading Edu-Tech Company </p>
        </div>

        <h2 className="Welcom mb-2 pb-0">Get Started!</h2>
        <p className="small text-muted pl-1">To continue, please enter your user credentials.</p>
      </div>
      {/* <div className="error">{error}</div> */}
      <div className={errors.phone ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          ref={register({
            required: 'Email Id is required',
            pattern: {
              value: /^9\d{9}|[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
              message: 'Invalid phone number',
            },
          })}
          // disabled={send}
          name="phone"
          type="text"
          id="phone"
          placeholder="Email Id or Phone number"
        />
        <label className="input-icon-label" htmlFor="email">
          <i className="fas fa-mobile-alt"></i>
        </label>
      </div>
      <div className="input-error">{errors.phone && errors.phone.message}</div>

      <div className={errors.password ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          ref={register({
            required: 'Password is required',
            // minLength: {
            //   value: 8,
            //   message: 'Invalid phone number',
            // },
            pattern: {
              value: /^.{6,}$/,
              message: 'Password must have at least 6 characters',
            },
          })}
          name="password"
          type={showPass ? 'text' : 'password'}
          // disabled={send}
          id="password"
          placeholder="Password"
        />
        <label className="input-icon-label" htmlFor="email">
          <i className={`fa ${showPass ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={togglePassword}></i>
        </label>
      </div>
      <div className="input-error">{errors.password && errors.password.message}</div>

      {/* {send && (
        <div className={errors.token ? 'errorfield inputGroup' : 'inputGroup'}>
          <input
            ref={register({ required: 'Otp code is required' })}
            name="token"
            type="text"
            id="token"
            placeholder="Otp code"
          />
          <label className="input-icon-label" htmlFor="token">
            <i className="fas fa-mobile-alt"></i>
          </label>
        </div>
      )}
      <div className="input-error">{errors.token && errors.token.message}</div> */}

      <div className="forgot__acc d-flex justify-content-end">
        <div className="remember d-inline-block">
          <Link to="/login/reset">
            <label className="forgot text-primary small" htmlFor="remember">
              Forgot Password?
            </label>
          </Link>
        </div>
      </div>
      <div className="pt-3 ">
        <div className="btn btn-block p-0 my-2">
          <button type="submit" id="login" className="btn btn-dark rounded shadow  mb-2">
            Login
          </button>
          <Link to="/login/register">
            <button className="btn btn-dark rounded shadow d-block" id="register">
              Create new account&nbsp;<i className="fas fa-arrow-circle-right"></i>
            </button>
          </Link>
        </div>
      </div>
    </form>
  );
}

export default withRouter(LoginFrom);
