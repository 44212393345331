import React from 'react';

function SubscriptionList({ subscriptionTypes, selectedType, setSelectedType, register, errors }) {
  if (!subscriptionTypes) {
    return <div>Subscription types not found.</div>;
  }
  if (subscriptionTypes.length === 0) {
    return <div>Not any subscription plan added.</div>;
  }
  return (
    <>
      {subscriptionTypes &&
        subscriptionTypes.map((type) => {
          let { id, period, amount, discount, title } = type;
          let selected = selectedType ? selectedType.id === id : false;
          return (
            <div key={id} className="">
              <div className="row">
                <div className="col-md-8 col-sm-7 col-6 py-sm-2 py-1 px-md-3 px-1 pr-sm-3 pr-2">
                  <input
                    type="radio"
                    className="burmanRadio__input"
                    id={`sub-${id}`}
                    onChange={(e) => {
                      console.log('Changed ', e.target.value);
                      setSelectedType(type);
                    }}
                    value={id}
                    checked={selected}
                    name="subscription"
                    ref={register({
                      required: 'Subscription field is required.',
                    })}
                  />
                  <label htmlFor={`sub-${id}`} className="burmanRadio__label mb-0">
                    <span className="fw-500">{title}</span>&nbsp;
                    {Number(discount) <= 0 ? (
                      <></>
                    ) : (
                      <span className="radius-none badge badge-pill badge-success d-sm-block d-none px-2 py-1 ml-sm-0 ml-3">
                        {discount}% OFF
                      </span>
                    )}
                  </label>
                  {/* <span className="d-inline-block float-right text-right" htmlFor={`sub-${id}`}>
                    <p className="text-muted d-block mb-0 pr-0">per month</p>
                    <h6 className="pr-0" style = {{fontWeight : 500}}>Rs. {amount / period}</h6>
                  </span> */}
                </div>
                <div
                  className="col-md-4 col-sm-5 col-6 py-sm-2 py-1 px-md-3 px-0 px-1 border-faint"
                  htmlFor={`sub-${id}`}
                >
                  <div className="text-right">
                    {Number(discount) <= 0 ? (
                      <h5 className="mb-0 pr-0" style={{ fontWeight: 500 }}>
                        &nbsp; Rs. {amount}
                      </h5>
                    ) : (
                      <h5 className="mb-0 pr-0" style={{ fontWeight: 500 }}>
                        <span className="old_amount" style={{ textDecoration: 'line-through' }}>
                          Rs. {amount + amount * (discount / 100)}
                        </span>
                        &nbsp; Rs. {amount}
                      </h5>
                    )}
                    <p className="text-muted d-block mb-0 pr-0">Total (Incl. of all charges)</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className="text-danger ml-2 small">{errors.subscription && errors.subscription.message}</div>
    </>
  );
}

export default SubscriptionList;
