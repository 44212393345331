import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import axios from 'axios';
import bootbox from 'bootbox';
import { ProfileImg } from '../helper';

function ProfileImage({ url }) {
  let { currentUser, updateCurrentUser } = useContext(AuthContext);
  let [selectedFile, setSelectedFile] = useState(false);
  let [uploaded, setUploaded] = useState(false);
  let [imageUrl, setImageUrl] = useState(false);
  let [loaded, setLoaded] = useState(false);
  const imageSelecter = React.useRef(null);
  let { imageId, name } = currentUser;

  const checkMimeType = (event) => {
    let files = event.target.files;
    let err = [];
    let val = true;
    const types = ['image/png', 'image/jpeg', 'image/gif'];
    for (var x = 0; x < files.length; x++) {
      if (types.every((type) => files[x].type !== type)) {
        err[x] = files[x].type + ' is not a supported. The image must be in png or jpeg or gif format\n';
        val = false;
      }
    }
    for (var z = 0; z < err.length; z++) {
      bootbox.alert(err[z]);
      event.target.value = null;
    }
    return val;
  };
  const maxSelectFile = (event) => {
    let files = event.target.files;
    if (files.length > 3) {
      const msg = 'You can only upload max 3 images at a time.';
      event.target.value = null;
      bootbox.alert(msg);
      return false;
    }
    return true;
  };
  const checkFileSize = (event) => {
    let files = event.target.files;
    let size = 2000000;
    let err = [];
    let val = true;
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + 'is too large, please pick a smaller file\n';
        val = false;
      }
    }
    for (var z = 0; z < err.length; z++) {
      bootbox.alert(err[z]);
      event.target.value = null;
    }
    return val;
  };

  const fileChangedHandler = (event) => {
    const [file] = event.target.files;
    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      console.log('Runned');
      let reader = new FileReader();
      setSelectedFile(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setUploaded(true);
    }
  };

  const uploadPicture = (event) => {
    console.log('upload picture called');
    event.preventDefault();
    const data = new FormData();
    data.append('file', selectedFile);
    console.log(data, selectedFile);
    axios({
      method: 'post',
      url: `${url}/profile/upload`,
      data,
      withCredentials: true,
      onUploadProgress: (ProgressEvent) => {
        setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          return bootbox.alert('Upload failed. Please try again.');
        } else {
          setUploaded(false);
          res.data.user && updateCurrentUser(res.data.user);
          bootbox.alert('Your profile picture has been changed successfully.');
        }
      })
      .catch((err) => {
        console.log(err);
        bootbox.alert('Upload fail');
      });
  };
  return (
    <>
      <div className="profile__page__picture">
        <ProfileImg src={imageId} alt="profile" />
      </div>
      <div className="profile__page__header">
        <div className="profile__page__account">
          <h4 className="profile__page__username d-block w-75 pb-1">{name}</h4>
        </div>
        {/* change profile pic */}
        {/* change profile apperas in big and icon in small */}
        <div className="profile__page__edit">
          <div
            onClick={uploaded ? uploadPicture : () => imageSelecter.current.click()}
            className="btn btn-outline-dark btn-sm"
            href="#"
          >
            <input
              type="file"
              id="FileUpload1"
              onChange={fileChangedHandler}
              ref={imageSelecter}
              name="userfile"
              style={{ display: 'none' }}
            />
            <span className="d-md-block d-none">{uploaded ? 'Save Profile' : 'Change Picture'}&nbsp;</span>
            <span className="d-md-none d-block">
              {uploaded ? <i className="fas fa-save" /> : <i className="fas fa-camera" />}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

const getProfileLink = (link) => {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  if (!link) {
    return '/images/profile.png';
  } else if (link.startsWith('https://')) {
    return link;
  } else {
    return `${serverUrl}/${link}`;
  }
};

export default ProfileImage;
