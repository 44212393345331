import React from 'react'

function TestNotFound() {
  return (

    <div className="col-12">
      <div
        className="tab-content text-center"
        id="nav-tabContent"
        style={{ paddingTop: "70px" }}
      >
        <img
          src="/images/no data.gif"
          alt="NOT FOUND"
          style={{ maxWidth: "320px", paddingBottom: "30px" }}
        />
        <div
          className="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
          style={{ paddingBottom: "40px" }}
        >
          <strong>Oops! it seems there is no test available for this category at the moment.
                <br />
          Please visit back later or try exploring other categories.</strong>
              </div>
      </div>
    </div>
  )
}

export default TestNotFound
