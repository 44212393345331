import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContextProvider';
import { concatText, ProfileImg } from '../helper';
import { useModuleContext } from '../../context/ModuleProvider';
import { BiBook } from 'react-icons/bi';
import { GoBroadcast } from 'react-icons/go';
import './Sidebar.css';

function Sidebar() {
  const { currentUser, logout } = useAuthContext();
  const { moduleId } = useModuleContext();
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4" style={{ minHeight: '548px' }}>
      {/* Brand Logo */}
      <NavLink style={{ display: 'flex', justifyContent: 'center', height: '68px' }} to={`/`} className="brand-link">
        <img
          src="/images/paradise.svg"
          alt="smart"
          className="brand-image top-logo d-block"
          style={{
            opacity: '.8',
            maxWidth: '200px',
            objectFit: 'contain',
            marginLeft: '0.3em',
          }}
        />
      </NavLink>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <NavLink to={`/${moduleId}/profile`} className="d-block">
            <ProfileImg
              alt="images"
              className="brand-link img-circle ml-3 bg-cover"
              style={{
                height: '40px',
                width: '40px',
                textAlign: 'center',
                border: '2px solid #e9ecef',
                padding: '1px',
                float: 'left',
              }}
              src={currentUser.imageId}
            />

            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="user-text" style={{ textAlign: 'start', marginLeft: '0.5rem' }}>
                {concatText(currentUser.name)}
                {/* <i className="flaticon-settings"></i> */}
              </span>
              <span className="user-text" style={{ textAlign: 'start', marginLeft: '0.5rem' }}>
                {moduleId}{" - "}
                {currentUser.registration_id
                  ? currentUser.registration_id
                  : concatText(currentUser.name.split(' ')[0], 12)
                }
              </span>
            </div>
          </NavLink>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2 pb-3" style={{ fontSize: '14px' }}>
          <ul
            id="nav"
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library */}
            <li className="nav-item" title="Dashboard">
              <NavLink to={`/${moduleId}/dashboard`} className="nav-link">
                <i className="nav-icon flaticon-home"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className="nav-item has-treeview" title="Course">
              <NavLink to={`/${moduleId}/practice`} className="nav-link">
                <i className="nav-icon flaticon-spots"></i>
                <p>Course</p>
              </NavLink>
            </li>

            {/* <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/qbank`} className="nav-link">
                <BiBook
                  className="ri-nav-icon"
                  color="rgb(151, 150, 150,0.9)"
                  size="20px"
                />
                <p>QBank</p>
              </NavLink>
            </li> */}
            <li className="nav-item has-treeview" title="Tests">
              <NavLink to={`/${moduleId}/test`} className="nav-link">
                <i className="nav-icon flaticon-tube"></i>
                <p>Model Exam</p>
              </NavLink>
            </li>
            {/* {moduleId !== 'nmcle' && (
              <li className="nav-item has-treeview">
                <NavLink to={`/${moduleId}/video`} className="nav-link">
                  <i className="nav-icon flaticon-play"></i>
                  <p>Video</p>
                </NavLink>
              </li>
            )} */}
            {/* <li className="nav-item has-treeview d-block d-md-none">
              <NavLink to={`/${moduleId}/ar`} className="nav-link">
                <i className="nav-icon flaticon-3d"></i>
                <p>3D Learn</p>
              </NavLink>
            </li> */}
            <li className="nav-item has-treeview" title="Discussion">
              <NavLink to={`/${moduleId}/discussion`} className="nav-link">
                <i className="nav-icon  flaticon-comment"></i>
                <p>Discussion</p>
              </NavLink>
            </li>
            {/* <li className="nav-item has-treeview" title="Plans">
              <NavLink to={`/${moduleId}/payment`} className="nav-link">
                <i className="nav-icon flaticon-tube"></i>
                <p>Plans</p>
              </NavLink>
            </li> */}
            {/* <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/revision`} className="nav-link">
                <i className="nav-icon flaticon-revision"></i>
                <p>Revision</p>
              </NavLink>
            </li> */}

            {/* <li className="nav-item has-treeview">
              <NavLink to={`/${moduleId}/live`} className="nav-link">
                <GoBroadcast
                  className="ri-nav-icon"
                  color="rgb(151, 150, 150,0.9)"
                  size="20px"
                />
                <p>Live</p>
              </NavLink>
            </li> */}

            <li className="nav-item" title="Logout">
              <Link className="nav-link" to="#" onClick={logout}>
                <i className="nav-icon  flaticon-logout text-danger"></i>
                <p className="text-danger">Logout</p>
              </Link>
            </li>
          </ul>
          <div className="d-flex align-items-end px-3 py-4">
            <p className="text-muted small fw-500">
              <span className="smart-section">Powered by</span> <a href="https://ayata.com.np">Ayata Inc.</a>
            </p>
          </div>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
