import React from 'react';
import MobileMenu from '../Navbar/MobileMenu';
import Footer from '../Navbar/Footer';
import Sidebar from '../Navbar/Sidebar';
import NavHeader from '../Navbar/NavHeader';

function withNavFooter(Component) {
  return (props) => {
    return (
      <div className="wrapper" style={{ width: '100vw' }}>
        <NavHeader {...props} />
        <Sidebar />
        <div
          className="content-wrapper page-wrapper"
          style={{
            minHeight: 'calc(100vh - 106px)',
          }}
        >
          <Component {...props} />
          <MobileMenu {...props} />
        </div>
        <Footer />
      </div>
    );
  };
}

function NavFooter({ children, title, mainroute }) {
  return (
    <div className="wrapper" style={{ width: '100vw' }}>
      <NavHeader mainroute={mainroute} title={title} />
      <Sidebar />
      <div
        className="content-wrapper page-wrapper"
        style={{
          minHeight: 'calc(100vh - 106px)',
        }}
      >
        {children}
        <MobileMenu mainroute={mainroute} title={title} />
        {!mainroute && (
          <div id="footer-menu" className="footer-menu-5-icons footer-menu-style-1 div-only-mobile">
            {/* <Adsense format={"fluid"} client={process.env.AD_CLIENT_ID} slot={process.env.AD_SLOT_ID} /> */}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export { withNavFooter, NavFooter };
