import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

function PracticeQuestionUI({
  handleChange,
  handleNext,
  handleSubmit,
  question,
  answer,
  submited,
  selected,
  addToRevision,
  questionOfDay,
}) {
  return (
    <section className="content" style={{ paddingBottom: '40px' }}>
      <div className="row">
        <div className="col-12 px-sm-2 px-0">
          <div className="card border-none" style={{ boxShadow: 'none' }}>
            <div>
              {question && (
                <form onSubmit={(e) => handleNext(e)} style={{ border: 'none' }}>
                  <div className="test-question">
                    {questionOfDay ? (
                      <span>Question Of Day</span>
                    ) : (
                      <span>Practice Question {question.count} of 2037</span>
                    )}
                    <h3 className="card-title pb-2">
                      <ReactMarkdown children={question.question} rehypePlugins={[rehypeRaw]} />
                    </h3>
                    <small className="text-muted">Choose the correct answer below:</small>
                  </div>
                  <Option
                    value="1"
                    name="A"
                    handleChange={handleChange}
                    option={question.optionA}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <Option
                    value="2"
                    name="B"
                    handleChange={handleChange}
                    option={question.optionB}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <Option
                    value="3"
                    name="C"
                    handleChange={handleChange}
                    option={question.optionC}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <Option
                    value="4"
                    name="D"
                    handleChange={handleChange}
                    option={question.optionD}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <br />
                  {submited && (
                    <div className="boxed">
                      <div>
                        <strong style={{ color: '#2ecc71' }}>The correct answer is:</strong>
                        <ReactMarkdown children={question[getOptionByValue(answer - 1)]} rehypePlugins={[rehypeRaw]} />
                      </div>
                      <div>
                        <ReactMarkdown children={question.description} rehypePlugins={[rehypeRaw]} />
                      </div>
                    </div>
                  )}
                  <br />
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="col-md-6 col-6" style={{ paddingLeft: '20px', paddingBottom: '10px' }}>
                        {!submited ? (
                          <button id="sub" onClick={(e) => handleSubmit(e)} className="btn btn-success">
                            Submit
                          </button>
                        ) : (
                          <button type="submit" id="next" onClick={handleNext} className="btn btn-info">
                            Next
                          </button>
                        )}
                      </div>
                      <div align="right" className="p-2 col-md-6 col-6 pull-right" style={{ marginTop: '-55px' }}>
                        {/* <div
                          className="btn flag"
                          style={{ color: "#f5378e", padding: "0px" }}
                          title="Flag this question"
                        >
                          <span className="fa-stack fa-lg">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-flag fa-stack-1x fa-inverse" />
                          </span>
                        </div> */}
                        <div
                          className="btn bookmark"
                          id="save"
                          style={{ color: 'var(--warning)', padding: '0px' }}
                          title="Mark for review"
                          onClick={addToRevision}
                        >
                          <span className="fa-stack fa-lg">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-bookmark fa-stack-1x fa-inverse" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PracticeQuestionUI;

function Option({ value, option, name, handleChange, selected, answer, submited, t }) {
  let wrong = submited && selected === value && answer !== value;
  let right = submited && answer === value;
  let addClass = wrong ? 'wrong' : '';
  addClass = right ? 'correct' : addClass;
  let id = 'answer' + value;
  return (
    <div className={`form-check ${addClass}`}>
      <input
        className="col-2 form-check-input option-input radio answer"
        type="radio"
        name="answer"
        id={id}
        onChange={handleChange}
        defaultValue={value}
        checked={value === selected}
        disabled={submited}
      />
      <label className="col-10 form-check-label" htmlFor={id}>
        <ReactMarkdown children={option} rehypePlugins={[rehypeRaw]} />
      </label>
    </div>
  );
}

const getOptionByValue = (value) => {
  const options = ['optionA', 'optionB', 'optionC', 'optionD'];
  return options[value];
};
