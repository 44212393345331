import React, { useRef } from 'react';
let serverUrl = process.env.REACT_APP_SERVER_URL;

const getProfileLink = (link) => {
  if (!link) {
    return '/images/profile.png';
  } else if (link.startsWith('https://')) {
    return link;
  } else {
    return `${serverUrl}/${link}`;
  }
};

const ProfileImg = ({ src, alt, ...props }) => {
  const profileRef = useRef();
  return (
    <img
      ref={profileRef}
      src={getProfileLink(src)}
      onError={() => {
        profileRef.current.src = '/images/profile.png';
      }}
      alt={alt || 'profile'}
      {...props}
    />
  );
};

const videoImgLink = (id) => {
  return `${serverUrl}/images/videos/${id}.png`;
};
const pdfImgLink = (id) => {
  return `${serverUrl}/images/videos/${id}.png`;
};
const courseImgLink = (id) => {
  return `${serverUrl}/images/videoSubjects/${id}.jpg`;
};

const concatText = (text, count) => {
  if (text.length > count) {
    return text.substring(0, count) + '...';
  }
  return text;
};

function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export { getProfileLink, ProfileImg, videoImgLink, pdfImgLink, courseImgLink, concatText, isIOS };
