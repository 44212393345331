import React from 'react'
import  { useTable } from 'react-table';
import { getProfileLink } from "../helper";
import "./ReactTable.css"


function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  console.log(rows, prepareRow, "ResutlTable");

  // Render the UI for your table
  return (
    <table  {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                // console.log(cell);
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function ResultTable({ data }) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Rank",
        id: "row",
        Cell: ({row}) => {
          console.log(row)
          return <div>{row.index + 4}</div>;
        }
      },
      {
        Header: 'Name',
        accessor: 'user',
        Cell: ({value}) => {
          console.log(value, "Name")

          return <div className="d-flex align-items-center">
          <img className="img-fluid mr-2 small__circle" alt={"p"} src={getProfileLink(value.imageId)} /> 
         <div>
           <p className="font-weight-bold mb-0">{value.name}</p>
         </div>
       </div>
        }
      },
      {
        Header: 'Score',
        accessor: 'cntCorrect',
        Cell: ({value}) => {
          console.log(data, "Rows")
          return <div>{value}</div>;
        }
      },

    ],
    []
  )


  return (
    <div className="ReactTable">
    <Table showPagination={false}
      columns={columns} data={data} />
      </div>
  )
}

export default ResultTable
