import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import bootbox from 'bootbox';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useModuleContext } from '../../context/ModuleProvider';
import Preloader from '../Preloader/Preloader';
import PNotFound from '../Practice/PNotFound';
import { decrypt } from '../../service/Encryption';

function VideoQuestion({ location, history }) {
  const { moduleId, url } = useModuleContext();
  const [question, setQuestion] = useState(null);
  const [selected, setSelected] = useState(null);
  const [submited, setSubmited] = useState(null);
  let [t, setT] = useState(1);
  const [loading, setLoading] = useState();
  let { videoId } = queryString.parse(location.search);

  useEffect(() => {
    fetchQuestion();
  }, [moduleId]);

  const fetchQuestion = () => {
    setLoading(true);
    setQuestion(null);
    let finalUrl = `${url}/video/question?videoId=${videoId}`;
    axios({
      method: 'get',
      url: `${finalUrl}`,
      withCredentials: true,
    }).then((res) => {
      let { question, redirect } = res.data;
      setLoading(false);
      if (question) {
        question = decrypt(question);
        question = JSON.parse(question);
        setQuestion(question);
      }
      if (redirect) {
        history.replace(`/${moduleId}/video/question/archive?videoId=${videoId}`);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('button pressed', selected);
    if (selected != null) {
      console.log('submit pressed');
      let data = {
        videoQuestionId: question.id,
        videoId,
        check: selected,
      };
      axios({
        method: 'post',
        url: `${url}/video/question/submit`,
        withCredentials: true,
        data,
      }).then((res) => {
        console.log(res.data, 'sumitted');
        if (res.data) {
          setSubmited(true);
        }
        if (!res.data.submited) {
          bootbox.alert('Question already submitted');
        }
      });
    } else {
      bootbox.alert('Please choose at least one of the given asnwers.');
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    if (!submited) {
      setSelected(e.target.value);
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    setT(++t);
    console.log('next button pressed');
    if (submited) {
      setSubmited(false);
      setSelected(null);
      fetchQuestion();
    }
  };

  const addToRevision = () => {
    let question_id = question.id;
    console.log('Add To revision clicked', question_id);
    let data = { question_id };
    axios({
      method: 'post',
      url: `${url}/revision`,
      withCredentials: true,
      data,
    }).then((res) => {
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      } else if (res.data.created) {
        bootbox.alert('Added Question to the Revision');
      } else {
        bootbox.alert('Question is alredy in the revision section');
      }
    });
  };

  const answer = question && question.answer;
  return !loading ? (
    question ? (
      <VideoQuestionUI
        handleSubmit={handleSubmit}
        handleNext={handleNext}
        handleChange={handleChange}
        question={question}
        answer={answer}
        addToRevision={addToRevision}
        selected={selected}
        submited={submited}
      />
    ) : (
      <PNotFound
        header="Sorry, we are out of question."
        title="Please check back later. In the meantime, you can practice other video question."
        link={`/${moduleId}/practice`}
      />
    )
  ) : (
    <Preloader />
  );
}

export default withRouter(VideoQuestion);

function VideoQuestionUI({
  handleChange,
  handleNext,
  handleSubmit,
  question,
  answer,
  submited,
  selected,
  addToRevision,
  questionOfDay,
}) {
  return (
    <section className="content" style={{ paddingBottom: '40px' }}>
      <div className="row">
        <div className="col-12  px-sm-2 px-0">
          <div className="card border-none" style={{ boxShadow: 'none' }}>
            <div>
              {question && (
                <form onSubmit={(e) => handleNext(e)} style={{ border: 'none' }}>
                  <div className="test-question">
                    <span>
                      Video Question {question.count} of {question.total}
                    </span>
                    <h3 className="card-title pb-2">
                      <ReactMarkdown children={question.question} rehypePlugins={[rehypeRaw]} />
                    </h3>
                    <small className="text-muted">Choose the correct answer below:</small>
                  </div>
                  <Option
                    value="1"
                    name="A"
                    handleChange={handleChange}
                    option={question.optionA}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <Option
                    value="2"
                    name="B"
                    handleChange={handleChange}
                    option={question.optionB}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <Option
                    value="3"
                    name="C"
                    handleChange={handleChange}
                    option={question.optionC}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <Option
                    value="4"
                    name="D"
                    handleChange={handleChange}
                    option={question.optionD}
                    selected={selected}
                    answer={answer}
                    submited={submited}
                  />
                  <br />
                  {submited && (
                    <div className="boxed">
                      <div>
                        <strong style={{ color: '#2ecc71' }}>The correct answer is:</strong>
                        <ReactMarkdown children={question[getOptionByValue(answer - 1)]} rehypePlugins={[rehypeRaw]} />
                      </div>
                      <div>
                        <ReactMarkdown children={question.description} rehypePlugins={[rehypeRaw]} />
                      </div>
                    </div>
                  )}
                  <br />
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="col-md-6 col-6" style={{ paddingLeft: '20px', paddingBottom: '10px' }}>
                        {!submited ? (
                          <button id="sub" onClick={(e) => handleSubmit(e)} className="btn btn-success">
                            Submit
                          </button>
                        ) : (
                          <button type="submit" id="next" onClick={handleNext} className="btn btn-info">
                            Next
                          </button>
                        )}
                      </div>
                      <div align="right" className="p-2 col-md-6 col-6 pull-right" style={{ marginTop: '-55px' }}>
                        <div
                          className="btn flag"
                          style={{ color: '#f5378e', padding: '0px' }}
                          title="Flag this question"
                        >
                          <span className="fa-stack fa-lg">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-flag fa-stack-1x fa-inverse" />
                          </span>
                        </div>
                        <div
                          className="btn bookmark"
                          id="save"
                          style={{ color: 'var(--warning)', padding: '0px' }}
                          title="Mark for review"
                          onClick={addToRevision}
                        >
                          <span className="fa-stack fa-lg">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-bookmark fa-stack-1x fa-inverse" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Option({ value, option, name, handleChange, selected, answer, submited, t }) {
  let wrong = submited && selected === value && answer !== value;
  let right = submited && answer === value;
  let addClass = wrong ? 'wrong' : '';
  addClass = right ? 'correct' : addClass;
  let id = 'answer' + value;
  return (
    <div className={`form-check ${addClass}`}>
      <input
        className="col-2 form-check-input option-input radio answer"
        type="radio"
        name="answer"
        id={id}
        onChange={handleChange}
        defaultValue={value}
        checked={value === selected}
        disabled={submited}
      />
      <label className="col-10 form-check-label" htmlFor={id}>
        <ReactMarkdown children={option} rehypePlugins={[rehypeRaw]} />
      </label>
    </div>
  );
}

const getOptionByValue = (value) => {
  const options = ['optionA', 'optionB', 'optionC', 'optionD'];
  return options[value];
};
