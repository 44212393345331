import React, { useState, useEffect } from "react";
import { useModuleContext } from "../../context/ModuleProvider";
import axios from "axios";
import Preloader from "../Preloader/Preloader";
import "./YoutubeLive.css";

function YoutubeLive() {
  let { url } = useModuleContext();
  let domain = window.location.hostname;
  let [youtubeLive, setYoutubeLive] = useState(null);
  let [loading, setLoading] = useState(false);
  // let id = "k6KcaMffxac";
  let id = youtubeLive ? youtubeLive.youtubeId : "";
  let videoSrc = `https://www.youtube.com/embed/${id}`;
  let chatSrc = `https://www.youtube.com/live_chat?v=${id};embed_domain=${domain}`;
  console.log(id, videoSrc);
  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: `${url}/video/youtube/live`,
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      console.log(res.data.youtubeLive, "Live");
      setYoutubeLive(res.data.youtubeLive);
    });
  }, []);

  return !loading || youtubeLive ? (
    <div className="w-100 p-0" id="dash__wrapper">
      <div className="row">
        <div className="col-md-8 col-12 px-0 live-main">
          <iframe
            title="Video"
            width="100%"
            height={600}
            src={videoSrc}
            frameBorder={0}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="col-md-4 px-1 pl-md-2 pr-md-0 col-12">
          <iframe
            style={{
              border: "2px solid transparent",
              boxShadow: "0px 1px 10px rgb(0 0 0 / 15%)",
            }}
            width="100%"
            title="chat"
            height={600}
            src={chatSrc}
          />
        </div>
      </div>
    </div>
  ) : (
    <Preloader />
  );
}

export default YoutubeLive;
