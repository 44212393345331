import React, { useContext } from 'react';
import { Switch, Route, Link, Redirect, withRouter } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgetForm from './ForgetForm';
import ResetForm from './ResetForm';
import { AuthContext } from '../../context/AuthContextProvider';
import { useEffect } from 'react';
import './Login.css';

function Login({ history }) {
  let { currentUser } = useContext(AuthContext);
  useEffect(() => {
    if (currentUser && currentUser.verified === 1) {
      history.push('/');
    }
  }, [currentUser]);
  return (
    <section id="login" className="makeCenter p-lg-5 p-md-4 p-sm-0 p-0">
      <div className="row d-flex-center">
        <div className="ipad-hide d-none d-lg-block d-md-block col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12 pr-0">
          <div className="overlay__img pt-md-5 pl-md-5 p-2 pt-4 mt-5">
            <Link className="d-lg-block d-none" to="/">
              <img alt="smart Logo" height={'auto'} width={'auto'} src="/images/paradise.svg" />
            </Link>
          </div>
          <div className="side__img">
            <img src="/images/formbg1.jpg" alt="IH" />
          </div>
        </div>
        <div className="login-only col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12 pl-0 pr-0 p-sm-4">
          <div className="login__elements">
            <Switch>
              <Route exact path="/login">
                <LoginForm />
              </Route>
              <Route path="/login/register">
                <RegisterForm />
              </Route>
              <Route path="/login/forget">
                <ForgetForm />
              </Route>
              <Route path="/login/reset">
                <ResetForm />
              </Route>
              <Route path="*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Login);
