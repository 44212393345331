import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AuthContext } from './AuthContextProvider';
import { availableModule } from '../config';
import moment from 'moment';

const ModuleContext = React.createContext();

export const ModuleProvider = ({ children }) => {
  const [currentModule, setCurrentModule] = useState(null);
  const [loading, setLoading] = useState(true);
  let { currentUser } = useContext(AuthContext);
  let { moduleId } = useParams();
  let history = useHistory();

  const checkType = (types) => {
    let isType = false;
    if (types.includes(type)) {
      return (isType = true);
    }
    return isType;
  };
  useEffect(() => {
    availableModule.then((data) => {
      const validArr = data;
      let valid = validArr.includes(moduleId);
      if (!valid) {
        history.push('/');
      } else {
        if (currentUser && currentUser.userModules) {
          currentUser.userModules.forEach((module) => {
            if (module.userModuleId === moduleId) {
              setCurrentModule(module);
            }
          });
        }
      }
      setLoading(false);
    });
  }, [currentUser]);

  let type = currentModule && currentModule.type;
  let expireDate = currentModule && currentModule.expireDate;
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  let url = `${serverUrl}/${moduleId}`;
  let isTeacher = type === 'teacher';
  let paid = moment.utc(expireDate).isAfter(moment.utc());

  return (
    <ModuleContext.Provider
      value={{
        serverUrl,
        currentModule,
        url,
        moduleId,
        type,
        isTeacher,
        paid,
        checkType,
      }}
    >
      {!loading && children}
    </ModuleContext.Provider>
  );
};

export const useModuleContext = () => {
  const state = useContext(ModuleContext);
  return state;
};
