import React from 'react';

function FAQTab() {
  return (
    <div className="px-4 ml-0" style={{ background: '#fff' }}>
      <p className="profile__label mb-3">Frequently Asked Questions</p>
      <div className="row">
        <div className="col-sm-12">
          <p>
            We get asked these questions a lot, so we made this small section to help you out identifying what you need
            faster.
          </p>
        </div>
        <div className="col-sm-8 mt-3" id="faqControl">
          <div className="form-group d-flex justify-content-between" id="fToggleOne">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#questionOne"
              aria-expanded="true"
              aria-controls="questionOne"
            >
              <label className="mb-0 d-block" htmlFor="activity-switch">
                Which platforms does Smart Academy App runs on?
              </label>
              <p className="small text-muted">Website, Android OS, iOS</p>
            </div>
            <div className="iconIndicator">
              <i className="fa fa-angle-down"></i>
            </div>
          </div>

          <div id="questionOne" className="collapse show" aria-labelledby="dToggleOne" data-parent="#faqControl">
            <div className="py-1 px-1">
              <p className="small" style={{ color: 'var(--gray)' }}>
                Yes. Smart Academy App is available for across all major platforms: Web App (For Desktop Users), Google
                Play Store(for Android Devices) & App Store (for Apple Devices).
              </p>
            </div>
          </div>

          <div className="form-group d-flex justify-content-between" id="fToggleTwo">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#questionTwo"
              aria-expanded="true"
              aria-controls="questionTwo"
            >
              <label className="mb-0 d-block" htmlFor="activity-switch">
                Which courses are available on Smart Academy right now?
              </label>
              <p className="small text-muted">Entrance Preparation Courses for MBBS, Nursing.</p>
            </div>
            <div className="iconIndicator">
              <i className="fa fa-angle-down"></i>
            </div>
          </div>

          <div id="questionTwo" className="collapse show" aria-labelledby="dToggleTwo" data-parent="#faqControl">
            <div className="py-1 px-1">
              <p className="small" style={{ color: 'var(--gray)' }}>
                Courses for MBBS and Nursing Plus is available. However, for other variants, few other models such as
                semester MCQ modal exams are also being used to conduct examinations in various other colleges.
              </p>
            </div>
          </div>

          <div className="form-group d-flex justify-content-between" id="fToggleThree">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#questionThree"
              aria-expanded="true"
              aria-controls="questionThree"
            >
              <label className="mb-0 d-block" htmlFor="activity-switch">
                Does this app need subscription to access the courses.
              </label>
              <p className="small text-muted">
                Yes. Premium courses Only Depends upon the duration the courses is purchased on.
              </p>
            </div>
            <div className="iconIndicator">
              <i className="fa fa-angle-down"></i>
            </div>
          </div>

          <div id="questionThree" className="collapse show" aria-labelledby="dToggleThree" data-parent="#faqControl">
            <div className="py-1 px-1">
              <p className="small" style={{ color: 'var(--gray)' }}>
                The resources in Smart Academy are accessible for Paid users only. The cost in very affordable and
                justified. You can use and try various trial exam models for free of cost.
              </p>
            </div>
          </div>

          <div className="form-group d-flex justify-content-between" id="fToggleFour">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#questionFour"
              aria-expanded="true"
              aria-controls="questionFour"
            >
              <label className="mb-0 d-block" htmlFor="activity-switch">
                How valid/authentic is the courses materials/resources?
              </label>
              <p className="small text-muted">Questions are provided directly from the Insitution itself</p>
            </div>
            <div className="iconIndicator">
              <i className="fa fa-angle-down"></i>
            </div>
          </div>

          <div id="questionFour" className="collapse show" aria-labelledby="dToggleFour" data-parent="#faqControl">
            <div className="py-1 px-1">
              <p className="small" style={{ color: 'var(--gray)' }}>
                The contents you see are copyrighted and valid and are maintaintained by the Institution itself.
                Moreover, you will be practicing the same sets of questions prepared by highly recognized teachers /
                publication.
              </p>
            </div>
          </div>

          <div className="form-group d-flex justify-content-between" id="fToggleFive">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#questionFive"
              aria-expanded="true"
              aria-controls="questionFive"
            >
              <label className="mb-0 d-block" htmlFor="activity-switch">
                Can I see the result and test conducted on the app?
              </label>
              <p className="small text-muted">Yes</p>
            </div>
            <div className="iconIndicator">
              <i className="fa fa-angle-down"></i>
            </div>
          </div>

          <div id="questionFive" className="collapse show" aria-labelledby="dToggleFive" data-parent="#faqControl">
            <div className="py-1 px-1">
              <p className="small" style={{ color: 'var(--gray)' }}>
                Whether you enroll in the mock tests or not, you will be able to see the overall result. However, you
                can only see your result and performance of your last appeared test.
              </p>
            </div>
          </div>

          <div className="form-group d-flex justify-content-between" id="fToggleSix">
            <div
              className="text-left"
              data-toggle="collapse"
              data-target="#questionSix"
              aria-expanded="true"
              aria-controls="questionSix"
            >
              <label className="mb-0 d-block" htmlFor="activity-switch">
                Does my profile or profile activity show to other users?
              </label>
              <p className="small text-muted">No.</p>
            </div>
            <div className="iconIndicator">
              <i className="fa fa-angle-down"></i>
            </div>
          </div>

          <div id="questionSix" className="collapse show" aria-labelledby="dToggleSix" data-parent="#faqControl">
            <div className="py-1 px-1">
              <p className="small" style={{ color: 'var(--gray)' }}>
                Here's a list of things that are public and is visible to other users: 1. Your discussion posts 2. Your
                Name 3. Your profile picture 4. Your result ranking and score of the test you've appeared.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQTab;
