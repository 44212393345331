import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Sticky, StickyContainer } from "react-sticky";
import bootbox from "bootbox";
import moment from "moment";
import axios from "axios";
import { useModuleContext } from "../../context/ModuleProvider";
import { useAuthContext } from "../../context/AuthContextProvider";

import ProfileStat from "./ProfileStat";
import ActivityTab from "./ActivityTab";
import AccountTab from "./AccountTab";
import FAQTab from "./FAQTab";
import NotificationTab from "./NotificationTab";
import ShareModel from "./ShareModel";
import { Link } from "react-router-dom";
import "./Profile.css";

function Profile() {
  let { moduleId, currentModule, url } = useModuleContext();
  const { logout } = useAuthContext();
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState();
  // let deleteMessage = 'Are you sure you want to delete this account?';
  let isMobile = window.innerWidth < 767;

  let expireDate = moment.utc(currentModule.expireDate);
  let remainTime = expireDate.diff(moment.utc());
  let expired = !(remainTime > 0);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: `${url}/payment/last`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((res) => {
        setLoading(false);
        setPayment(res.data.payment);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const { currentUser } = useAuthContext();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchAvailableCourses = async () => {
      const response = await axios({
        method: "get",
        url: `${serverUrl}/course/available`,
        withCredentials: true,
      });

      if (response.data.detail) {
        setCourses(response.data.detail);
      }
    };
    fetchAvailableCourses();
  }, []);

  let enrolledArray = [];
  currentUser.userModules.forEach((course) => {
    enrolledArray.push(course.userModuleId);
  });

  let displayCourse = courses.filter((course) => {
    return enrolledArray.includes(course.module_id);
  });

  let currentModuleName = displayCourse.filter(
    (each) => each.module_id === currentModule.userModuleId
  );
  console.log(currentModuleName[0] && currentModuleName[0].name);

  return (
    <StickyContainer>
      <div id="profile-page" className="w-100">
        <div className="row">
          <ShareModel />

          <div className="col-md-4 col-12 pr-2 pr-sm-0">
            <Sticky>
              {({ style }) => {
                return (
                  <div style={style}>
                    <ProfileStat expired={expired} />

                    <div className="p-3 my-2 bg-white rounded">
                      <p className="course-switch">Switch Course</p>
                      <div className="dropdown my-2">
                        <button
                          className="btn btn-outline-primary dropdown-toggle w-100 py-2"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>
                            {(currentModuleName[0] &&
                              currentModuleName[0].name) ||
                              "Loading..."}
                          </span>
                          <span className="dropdown-toggle-icon"></span>
                        </button>
                        <div
                          className="dropdown-menu w-100"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {displayCourse.map((course, i) => (
                            <a
                              key={i}
                              className="dropdown-item w-100"
                              href={`/${course.module_id}/dashboard`}
                            >
                              <p>{course.name}</p>
                            </a>
                          ))}
                          <a
                            className="dropdown-item w-100 bg-warning"
                            href={`/enroll`}
                          >
                            <p className="font-weight-bold">
                              <span
                                style={{ fontSize: "20px", fontWeight: "bold" }}
                              >
                                +
                              </span>{" "}
                              Enroll to New Course
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-12  p-0">
                      <div className="mySub">
                        <div className="row">
                          {loading && <div>Loading...</div>}

                          {!loading && !payment && (
                            <div className="col-12 border-bottom p-sm-3 p-2">
                              <p className="small">
                                You have not subscribed to any subscription yet.
                                Please subscribe now to get access to all
                                feature. <br />
                              </p>
                              <Link to={`/${moduleId}/payment`}>
                                <button className=" mr-3 btn btn-dark btn-sm w-100 p-2 mt-2">
                                  Buy Now
                                </button>
                              </Link>
                            </div>
                          )}

                          {!loading && payment && (
                            <div className="col-12 border-bottom p-sm-3 p-2">
                              <h5 className="fw-500 mb-2">
                                {currentModule.userModuleId.toUpperCase()}{" "}
                                Package
                              </h5>
                              <span
                                className={`badge ${
                                  !expired ? "badge-success" : "badge-danger"
                                }`}
                              >
                                Active
                              </span>
                              <Link to={`/${moduleId}/payment`}>
                                <button className="float-right mr-0 btn btn-outline-dark btn-sm">
                                  Upgrade
                                </button>
                              </Link>
                              <p className="small mb-0">
                                <strong>Purchased On: </strong>
                                {moment
                                  .utc(payment.startDate)
                                  .format("YYYY-MM-DD")}{" "}
                                &nbsp;
                                <strong>Time: </strong>{" "}
                                {moment
                                  .utc(payment.start_date)
                                  .format("HH:mm:ss")}
                              </p>
                              <br />
                              <p className="small">
                                <strong>Paid via: </strong>
                                {payment.merchantType.toUpperCase()} &nbsp;
                                <strong>Amount: </strong> Rs. {payment.amount}
                              </p>
                              <div
                                className={`alert ${
                                  !expired ? "alert-warning" : "alert-danger"
                                } my-3`}
                                role="alert"
                              >
                                {expired ? (
                                  <p className="small">
                                    Your subscription has expired on{" "}
                                    {expireDate.format("YYYY-MM-DD HH:mm:ss")}.{" "}
                                    <br />
                                    <strong>0 Days remaining</strong>
                                  </p>
                                ) : (
                                  <p className="small">
                                    Your subscription expires on{" "}
                                    {expireDate.format("YYYY-MM-DD HH:mm:ss")}.{" "}
                                    <br />
                                    <strong>
                                      {expireDate.fromNow()} remaining
                                    </strong>
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* menu label */}
                    <p className="profile__label">General</p>
                    <div className="profile__page__options bg-white pl-sm-0 pr-sm-0 profile__page-card">
                      <ul className="nav nav-tabs" id="profTab" role="tablist">
                        {!isMobile && (
                          <li className="nav-item w-100 text-center">
                            <a
                              className="nav-link active"
                              id="profile__page-settings-tab"
                              data-toggle="tab"
                              href="#profile__page-settings"
                              role="tab"
                              aria-controls="profile__page-settings"
                              aria-selected="true"
                            >
                              <span className="pr-2">
                                <i className="fas fa-cog"></i>
                              </span>
                              Account
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a>
                            {/* <a
                              className="nav-link "
                              id="notifications-tab"
                              data-toggle="tab"
                              href="#notifications"
                              role="tab"
                              aria-controls="notifications"
                              aria-selected="false"
                            >
                              <span className="pr-2">
                                <i className="far fa-bell"></i>
                              </span>
                              Notifications
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a> */}
                            {/* <a
                              className="nav-link"
                              id="activity-tab"
                              data-toggle="tab"
                              href="#activity"
                              role="tab"
                              aria-controls="activity"
                              aria-selected="false"
                            >
                              <span className="pr-2">
                                <i className="fas fa-chart-line"></i>
                              </span>
                              Activity
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a> */}
                          </li>
                        )}

                        {isMobile && (
                          <li className="nav-item w-100 text-center">
                            <a
                              className="nav-link"
                              id="profile__page-settings-tab"
                              href={`/${moduleId}/profile/setting`}
                            >
                              <span className="pr-2">
                                <i className="fas fa-cog"></i>
                              </span>
                              Account
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a>
                            {/* <a
                              className="nav-link "
                              id="notifications-tab"
                              href={`/${moduleId}/profile/notification`}
                              aria-controls="notifications"
                              aria-selected="false"
                            >
                              <span className="pr-2">
                                <i className="far fa-bell"></i>
                              </span>
                              Notifications
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a> */}
                            {/* <a
                              className="nav-link"
                              id="activity-tab"
                              href={`/${moduleId}/profile/activity`}
                              aria-controls="activity"
                              aria-selected="true"
                            >
                              <span className="pr-2">
                                <i className="fas fa-chart-line"></i>
                              </span>
                              Activity
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a> */}
                          </li>
                        )}
                      </ul>
                    </div>

                    {/* menu label */}
                    <p className="profile__label">About</p>

                    <div className="profile__page__options bg-white pl-sm-0 pr-sm-0 profile__page-card">
                      <ul className="nav nav-tabs" id="profTab" role="tablist">
                        <li className="nav-item w-100 text-center">
                          {!isMobile && (
                            <a
                              className="nav-link"
                              id="faqs-tab"
                              data-toggle="tab"
                              href="#faqs"
                              role="tab"
                              aria-controls="faqs"
                              aria-selected="false"
                            >
                              <span className="pr-2">
                                <i className="far fa-question-circle"></i>
                              </span>
                              FAQs
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a>
                          )}

                          {isMobile && (
                            <a
                              className="nav-link "
                              id="faqs-tab"
                              href={`/${moduleId}/profile/faqs`}
                              aria-controls="faqs"
                              aria-selected="false"
                            >
                              <span className="pr-2">
                                <i class="far fa-question-circle"></i>
                              </span>
                              FAQs
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a>
                          )}

                          <a
                            className="nav-link"
                            id="terms-tab"
                            href="https://www.snartacademy.com/terms-and-conditions/"
                            aria-controls="terms"
                            aria-selected="false"
                          >
                            <span className="pr-2">
                              <i className="fas fa-file-contract"></i>
                            </span>
                            Terms & Conditions
                            <span className="float-right">
                              <i className="fas fa-chevron-right"></i>
                            </span>
                          </a>
                          <a
                            className="nav-link"
                            id="privacy-tab"
                            href="https://www.smartacademy.com/privacy-policy/"
                          >
                            <span className="pr-2">
                              <i className="fas fa-user-lock"></i>
                            </span>
                            Privacy Policy
                            <span className="float-right">
                              <i className="fas fa-chevron-right"></i>
                            </span>
                          </a>
                          {/* <a
                            className="nav-link"
                            id="feedback-tab"
                            href={`/${moduleId}/feedback`}
                            aria-controls="feedback"
                            aria-selected="false"
                          >
                            <span className="pr-2">
                              <i className="far fa-comment"></i>
                            </span>
                            Send Us Feedback
                            <span className="float-right">
                              <i className="fas fa-chevron-right"></i>
                            </span>
                          </a> */}
                          {/* {isMobile && (
                            <a
                              className="nav-link"
                              id="ratings-tab"
                              href={`/${moduleId}/profile#rateus`}
                              aria-controls="ratings"
                              aria-selected="false"
                            >
                              <span className="pr-2">
                                <i class="far fa-smile"></i>
                              </span>
                              Rate Us
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a>
                          )} */}
                          {/* {isMobile && (
                            <a
                              className="nav-link"
                              id="sharethisapp-tab"
                              href={`/${moduleId}/profile#share`}
                              aria-selected="true"
                              tabIndex={-1}
                            >
                              <span className="pr-2">
                                <i className="fas fa-share-alt"></i>
                              </span>
                              Share This App
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a>
                          )} */}
                          {/* {!isMobile && (
                            <a
                              className="nav-link"
                              id="sharethisapp-tab"
                              aria-selected="true"
                              tabIndex={-1}
                              role="dialog"
                              data-toggle="modal"
                              data-target="#shareModal"
                              data-whatever="@shareModal"
                            >
                              <span className="pr-2">
                                <i className="fas fa-share-alt"></i>
                              </span>
                              Share This App
                              <span className="float-right">
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </a>
                          )} */}
                          <a
                            className="nav-link"
                            id="appversion-tab"
                            data-toggle="tab"
                            href="#appversion"
                            role="tab"
                            aria-controls="appversion"
                            aria-selected="false"
                          >
                            <span className="pr-2">
                              <i className="fas fa-info-circle"></i>
                            </span>
                            App Version
                            <span className="float-right">2.0.0 (Beta)</span>
                          </a>

                          <Link
                            className="nav-link text-danger"
                            data-widget="pushmenu"
                            to="#"
                            onClick={logout}
                          >
                            <span className="pr-2">
                              <i className="fas fa-sign-out-alt"></i>
                            </span>
                            Log Out
                          </Link>
                        </li>
                      </ul>
                    </div>

                    {/* <p className="profile__label">User Account Control (UAC)</p>
                    <div className="profile__page__options bg-white pl-sm-0 pr-sm-0 profile__page-card">
                      <ul className="nav nav-tabs" id="profTab" role="tablist">
                        <li className="nav-item w-100 text-center">
                          <a
                            className="nav-link text-danger"
                            onClick={() =>
                              bootbox.alert({
                                message: deleteMessage,
                                buttons: {
                                  ok: {
                                    label: 'Yes',
                                    className: 'btn-danger',
                                  },
                                },
                              })
                            }
                          >
                            <span className="pr-2">
                              <i className="fas fa-trash-alt"></i>
                            </span>
                            Delete Account
                          </a>
                        </li>
                      </ul>
                      <p className="profile__label p-2">
                        If you delete your account now, you will have the option to recover it within 30 days.
                      </p>
                    </div> */}
                  </div>
                );
              }}
            </Sticky>
          </div>

          <div className="col-md-8 col-12">
            {!isMobile && (
              <div className="profile__page__options bg-white pl-sm-0 pr-sm-0 profile__page-card">
                <div className="tab-content" id="profile__page-main">
                  {/* account tab  */}
                  <div
                    aria-labelledby="profile__page-settings-tab"
                    className="tab-pane fade show active fade mt-0 pt-0"
                    id="profile__page-settings"
                    role="tabpanel"
                  >
                    <AccountTab />
                  </div>

                  <div
                    aria-labelledby="notifications-tab"
                    className="tab-pane fade mt-0 pt-0"
                    id="notifications"
                    role="tabpanel"
                  >
                    <NotificationTab />
                  </div>

                  <div
                    aria-labelledby="activity-tab"
                    className="tab-pane fade mt-0 pt-0"
                    id="activity"
                    role="tabpanel"
                  >
                    <ActivityTab />
                  </div>

                  <div
                    aria-labelledby="faqs-tab"
                    className="tab-pane fade mt-0 pt-0"
                    id="faqs"
                    role="tabpanel"
                  >
                    <FAQTab />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StickyContainer>
  );
}

export default withRouter(Profile);
