import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import './SeeChapter.css';
import { useModuleContext } from '../../context/ModuleProvider';

function SeeUnit({ location }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const { moduleId } = useModuleContext();
  const [subject, setSubject] = useState([]);
  const [selected, setSelected] = useState(null);
  let { subjectId } = queryString.parse(location.search);
  const url = `${server}/${moduleId}/practice`;

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/seeUnit?subjectId=${subjectId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      withCredentials: true,
    }).then((res) => {
      let subject = res.data.subject;
      if (subject.length === 0) {
      } else {
        setSubject(subject);
        subject.map((s) => {
          if (parseInt(s.subject_id) === parseInt(subjectId)) {
            setSelected(s);
          }
          return s;
        });
      }
    });
  }, [url, subjectId]);

  return (
    <section id="see-chapter" className="see-chapter question-detail">
      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-md-8 col-12">
            {/* left side contents */}
            {selected && (
              <div className="card card-default color-palette-box" style={{ borderTop: '0.3em solid #3498db' }}>
                <div className="card-header clearfix">
                  <h3 className="card-title d-inline">
                    <img
                      className="chapter-img"
                      src={`${server}/images/subjectLight/icons/${moduleId}/${subjectIcon(selected.subject_name)}.png`}
                      alt="Unit"
                    />
                    <span className="d-inline">{selected && selected.subject_name}</span>
                    <span className="float-right">
                      <Link to={`start?subjectId=${selected.subject_id}`}>
                        <button
                          className="btn btn-dark my-2"
                          type="button"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Jump right into the practice section"
                        >
                          Start
                        </button>
                      </Link>
                    </span>
                  </h3>
                  <span className="float-left" style={{ paddingLeft: '15px' }}>
                    <div className="chapter-topics">
                      <div className="d-block text-left">
                        <small className="font-weight-bold">5/100 Mastery points</small>
                      </div>
                      {/* this margin value is different */}
                      <div style={{ width: '260px', margin: '10px auto 10px' }}>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="0.05"
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ maxWidth: '0.05%' }}
                          >
                            <span className="title">0.05%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="card-body">
                  <ul className="list-group" style={{ height: 'auto' }}>
                    {selected &&
                      selected.units.map((u) => (
                        <Link key={u.id} to={`/${moduleId}/practice/start?unitId=${u.unit_id}`}>
                          <li className="list-group-item-text chapter-detail-list" style={{ paddingBottom: '5px' }}>
                            <img
                              className="chapter-img"
                              src={`${server}/images/chapter/physics/${u.logo_id}`}
                              alt={u.unit_name}
                            />
                            {u.unit_name}
                          </li>
                        </Link>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 col-12">
            <h4>100</h4>
            <p className="text-muted">Mastery points available in course</p>
            <hr />
            <p className="font-weight-bold">Course Summary</p>
            <hr />
            {subject.map((s) => (
              <div key={s.id} className="chapter-topics">
                <small className="font-weight-bold">{s.subject_name}</small>
                <div style={{ width: '360px', margin: '10px auto 10px' }}>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ maxWidth: '0%' }}
                    >
                      <span className="title">0%</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* final bottom */}
            <div className="chapter-topics-bottom">
              <small className="font-weight-bold">Course Challenge</small>
              <div className="lists-at-bottom row d-flex justify-content-between">
                <p className="text-muted d-block px-2" style={{ width: '80%' }}>
                  Test the knowledge of the skills in this course.
                </p>
                <img src="/images/challenge.png" alt="thinking+logo" style={{ maxWidth: '60px', maxHeight: '65px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const subjectIcon = (name) => {
  return name.toLowerCase().replace(/\s/g, '-');
};

export default withRouter(SeeUnit);
