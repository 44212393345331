import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import SocialLogin from './SocialLogin';

function ForgetFrom() {
  const { register, handleSubmit, errors } = useForm();
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  const [info, setInfo] = useState({ error: null, loading: false });

  const submitForm = async (data) => {
    setInfo({
      loading: true,
      error: 'Please wait! Sending email might take a few second',
    });
    const res = await axios({
      method: 'post',
      url: `${serverUrl}/auth/forget`,
      withCredentials: true,
      data,
    });
    console.log(res.data, errors);
    setInfo({ loading: false, error: res.data.message || '' });
    return;
  };
  let { loading, error } = info;
  return (
    <form className="authForm" id="loginForm" onSubmit={handleSubmit(submitForm)}>
      <div className="instructions__login">
        <div className="overlay__img d-lg-none d-md-none d-none">
          <img style={{ position: 'relative' }} alt="smart Logo" src="/images/logo.png" />
        </div>
        <div class="back-element d-block" style={{ position: 'relative', padding: '15px 0px' }}>
          <Link class="text-muted" to="/login">
            <i class="fas fa-arrow-left"></i>
          </Link>
        </div>
        <h2 className="Welcom d-flex mb-0 pb-0">
          {/* <Link to="/login" className="back-link">
            <i className="fa fa-chevron-left" />
          </Link> */}
          Forget Password?
        </h2>
        <p className="small text-muted">Enter your email & we’ll send you the instructions to reset your password.</p>
      </div>
      <div className="error">{error}</div>
      <div className={errors.email ? 'errorfield inputGroup' : 'inputGroup'}>
        <input
          name="email"
          ref={register({
            required: {
              message: 'Email is required',
            },
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: 'Email is invalid',
            },
          })}
          type="email"
          id="loginEmail"
          maxLength={254}
          placeholder="Email"
        />
        <label className="input-icon-label" htmlFor="email">
          <i class="far fa-envelope"></i>
        </label>
      </div>
      <div className="input-error">{errors.email && errors.email.message}</div>

      {/* <div className="inputGroup pt-3 pb-3 center-block">
        <button type="submit" id="send" className="w-100 btn btn-dark rounded shadow">{loading ? "Sending Email..." : "Send Email"}</button>
      </div> */}
      <div className="btn btn-block p-0 pt-4 my-2">
        <button id="login" className="btn btn-dark rounded shadow d-block d-md-none">
          {loading ? 'Sending Email...' : 'Reset Password'}&nbsp;<i className="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div className="text-center">
        <p className="text-muted">or directly login with Gmail or Facebook</p>
        <br />
        <SocialLogin />
        <p className="text-muted">
          <i className="fas fa-shield-alt"></i>&nbsp;It's safe & secure.
        </p>
      </div>
    </form>
  );
}

export default ForgetFrom;
