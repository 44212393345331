import React, { useContext, useEffect, useState } from 'react';
import './Live.css';
import axios from 'axios';
import { useModuleContext } from '../../context/ModuleProvider';

import { FaPlayCircle, FaCrown } from 'react-icons/fa';

import Preloader from '../Preloader/Preloader';
import { useParams, Link } from 'react-router-dom';
import { MeetingContext } from '../../context/MeetingContextProvider';
import { decrypt } from '../../service/Encryption';
import { toast } from 'react-toastify';
import moment from 'moment';

function Live() {
  const { setMeeting } = useContext(MeetingContext);
  const [isLoading, setIsLoading] = useState();
  const [userLiveGroup, setUserLiveGroup] = useState([]);
  const { url } = useModuleContext();

  const toReadableDate = (dateString) => {
    if (dateString) {
      let date = new Date(dateString);
      return `${date.toDateString()} ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
    } else return '';
  };

  const { moduleId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios({
      method: 'get',
      url: `${url}/zoom/haslivegroup-access`,
    })
      .then((res) => {
        let data = decrypt(res.data.liveGroups);
        data = JSON.parse(data);
        setUserLiveGroup(data);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  }, []);

  return !isLoading ? (
    <section className="content zoom-live">
      {/* <div className="live-subscribe">
          <div className="texts">
            <h1>Introducing best Live Subscription Plans!</h1>
            <p>Take Your Preparation to Next Level & Discover more today !!</p>
          </div>
          <Link to={`/${moduleId}/zoom-payment`} className="buy-now-btn">
            Buy Now
          </Link>
        </div> */}

      <div className="live-contents">
        {userLiveGroup && userLiveGroup.length !== 0 ? (
          <>
            <div className="page_header">
              <h1>Live Groups</h1>
              <Link to={`/${moduleId}/zoom-payment`}>Buy Now</Link>
            </div>
            {userLiveGroup.map((liveGroups) => {
              return (
                <>
                  <h1>{liveGroups.title}</h1>
                  {liveGroups.lives && liveGroups.lives.length ? (
                    <div className="meeting-lives">
                      {liveGroups.lives.map((item, i) => {
                        let start_date = toReadableDate(item.start_time);
                        let s_time = moment(item.start_time).format('hh:mm A');
                        return (
                          <div className="live-item" key={i}>
                            <div className="icon">
                              <FaPlayCircle />
                            </div>
                            <div className="content">
                              <article className="details" style={{ backgroundColor: 'transparent' }}>
                                <p className="premium">
                                  <FaCrown />
                                  Premium
                                </p>
                                <p className="title">{item.title}</p>
                                <p>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_332_400)">
                                      <path
                                        d="M7.99992 1.33398C11.6819 1.33398 14.6666 4.31865 14.6666 8.00065C14.6666 11.6827 11.6819 14.6673 7.99992 14.6673C4.31792 14.6673 1.33325 11.6827 1.33325 8.00065C1.33325 4.31865 4.31792 1.33398 7.99992 1.33398ZM7.99992 2.66732C6.58543 2.66732 5.22888 3.22922 4.22868 4.22941C3.22849 5.22961 2.66659 6.58616 2.66659 8.00065C2.66659 9.41514 3.22849 10.7717 4.22868 11.7719C5.22888 12.7721 6.58543 13.334 7.99992 13.334C9.41441 13.334 10.771 12.7721 11.7712 11.7719C12.7713 10.7717 13.3333 9.41514 13.3333 8.00065C13.3333 6.58616 12.7713 5.22961 11.7712 4.22941C10.771 3.22922 9.41441 2.66732 7.99992 2.66732ZM7.99992 4.00065C8.16321 4.00067 8.32081 4.06062 8.44283 4.16913C8.56486 4.27763 8.64281 4.42715 8.66192 4.58932L8.66658 4.66732V7.72465L10.4713 9.52932C10.5908 9.64929 10.6602 9.81027 10.6654 9.97957C10.6706 10.1489 10.6111 10.3138 10.4991 10.4408C10.3871 10.5679 10.2309 10.6475 10.0623 10.6636C9.89365 10.6796 9.72525 10.6309 9.59125 10.5273L9.52858 10.472L7.52858 8.47198C7.42497 8.36828 7.35843 8.23332 7.33925 8.08798L7.33325 8.00065V4.66732C7.33325 4.49051 7.40349 4.32094 7.52851 4.19591C7.65354 4.07089 7.82311 4.00065 7.99992 4.00065Z"
                                        fill="#808080"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_332_400">
                                        <rect width="16" height="16" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  {item.meet_type === 8 ? s_time : start_date}
                                </p>
                              </article>
                              <div className="buttons">
                                {!item.is_live ? (
                                  <Link className="not-allowed" to={'#'}>
                                    Offline
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/live/meeting`}
                                    onClick={() => {
                                      setMeeting({
                                        meetingId: item.meet_id,
                                        meetingPw: item.meet_pass,
                                        meetingMsdkKey: item.zoom_msdk_key,
                                      });
                                    }}
                                  >
                                    Start
                                  </Link>
                                )}
                                <Link
                                  className="videos"
                                  to={`/${moduleId}/live-videos?live_id=${item.id}&liveGroupId=${liveGroups.id}&live_title=${item.title}`}
                                >
                                  Videos
                                </Link>
                              </div>
                            </div>
                            <div className={`live-status ${item.is_live ? 'is-live' : ''}`}>
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="8" cy="8.5" r="8" fill="#FFC2C2" />
                                <circle cx="8" cy="8.5" r="3.75" fill="#D92626" stroke="white" stroke-width="0.5" />
                              </svg>
                              <p>LIVE</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="no-class">
                      <p>
                        <span>{liveGroups.title}</span> has no classes for now.
                      </p>
                    </div>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <BuyLiveClass moduleId={moduleId} />
        )}
      </div>
    </section>
  ) : (
    <Preloader />
  );
}

const BuyLiveClass = ({ moduleId }) => {
  return (
    <div className="buy-online-class">
      <div>
        <img src="/images/buy-online-class.svg" alt="Buy" />
        <div className="buy-now">
          <p>You donot have any Live Group assigned to your name.</p>
          <Link to={`/${moduleId}/zoom-payment`}>Buy Now</Link>
        </div>
      </div>
    </div>
  );
};

export default Live;
