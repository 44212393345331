import React, { useEffect, useContext } from "react";
import { Link, Prompt, withRouter } from "react-router-dom";
import { TestContext } from "../../context/TextContext";
import MemoizedStatus from "./TestStatus";
import Preloader from "../Preloader/Preloader";
import TestModal from "./TestModal";
import TestQuestionCard from "./TestQuestionCard";
import { useModuleContext } from "../../context/ModuleProvider";
import { fetchQuestions } from "../../context/TextAction";
import "./TestQuestion.css";

function TestQuestion({ history }) {
  const [
    { questions, checkedCount, markCount, testId, loading, data, error, count },
    dispatch,
    { handleChange, handleMark, handleFlag },
  ] = useContext(TestContext);
  let { moduleId } = useModuleContext();

  const handleclose = (e) => {
    const message = "Do you want to quit the test?";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    dispatch(fetchQuestions(testId, history));
  }, []);

  useEffect(() => {
    console.log(error, "Error from useEffect");
    !error && window.addEventListener("beforeunload", handleclose);
    return () => window.removeEventListener("beforeunload", handleclose);
  }, [error]);

  //Following useEffect is to disable audio seeking and replays after 2 plays

  useEffect(() => {
    function handleAudioPlayback(audioElement) {
      var playCount = 0;
      var supposedCurrentTime = 0;

      audioElement.addEventListener("timeupdate", function () {
        if (!audioElement.seeking) {
          supposedCurrentTime = audioElement.currentTime;
        }
      });

      audioElement.addEventListener("seeking", function () {
        var delta = audioElement.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          audioElement.currentTime = supposedCurrentTime;
        }
      });

      audioElement.addEventListener("ended", function () {
        supposedCurrentTime = 0;

        playCount++;

        // setTimeout(() => {
        //   audioElement.play();
        // }, [1000]);

        if (playCount >= 1) {
          audioElement.src = "#";
        }
      });
    }

    var audioElements = document.getElementsByTagName("audio");

    for (var i = 0; i < audioElements.length; i++) {
      handleAudioPlayback(audioElements[i]);
    }
  }, [questions]);

  return (
    <section id="test-question" className="content noselect">
      <Prompt
        when={!data}
        message={(l) =>
          l.pathname.includes("/test/start")
            ? true
            : "Oops .. it seems you are you leaving the test. All the answers will be lost."
        }
      />
      <TestModal data={data} />
      {error && (
        <div className="flex center align-items-center text-center w-100">
          <h2>{error}</h2>
          <button
            onClick={() => history.push(`/${moduleId}/test`)}
            className="btn primary"
          >
            Browse other test.
          </button>
        </div>
      )}
      {loading && !error && <Preloader />}
      {!loading && !error && (
        <div className="row cf">
          <MemoizedQuestions
            questions={questions}
            count={count}
            handleMark={handleMark}
            handleChange={handleChange}
            handleFlag={handleFlag}
          />
          <div className="col-lg-3 col-md-3 quiz-card card">
            <div className="p-1 card-body">
              <h6 className="font-weight-bold" style={{ whiteSpace: "nowrap" }}>
                Mock Test
              </h6>
              {/* <div class="line"></div> */}
              {/* <div className="user-panel my-2 d-flex py-1 px-1">
                <div className="brand-link brand-image img-circle border" style={{height: "40px",width: "40px",textAlign: "center"}}>
                  {currentUser.email && currentUser.email.slice(0, 1)}
                </div>
                <div className="info px-1 small">{currentUser && currentUser.email}</div>
              </div> */}
              <div className="help-texts mb-2">
                <div className="row d-flex" style={{ fontSize: "14px" }}>
                  <div>
                    <i className="fa fa-circle" style={{ color: "#6c757d" }} />
                    &nbsp;
                    <span className="text-muted small" id="remainCnt">
                      {count - checkedCount}&nbsp;unanswered
                    </span>
                  </div>
                  <div>
                    <i
                      className="fa fa-circle"
                      style={{ paddingLeft: "10px", color: "#20c997" }}
                    />
                    &nbsp;
                    <span className="text-muted small" id="attemptCnt">
                      {checkedCount}&nbsp;answered
                    </span>
                  </div>
                  <div>
                    <i
                      className="fa fa-circle"
                      style={{ paddingLeft: "10px", color: "#FFAD3B" }}
                    />
                    &nbsp;
                    <span className="text-muted small" id="bookmarkCnt">
                      {markCount}&nbsp;marked
                    </span>
                  </div>

                  {/* <i
                      className="fa fa-circle"
                      style={{ paddingLeft: "10px", color: "#f5378e" }}
                    />
                    &nbsp;
                    <span className="text-muted small" id="flagCnt">
                      {flagCount}&nbsp;flagged
                    </span> */}
                </div>
              </div>
              {/* <div
                style={{
                  textAlign: "left",
                  paddingBottom: "10px",
                  fontSize: "14px"
                }}
              >
                <i className="fa fa-clock-o" />
                &nbsp;Time Remaining :&nbsp;
                <span
                  className="badge badge-danger"
                  id="timer"
                  style={{ fontSize: "20px" }}
                >
                  <TestCounter end={end} />
                </span>
              </div> */}
              <div>
                <div className="w-100">
                  <h6 className="font-weight-bold">Questions</h6>
                </div>
                <div className="d-flex flex-wrap justify-content-between mb-3 quizCard">
                  {questions.map((q, index) => (
                    <MemoizedStatus
                      testId={testId}
                      key={index}
                      q={q}
                      index={index}
                    />
                  ))}
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* <div
              className="card-footer col-md-3 text-center"
              style={{
                position: "fixed",
                fontSize: "10px",
                bottom: 0,
                borderTop: "1px solid rgba(0,0,0,.125)",
                backgroundColor: "#fff",
                zIndex: 2
              }}
            >
              
              <Link to="#">Help</Link> | <Link to="#">Instructions</Link>
            </div> */}
          </div>
        </div>
      )}
    </section>
  );
}

const MemoizedQuestions = React.memo(
  function ({ questions, count, handleMark, handleChange, handleFlag }) {
    console.log("This is Memo Test Questions");
    return (
      <div className="col-lg-9 col-md-9 col-12">
        <div
          className="card border-0 ml-0 ml-sm-5"
          style={{ boxShadow: "none", border: "none" }}
        >
          <div style={{ marginTop: "1.8em" }}>
            <form>
              {/* <List width={400} height={300 * (count || 10) * 2} itemCount={questions.length} itemSize={300}  >
              {({ index }) => {
                let q = questions[index];
                return <Question key={index} q={q} count={count} index={index} handleChange={handleChange} handleFlag={handleFlag} handleMark={handleMark} />
              }}
            </List> */}

              {questions.map((q, index) => (
                <TestQuestionCard
                  key={index}
                  q={q}
                  count={count}
                  index={index}
                  handleChange={handleChange}
                  handleFlag={handleFlag}
                  handleMark={handleMark}
                />
              ))}
            </form>
          </div>
        </div>
      </div>
    );
  },
  () => true
);

export default withRouter(TestQuestion);
