import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Course.css';
import bootbox from 'bootbox';
import { FiLogOut } from "react-icons/fi";
import { useAuthContext } from '../../context/AuthContextProvider';
import axios from 'axios';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function Home({ history }) {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchAvailableCourses = async () => {
      const response = await axios({
        method: 'get',
        url: `${serverUrl}/course/available`,
        withCredentials: true,
      });

      if (response.data.detail) {
        setCourses(response.data.detail);
      }
    };
    fetchAvailableCourses();
  }, []);

  const { currentUser, logout } = useAuthContext();

  let enrolledArray = [];
  currentUser.userModules.forEach((course) => {
    enrolledArray.push(course.userModuleId);
  });

  let displayCourse = courses.filter((course) => {
    return enrolledArray.includes(course.module_id);
  });

  return (
    <div className="background mb-0 h-100">
      <div
        id="course-nav"
        className="shadow-sm sticky-top bg-white d-lg-flex p-2 flex-row justify-content-between align-items-end"
        style={{ position: 'fixed', width: '100%' }}
      >
        <a href="/" style={{ opacity: 1 }}>
          <div className="smart-logo">
            <img alt="smart Logo" height="50px" width="200px" src="/images/paradise.svg" />
          </div>
        </a>
        <div className='d-flex align-items-center justify-content-center'>
          <Link to="/enroll" type="button" className="btn btn-primary m-0 mt-4 mr-md-4 py-3 px-md-4 px-3 float-right">
            <span style={{ fontSize: '14px' }}>Explore Courses</span>
          </Link>
          <Tippy content="Logout" placement="top">
            <Link className='mt-4 mr-md-4 mr-2 logout' to="#" onClick={logout}>
              <FiLogOut fontSize={20} color='#DC3545' cursor={"pointer"} />
            </Link>
          </Tippy>
        </div>
      </div>
      <div className="container smart-moto-image d-none d-md-block">
        <img src="/images/online-bidhya.png" width={'85%'} alt="smart-online-class"></img>
        <div className="smart-moto-text">
          <p className="moto">Leading Edu-Tech Company</p>
        </div>
      </div>
      <div className="container course-group-list p-0">
        <div className="row panel new__categories-table">
          <div className="col-12 p-md-4 p-2">
            <div className="row">
              {/* <div className="col-md-6 col-12 px-0 pb-2 text-md-left"></div> */}
              <div className="col-md-8 col-12 px-0 pb-2 d-flex flex-column justify-content-start">
                <h3 className="d-block mb-3">Courses you have enrolled in: </h3>
                <p className="guide-text">Click one of the courses below to start learing now !!</p>
                {/* <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback" />
                  <input type="text" className="form-control" placeholder="Search" />
                </div> */}
              </div>
            </div>
          </div>

          {displayCourse.map((c) => {
            return <Category key={c.module_id} course={c} history={history} />;
          })}

          <div
            onClick={() => history.push('./enroll')}
            className="col-md-6 col-12 new__categories-plan plan2 mb-5"
          // style={{ backgroundImage: `url("/images/enrollNew.png")` }}
          >
            <img
              src={`/images/enrollNew.png`}
              alt="new course"
              height="100px"
              style={{
                objectFit: 'contain',
                maxHeight: '100px',
                width: '300px',
                maxWidth: '100%',
                marginTop: '1.75em',
              }}
            />
            <Link className="new__categories-price mt-2" to={''}>
              Enroll to new course
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function Category({ course, history }) {
  let { module_id, name, src } = course;
  let link = `/${module_id}/dashboard`;
  // id === 'nursing' && (link = `./courseSelect`);
  const [imgErrorCount, setImgErrorCount] = useState(0);

  return (
    <div onClick={() => history.push(link)} className="col-sm-12 col-md-6 col-12  new__categories-plan mb-3">
      <div className="text-right unenroll">
        <div
          className="btn btn-outline-dark"
          onClick={(e) => {
            bootbox.confirm({
              message: `<b>Are you sure you want to unenroll?</b> 
              <img src='/images/discussion.jpg' width="100%"></img>
              <br/><strong>Note:</strong> This will also remove all of your subscription and progress related to this course and  <strong>this action can't be undone</strong>!`,
              buttons: {
                confirm: {
                  label: 'Yes',
                  className: 'btn-danger',
                },
                cancel: {
                  label: 'No',
                  className: 'btn-success',
                },
              },
              callback: function (result) {
                if (result) {
                  axios({
                    method: 'delete',
                    url: `${serverUrl}/${module_id}/access/remove `,
                    withCredentials: true,
                  }).then((res) => {
                    bootbox.alert('Unenrolled Successfully! Keep Learning!', function () {
                      window.location.href = '/';
                    });
                  });
                }
              },
            });
            e.stopPropagation();
          }}
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </div>
      </div>

      <img
        // src={`/images/module/${module_id}.jpg`}
        src={`${serverUrl}/images/courses/${src}`}
        alt={`${module_id}`}
        height="100px"
        style={{ objectFit: 'contain', maxHeight: '100px', width: '270px', maxWidth: '100%' }}
        onError={({ currentTarget }) => {
          if (imgErrorCount < 4) {
            currentTarget.onerror = null;
            currentTarget.src = `/images/defaultCourse.png`;
            setImgErrorCount((pre) => pre + 1);
          }
        }}
      />
      <Link className="new__categories-price mt-2" to={link}>
        {name}
      </Link>
    </div>
  );
}

export default withRouter(Home);
