import React from "react";
import { useParams, withRouter, Route, Switch } from "react-router-dom";
import ModuleRoute from "./ModuleRoute";
import TestNav from "../Test/TestNav";
import TestQuestion from "../Test/TestQuestion";
import TestSidebar from "../Test/TestSidebar";
import Footer from "../Navbar/Footer";
import queryString from "query-string";
import AccessModule from "../AccessModule";
import ScrollToTop from "./ScrollToTop";
import NoMatch from "./NoMatch";
import HomeRoute from "./HomeRoute";
import { TestProvider } from "../../context/TextContext";
import { NavFooter } from "./Wrappers";
import { ModuleProvider } from "../../context/ModuleProvider";
import ErrorBoundary from "./ErrorBoundary";

function Module() {
  return (
    <ErrorBoundary>
      <ModuleProvider>
        <ScrollToTop />
        <div style={{ height: "auto", overflow: "visible" }}>
          <Switch>
            <Route path={`/:moduleId/access/`}>
              <AccessModule />
            </Route>
            <ModuleRoute path="/:moduleId/test/start">
              <TestRouteRouter />
            </ModuleRoute>
            <ModuleRoute path="/:moduleId">
              <HomeRoute />
            </ModuleRoute>
            <Route path="*">
              <NavFooter>
                <NoMatch />
              </NavFooter>
            </Route>
          </Switch>
        </div>
      </ModuleProvider>
    </ErrorBoundary>
  );
}

function TestRoute({ location }) {
  let { moduleId } = useParams();
  let testId = queryString.parse(location.search).testId;

  return (
    <TestProvider testId={testId} moduleId={moduleId}>
      <div className="wrapper" style={{ width: "100vw" }}>
        <TestNav moduleId={moduleId} />
        <div
          style={{
            paddingTop: "40px",
          }}
        >
          <TestQuestion />
          <TestSidebar />
        </div>
        <Footer test={true} />
      </div>
    </TestProvider>
  );
}

const TestRouteRouter = withRouter(TestRoute);

export default withRouter(Module);
