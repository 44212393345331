import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Preloader from "../Preloader/Preloader";
import queryString from "query-string";
import { useModuleContext } from "../../context/ModuleProvider";
import PracticeResultCard from "../Practice/PracticeResultCard";

function VideoQuestionArchive({ location }) {
  const { url } = useModuleContext();
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = useState();
  let { videoId } = queryString.parse(location.search);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: `${url}/video/question/archive`,
      params: { videoId },
      withCredentials: true,
    }).then((res) => {
      console.log(res.data);
      setLoading(false);
      setQuestions(res.data.questions);
    });
  }, [url]);
  return (
    <section className="content">
      {!loading ? (
        questions ? (
          <div className="container-fluid">
            <div className="row">
              {questions.length !== 0 ? (
                questions.map((question, index) => (
                  <PracticeResultCard
                    key={question.id}
                    question={question}
                    count={questions.length}
                    index={index + 1}
                  />
                ))
              ) : (
                <div>Not any video question practice yet!</div>
              )}
            </div>
          </div>
        ) : (
          <div>Video doesnot have any question to practice yet!</div>
        )
      ) : (
        <Preloader />
      )}
    </section>
  );
}

export default withRouter(VideoQuestionArchive);
