import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { useModuleContext } from "../../context/ModuleProvider";

function KhaltiSuccess({ location }) {
  const [verifing, setVerifing] = useState(false);
  const [message, setMessage] = useState("");
  const { pidx, purchase_order_id } = queryString.parse(location.search);
  let { moduleId, url } = useModuleContext();
  let history = useHistory();

  let khaltiPath =
    process.env.REACT_APP_ENV === "production"
      ? "https://khalti.com/api/v2/epayment/lookup/"
      : "https://a.khalti.com/api/v2/epayment/lookup/";

  const verifyPayment = () => {
    setVerifing(true);
    axios({
      method: "post",
      url: `${url}/payment/khalti-pay-test`,
      data: {
        khaltiPath,
        pidx,
        testId: purchase_order_id.split("-")[0],
      },
      withCredentials: true,
    })
      .then((res) => {
        setVerifing(false);
        if (res.data.error) {
          setMessage(res.data.message);
        } else {
          let { khaltiTransactionId } = res.data;

          history.replace(
            `/${moduleId}/payment/detail?khaltiTransactionId=${khaltiTransactionId}`,
            {}
          );
        }
      })
      .catch((error) => {
        setVerifing(false);
      });
  };

  useEffect(() => {
    if (pidx) {
      verifyPayment();
    }
  }, []);

  return (
    <div>
      {verifing && (
        <div>
          Please wait verifing the payment.
          {message && (
            <Link to={`/${moduleId}/payment`}>
              <button>Please try again </button>
            </Link>
          )}
        </div>
      )}
      <div>{message}</div>
    </div>
  );
}

export default withRouter(KhaltiSuccess);
