import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

function ResultQuestionCard({ question, count, index }) {
  let { answer, result } = question;
  let selected = (question.result && question.result.selected) || null;
  const [correct, setCorrect] = useState();

  const numberToLetter = { 1: 'A', 2: 'B', 3: 'C', 4: 'D' };

  useEffect(() => {
    if (selected !== null) {
      setCorrect(answer === numberToLetter[selected]);
    }
  }, [selected, answer]);

  const cardClass = result === null ? 'unattemptedBdr' : correct ? 'correctBdr' : 'wrongBdr';

  return (
    <div className="col-12 px-sm-2 px-0">
      <div className={`card cardBg test-container ${cardClass}`} style={{ boxShadow: 'none', borderRadius: "16px" }}>
        <div className="radius-15">
          {question && (
            <form className="radius-15" style={{ border: 'none' }}>
              <div className="test-question" style={{ borderRadius: "16px" }}>
                <span>
                  Test Question {index} of {count}
                </span>
                <h3 className="card-title pb-2">
                  <ReactMarkdown children={question.question} rehypePlugins={[rehypeRaw]} />
                </h3>
                <small className="text-muted">Choose the correct answer below: &nbsp;</small>
              </div>
              <Option value={1} name="A" option={question.optionA} selected={selected} answer={answer} />
              <Option value={2} name="B" option={question.optionB} selected={selected} answer={answer} />
              <Option value={3} name="C" option={question.optionC} selected={selected} answer={answer} />
              <Option value={4} name="D" option={question.optionD} selected={selected} answer={answer} />
              <br />
              <div className="boxed">
                <div>
                  <strong style={{ color: '#2ecc71' }}>The correct answer is:&nbsp;</strong>
                  <ReactMarkdown children={question[`option${answer}`]} rehypePlugins={[rehypeRaw]} />
                </div>
                <div>
                  <ReactMarkdown children={question.description} rehypePlugins={[rehypeRaw]} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="col-md-6 col-6" style={{ paddingLeft: '20px', paddingBottom: '10px' }}></div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

function Option({ value, option, selected, answer, name }) {
  let wrong = selected === value && answer !== name;
  let right = answer === name;
  let addClass = wrong ? 'wrong' : '';
  addClass = right ? 'correct' : addClass;
  let id = 'answer' + value;
  return (
    <div className={`form-check ${addClass}`} style={{ boxShadow: "1px 1px 2px 1px #0000000F", background: "rgba(255, 255, 255, 0.01)" }}>
      <input
        className="col-2 form-check-input option-input radio answer"
        type="radio"
        name="answer"
        id={id}
        defaultValue={value}
        checked={value === selected}
        disabled={true}
      />
      <label className="col-10 form-check-label" htmlFor={id}>
        <ReactMarkdown children={option} rehypePlugins={[rehypeRaw]} />
      </label>
    </div>
  );
}

export default ResultQuestionCard;
