import React, { useState, useEffect } from "react";
import axios from "axios";
import NotFound from "./NotFound";
import QuestionCard from "./QuestionCard";
import { withRouter } from "react-router-dom";
import DashboardModal from "./DashboardModal";
import Preloader from "../Preloader/Preloader";
import { useModuleContext } from "../../context/ModuleProvider";
import "./Modal.css";

function DashboardQuestion({ match }) {
  let type = match.params.type;
  let { url } = useModuleContext();
  let [originalQuestions, setOriginalQuestions] = useState(null);
  let [loading, setLoading] = useState(true);
  let [questions, setQuestions] = useState(null);
  // console.log(cp)
  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/dashboard/${type}`,
      withCredentials: true,
    }).then(async (res) => {
      console.log(res.data);
      setOriginalQuestions(res.data.results);
      setQuestions(res.data.results);
      setLoading(false);
    });
  }, [url]);

  const handleFilter = (subjectId, correct, wrong) => {
    let handleToggle = (q) => {
      if (correct === wrong) {
        return q;
      } else if (correct && q.answer === q.check) {
        return q;
      } else if (wrong && q.answer !== q.check) {
        return q;
      }
    };
    let newQuestion = originalQuestions.filter((q) => {
      if (subjectId === -1) {
        return handleToggle(q);
      } else if (q.subject === subjectId) {
        return handleToggle(q);
      }
    });
    console.log(newQuestion);
    return setQuestions(newQuestion);
  };

  return (
    <React.Fragment>
      <DashboardModal type={type} handleFilter={handleFilter} />
      {!loading ? (
        questions && questions.length !== 0 ? (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      {questions.map((q, index) => (
                        <QuestionCard
                          key={q.id}
                          q={q}
                          index={index}
                          len={questions.length}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <NotFound />
        )
      ) : (
        <Preloader />
      )}
    </React.Fragment>
  );
}

export default withRouter(DashboardQuestion);
