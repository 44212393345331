import React, { useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import Preloader from '../Preloader/Preloader';
import './PracticeUnit.css';

function PracticeUnit({ history, location, match }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const { moduleId } = match.params;
  const [unit, setUnit] = useState([]);
  const [loading, setLoading] = useState(true);
  let subjectId = queryString.parse(location.search).subjectId;
  const url = `${server}/${moduleId}/practice`;

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/subject?subjectId=${subjectId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      withCredentials: true,
    }).then((res) => {
      let unit = res.data.unit;
      if (unit.length === 0) {
        history.replace(`/${moduleId}/practice/start?subjectId=${subjectId}`);
      } else {
        setUnit(unit);
      }
      setLoading(false);
    });
  }, [url, subjectId]);
  return (
    <div id="practice-unit" style={{ marginTop: '-8px' }} className="row">
      {!loading ? (
        unit.map((u) => <UnitCard unit={u} key={u.unit_id} moduleId={moduleId} subjectId={subjectId} />)
      ) : (
        <Preloader />
      )}
    </div>
  );
}

export default withRouter(PracticeUnit);

function UnitCard({ unit, subjectId, moduleId }) {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let { unit_id, unit_name, chapters, id } = unit;
  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12">
      <div className="card subject-card subject-card-default color-palette-box">
        <div className=" subject-card-header clearfix">
          <h6 className=" subject-card-title d-inline">
            <span>{unit_name}</span>
          </h6>
          <span className="float-right d-inline">
            <Link to={`/${moduleId}/practice/unitDetail?unitId=${unit_id}&subjectId=${subjectId}`} className="see-all">
              See all
            </Link>
          </span>
          <div className="clearfix" />
        </div>
        <div className="list-group clearfix">
          {chapters.map((c) => {
            let { chapters_id, chapter_name, module_id } = c;
            //TODO chnage to chapterId
            let link = `/${module_id}/practice/start?chapterId=${chapters_id}`;
            return (
              <Link key={chapters_id} to={link} className="list-group-item list-group-item-action">
                <img className="unit-img" src={`${SERVER_URL}/images/chapter/physics/${id}.png`} alt={chapter_name} />
                {chapter_name}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
