import React, { useContext, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import { Controller, useForm } from 'react-hook-form';
import bootbox from 'bootbox';
import { RiArrowDropDownLine } from "react-icons/ri";

function RegisterFrom({ history }) {
  const { registerUser, verifyUser } = useContext(AuthContext);
  const { register, handleSubmit, errors, watch, control } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [send, setSend] = useState(false);
  const [availableModule, setAvailableModule] = useState('')

  const [showPass, setShowPass] = useState(false);

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  let watchPass = watch('password');

  const submitForm = async (data, e) => {
    e.preventDefault();
    // console.log("data", data)
    if (!send) {
      try {
        setLoading(true);
        const res = await registerUser(data);
        setLoading(false);
        if (res.data.error) {
          return setError(res.data.message);
        } else {
          setSend(true);
        }
      } catch (error) {
        let response = error.response && error.response;
        let data = response && response.data;
        let message = data && data.message;
        bootbox.alert(message || 'Something went wrong');
        setLoading(false);
      }
    } else {
      await verifyUser(data, history);
    }
  };

  useEffect(() => {
    (async () => {
      await fetch(`${process.env.REACT_APP_SERVER_URL}/course-list/available`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setAvailableModule(data.detail);
        });
    })()
  }, []);

  return (
    <form className="authForm" id="registerForm" onSubmit={handleSubmit(submitForm)}>
      <div className="instructions__login">
        {/* <div className="back-element d-sm-none d-block " style={{ position: "relative", padding: "15px 10px" }}>
          <Link to="/" className="text-dark "><i className="fa fa-long-arrow-left"></i>&nbsp;BACK</Link>
        </div> */}
        {/* <div
          class="back-element d-block"
          style={{ position: 'relative', padding: '15px 0px', fontSize: '18px', color: 'black' }}
        >
          <Link class="text-muted" to="/login">
            <i class="fas fa-arrow-left" style={{ color: 'black' }}></i>
          </Link>
        </div>
        <div className="overlay__img d-lg-none d-md-none d-none">
          <img style={{ position: 'relative' }} alt="smart Logo" src="/images/logo.png" />
        </div> */}

        <div className="overlay__img d-lg-none d-md-none d-none">
          <Link to="/">
            <img style={{ position: 'relative' }} alt="smart Logo" src="/images/logo.png" />
          </Link>
        </div>

        <div className="overlay__img d-sm-none" style={{ marginTop: '-120px', marginBottom: '50px' }}>
          <Link to="/">
            <img style={{ position: 'relative', width: '300px' }} alt="smart Logo" src="/images/paradise.svg" />
          </Link>
          <br />
          <p style={{ fontSize: '16px', marginLeft: '1.2em' }}>Leading Edu-Tech Company </p>
        </div>

        <h2 className="Welcom pb-0 mb-2" style={{ padding: '10px 0px 0px' }}>
          Welcome!
        </h2>
        <p className="small text-muted pl-1">Please fill the information below to sign up.</p>
      </div>
      <div className="error">{error}</div>

      {!send && (
        <>
          <div className={errors.name ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                pattern: {
                  required: 'Full name is required',
                  value: /^[a-zA-Z\s]*$/,
                  message: 'Invalid name',
                },
              })}
              // disabled={send}
              name="name"
              type="text"
              id="name"
              placeholder="Full Name"
            />
            <label className="input-icon-label" htmlFor="name"></label>
          </div>
          <div className="input-error">{errors.name && errors.name.message}</div>

          <div className={errors.email ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                required: 'Email is required',
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                  message: 'Invalid email',
                },
              })}
              // disabled={send}
              name="email"
              type="text"
              id="email"
              placeholder="Email"
            />
            <label className="input-icon-label" htmlFor="email">
              <i className="fas fa-envelope"></i>
            </label>
          </div>
          <div className="input-error">{errors.email && errors.email.message}</div>

          <div className={errors.phone ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                pattern: {
                  required: false,
                  value: /^[0-9]{10,}$/,
                  message: 'Invalid phone number',
                },
              })}
              // disabled={send}
              name="phone"
              type="text"
              id="phone"
              placeholder="(+977) Phone number"
            />
            <label className="input-icon-label" htmlFor="phone">
              <i className="fas fa-mobile-alt"></i>
            </label>
          </div>
          <div className="input-error">{errors.phone && errors.phone.message}</div>
          <div className="inputGroup">
            <Controller
              as={
                <select className="" id='course'>
                  <option>Select a course</option>
                  {availableModule && availableModule.map((item) => {
                    return (
                      <option id='course' key={item.id} value={item.module_id} >{item.name}</option>
                    )
                  })}
                </select>
              }
              name="moduleId"
              rules={{ required: "Select any course." }}
              control={control}
            />
            <label className="react-icon" htmlFor='course'>
              {/* <i className="flaticon-spots"></i> */}
              <RiArrowDropDownLine fontSize={32} />
            </label>
          </div>
          <div className={errors.password ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                required: 'Password is required',
                pattern: {
                  // value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
                  value: /^.{6,}$/,
                  message: 'Password must have at least 6 characters',
                },
              })}
              name="password"
              type={showPass ? 'text' : 'password'}
              disabled={send}
              id="password"
              placeholder="Password"
            />
            <label className="input-icon-label" htmlFor="email">
              <i className={`fa ${showPass ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={togglePassword}></i>
            </label>
          </div>
          <div className="input-error">{errors.password && errors.password.message}</div>

          <div className={errors.password ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                required: 'Confirm Password is required',
                validate: (value) => {
                  return value === watchPass || 'Password does not match!';
                },
              })}
              name="confirm"
              type={showPass ? 'text' : 'password'}
              disabled={send}
              id="confirm"
              placeholder="Confirm Password"
            />
            <label className="input-icon-label" htmlFor="email">
              <i className={`fa ${showPass ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={togglePassword}></i>
            </label>
          </div>
          <div className="input-error">{errors.confirm && errors.confirm.message}</div>
        </>
      )}

      {send && (
        <div className={errors.token ? 'errorfield inputGroup' : 'inputGroup'}>
          <p>Insert below the OTP sent to your mail or phone no.</p>
          <input
            ref={register({ required: 'Otp code is required' })}
            name="token"
            type="text"
            id="token"
            placeholder="OTP Code"
          />
          <label className="input-icon-label" htmlFor="token">
            <i className="fas fa-mobile-alt"></i>
          </label>
        </div>
      )}

      <div className="inputGroup d-inline ">
        <div className="btn btn-block p-0 my-2 pt-5">
          <button id="register" className="btn btn-dark rounded shadow register modified">
            {loading ? 'Loading' : send ? 'Verify OTP' : 'Register'}
          </button>
          <Link to="/login">
            <button className="btn btn-dark rounded shadow d-block mt-3" id="register">
              Go back to login&nbsp;<i className="fas fa-arrow-circle-left"></i>
            </button>
          </Link>
        </div>
      </div>
    </form>
  );
}

export default withRouter(RegisterFrom);