import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import bootbox from 'bootbox';
import './PracticeQuestion.css';
import PracticeQuestionUI from './PracticeQuestionUI';
import Preloader from '../Preloader/Preloader';
import PNotFound from './PNotFound';
import { useModuleContext } from '../../context/ModuleProvider';
import { decrypt } from '../../service/Encryption';

function PracticeQuestion({ location }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const { moduleId } = useModuleContext();
  const [question, setQuestion] = useState(null);
  const [selected, setSelected] = useState(null);
  const [submited, setSubmited] = useState(null);
  const [loading, setLoading] = useState(true);

  const url = `${server}/${moduleId}`;
  let { chapterId, unitId, subjectId } = queryString.parse(location.search);
  let [t, setT] = useState(1);
  useEffect(() => {
    fetchQuestion();
  }, [moduleId]);

  const fetchQuestion = () => {
    let finalUrl = getFinalUrl(chapterId, unitId, subjectId, url);
    axios({
      method: 'get',
      url: `${finalUrl}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      withCredentials: true,
    }).then((res) => {
      console.log(res.data.question);
      let question = res.data.question;
      if (question) {
        question = decrypt(question);
        question = JSON.parse(question);
        setQuestion(question);
      }
      setLoading(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('button pressed', selected);
    if (selected != null) {
      console.log('submit pressed');
      let data = {
        question_id: question.id,
        check: selected,
      };
      axios({
        method: 'post',
        url: `${url}/practice/start/submit`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        withCredentials: true,
        data,
      }).then((res) => {
        let { question, error, message } = res.data;
        if (error) {
          bootbox.alert(message);
        } else if (question) {
          !res.data.submited && bootbox.alert(message);
          setSubmited(true);
        }
      });
    } else {
      bootbox.alert('Please choose at least one of the given asnwers.');
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    if (!submited) {
      setSelected(e.target.value);
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    setT(++t);
    console.log('next button pressed');
    if (submited) {
      setSubmited(false);
      setSelected(null);
      fetchQuestion();
    }
  };

  const addToRevision = () => {
    let question_id = question.id;
    console.log('Add To revision clicked', question_id);
    let data = { question_id };
    axios({
      method: 'post',
      url: `${url}/revision`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
      withCredentials: true,
      data,
    }).then((res) => {
      console.log(res.data);
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      } else if (res.data.created) {
        bootbox.alert('Added Question to the Revision');
      } else {
        bootbox.alert('Question is alredy in the revision section');
      }
    });
  };

  const answer = question && question.answer;
  return !loading ? (
    question ? (
      <PracticeQuestionUI
        handleSubmit={handleSubmit}
        handleNext={handleNext}
        handleChange={handleChange}
        question={question}
        answer={answer}
        addToRevision={addToRevision}
        selected={selected}
        submited={submited}
      />
    ) : (
      <PNotFound
        header="Sorry, we are out of question."
        title="Please check back later. In the meantime, you can practice other subject."
        link={`/${moduleId}/practice`}
      />
    )
  ) : (
    <Preloader />
  );
}

export default withRouter(PracticeQuestion);

const getFinalUrl = (chapterId, unitId, subjectId, url) => {
  url = `${url}/practice/start?`;
  if (chapterId) {
    return `${url}chapterId=${chapterId}`;
  }
  if (unitId) {
    return `${url}unitId=${unitId}`;
  }
  if (subjectId) {
    return `${url}subjectId=${subjectId}`;
  }
};
