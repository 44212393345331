import axios from 'axios';
import moment from 'moment';
import {
  RECEIVE_QUESTIONS,
  REQUEST_QUESTIONS,
  HANDLE_OPTIONS_CHANGE,
  HANDLE_MARK,
  HANDLE_FLAG,
  HANDLE_SUBMIT,
  REQUEST_FAILED,
  SUBMIT_FAILED,
  ADD_LOCATION,
} from '../constants/actionTypes';
import bootbox from 'bootbox';
import { decrypt } from '../service/Encryption';
let serverUrl = `${process.env.REACT_APP_SERVER_URL}`;

export const setTest = (data) => ({
  type: 'SET_TEST',
  data,
});

const requestQuestions = (testId) => ({
  type: REQUEST_QUESTIONS,
  testId,
});

const receviceQuestions = (test) => ({
  type: RECEIVE_QUESTIONS,
  test,
});
export const _handleChange = (id, value) => ({
  type: HANDLE_OPTIONS_CHANGE,
  id: id,
  value: value,
});

export const _handleMark = (id) => ({
  type: HANDLE_MARK,
  id: id,
});

export const addLocation = (pos) => ({
  type: ADD_LOCATION,
  position: pos,
});

export const _handleFlag = (id) => ({
  type: HANDLE_FLAG,
  id: id,
});

export const fetchFailed = (error) => ({
  type: REQUEST_FAILED,
  error,
});

export const fetchQuestions = (testId, history) => {
  return (dispatch, getState) => {
    dispatch(requestQuestions(testId));
    let moduleId = getState().moduleId;
    return axios({
      method: 'get',
      url: `${serverUrl}/${moduleId}/test/question/v2?testId=${testId}`,
      withCredentials: true,
    })
      .then((res) => {
        let test = res.data.test;
        if (test) {
          test = decrypt(test);
          test = JSON.parse(test);
          let { duration, extraTime, end } = test;
          duration = moment.duration(duration, 'minute');
          extraTime = moment.duration(extraTime, 'minute');
          let remain = moment.utc(end).diff(moment.utc());
          if (remain > duration - extraTime) {
            test.end = moment.utc(end).add(duration - remain);
          } else {
            test.end = moment.utc(end).add(extraTime);
          }
          dispatch(receviceQuestions(test));
        } else {
          let error = res.data.error || 'Sorry test not found. Please try again.';
          history.push(`/${moduleId}/test`);
          dispatch(fetchFailed(error));
          bootbox.alert(error);
        }
      })
      .catch((error) => {
        bootbox.alert('Unexpected Error. Please check your network connection and try again.');
      });
  };
};

const handleSubmit = () => ({
  type: HANDLE_SUBMIT,
});

const submitFailed = () => ({
  type: SUBMIT_FAILED,
});

export const postResult = (message, history) => {
  return (dispatch, getState) => {
    dispatch(handleSubmit());
    let data = getState().data;
    let moduleId = getState().moduleId;
    let url = `${serverUrl}/${moduleId}`;
    if (data) {
      return axios({
        method: 'post',
        url: `${url}/test/submit`,
        withCredentials: true,
        data,
      })
        .then((res) => {
          console.log(res.data, 'Post data submitted response');
          if (res.data.error) {
            bootbox.alert(res.data.error);
            dispatch(submitFailed());
          } else {
            bootbox.alert(message || 'Test has been sucessfully submitted.');
            history && history.push('../dashboard');
          }
        })
        .catch((err) => {
          console.log(err);
          bootbox.alert('Please check your network connection and try again.');
          dispatch(submitFailed());
        });
    } else {
      bootbox.alert('Sorry unable to submit the test. Unexpected error.');
    }
  };
};
