import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContextProvider';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import TestStat from './TestStat';
import Swiper from 'react-id-swiper';
import { StickyContainer, Sticky } from 'react-sticky';
import { useModuleContext } from '../../context/ModuleProvider';
import { ProfileImg } from '../helper';
import 'swiper/css/swiper.css';
import './TestResult.css';
import ResultTable from './ResultTable';
import { CSVLink } from 'react-csv';

function TestResult({ location }) {
  let testId = queryString.parse(location.search).testId;
  const { currentUser } = useContext(AuthContext);
  const { moduleId, url } = useModuleContext();
  const [test, setTest] = useState([]);
  const [stat, setStat] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [exportData, setExportData] = useState([])

  const [eswiper, updateEswiper] = useState(null);
  const exploreParams = {
    spaceBetween: 5,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 5,
      },
      574: {
        slidesPerView: 3,
        spaceBetween: 7,
      },
    },
  };
  eswiper && eswiper.update();

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/test/result?testId=${testId}&isAdmin=false`,
      withCredentials: true,
    }).then((res) => {
      setTest(res.data);
      axios({
        method: 'get',
        url: `${url}/test/stat?testId=${testId}`,
        withCredentials: true,
      }).then((res) => {
        setStat(res.data);
      });
      window.addEventListener('resize', () => setWidth(window.innerWidth));
      return () => window.removeEventListener('resize');
    });
  }, [url]);
  const topper = test && test.testResults ? test.testResults.slice(0, 3) : [];
  const loser = test && test.testResults ? test.testResults.slice(3, test.testResults.length) : [];

  useEffect(() => {
    if (test.testResults && Array.isArray(test.testResults)) {
      const filteredData = test.testResults.map((item) => ({
        "Registration No": item.user.registration_id,
        "Full Name": item.user.name,
        "Total Attempted": item.cntCorrect + item.cntWrong,
        "Total Unattempted": test.total - (item.cntCorrect + item.cntWrong),
        "Total Correct": item.cntCorrect,
        "Total Incorrect": item.cntWrong,
        Score: item.cntCorrect,
      }));
      setExportData(filteredData);
    }
  }, [test]);

  return (
    <StickyContainer>
      <section className="leaderboard__section">
        <div className="row">
          <div className="col-sm-8 col-12 p-0  order-sm-1 order-2 d-none">
            <div className="seperatory my-2 radius-10 p-1">
              <div className="leader__title p-1">
                <h5 style={{ padding: '10px' }} className="d-inline-block">
                  Leaderboard for {test.name}
                </h5>
              </div>
              <div className="row " id="test-swiper">
                <Swiper getSwiper={updateEswiper} {...exploreParams}>
                  {topper.map((t, index) => {
                    let {
                      id,
                      score,
                      cntCorrect,
                      cntWrong,
                      user: { imageId, name, registration_id },
                    } = t;
                    return (
                      <div key={id} className="col-sm-4 col-12">
                        <div className="leaderboard__cards">
                          <div className="leader__cards">
                            <div className="card-body leader__body text-center">
                              <ProfileImg
                                style={{ display: 'block', margin: 'auto' }}
                                alt={'profile pic'}
                                src={imageId}
                                className=" img-fluid ledaer__img bg-cover"
                              />
                              <h6 className="pt-2 pr-0" style={{ whiteSpace: 'nowrap' }}>
                                {name}
                              </h6>
                              <br />
                              <h6 className="pr-0" style={{ whiteSpace: 'nowrap' }}>
                                Symbol No: {registration_id && registration_id}
                              </h6>
                              <br />
                              <p className="text-muted small">Total Score</p>

                              <div className="leader__bottom">
                                <h3>{score}</h3>
                                <p className="text-muted small">
                                  OUT OF
                                  {parseInt(cntWrong) + parseInt(cntCorrect)}
                                </p>
                                <div className="w-100 text-center">
                                  <h5 className="pr-0">RANK {index + 1} </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div style={{ textAlign: "end" }}>
              <CSVLink data={exportData} filename={`${test.name}-${test.module_id}-${test.start}`} className='btn btn-dark d-none'>
                Export Data
              </CSVLink>
            </div>
            <div className="d-none">
              <div className="Other__leaders">
                <div>
                  <h6 className="text-uppercase">Other Leaders</h6>
                </div>
                <div className="table__container">
                  <div className="row py-1">
                    <div className="col-12">
                      {/* <ResultTable data={loser} /> */}
                      <table
                        id="data__inTable"
                        className="table table-hover responsive nowrap"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>Rank</th>
                            <th style={{ textAlign: "center" }}>Id</th>
                            <th className='d-none'>Name</th>
                            <th style={{ textAlign: "center" }}>Total Question</th>
                            <th style={{ textAlign: "center" }}>Correct</th>
                            <th style={{ textAlign: "center" }}>Incorrect</th>
                            <th style={{ textAlign: "center" }}>Score</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loser.map((l, index) => {
                            let {
                              id,
                              score,
                              cntCorrect,
                              cntWrong,
                              user: { name, imageId, registration_id },
                            } = l;
                            return (
                              <tr key={id}>
                                <td style={{ textAlign: "center" }}>
                                  <p className="rankings">{index + 4}</p>
                                </td>
                                <td style={{ textAlign: "center" }}>{registration_id ? registration_id : "-"}</td>
                                <td className='d-none'>
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <ProfileImg
                                        className="img-fluid mr-2 small__circle bg-cover"
                                        alt={"p"}
                                        src={imageId}
                                      />
                                      <div>
                                        <p className="font-weight-bold mb-0">
                                          {name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {/*  style={{ textAlign: "center" }} */}
                                <td style={{ textAlign: "center" }}>{test.total}</td>
                                <td style={{ textAlign: "center" }}>{cntCorrect}</td>
                                <td style={{ textAlign: "center" }}>{cntWrong}</td>
                                <td style={{ textAlign: "center" }}>{score}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none">
              <div className="Other__leaders">
                <div>
                  <h6 className="text-uppercase">Test History</h6>
                </div>
                <div className="table__container">
                  <div className="row py-1">
                    <div className="col-12">
                      <table
                        id="data__inTable"
                        className="table table-hover responsive nowrap"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>Test</th>
                            <th style={{ textAlign: "center" }}>Total Question</th>
                            <th style={{ textAlign: "center" }}>Correct</th>
                            <th style={{ textAlign: "center" }}>Incorrect</th>
                            <th style={{ textAlign: "center" }}>Score</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loser.map((l, index) => {
                            let {
                              id,
                              score,
                              cntCorrect,
                              cntWrong,
                              user: { name, imageId, registration_id },
                            } = l;
                            return (
                              <tr key={id}>
                                <td style={{ textAlign: "center" }}>
                                  <p className="rankings">{index + 4}</p>
                                </td>
                                <td style={{ textAlign: "center" }}>{test.total}</td>
                                <td style={{ textAlign: "center" }}>{cntCorrect}</td>
                                <td style={{ textAlign: "center" }}>{cntWrong}</td>
                                <td style={{ textAlign: "center" }}>{score}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {stat && (
            <div className={`px-0 mt-2 mb-1 radius-10 col-12`}>
              {/* px-0 mt-2 mb-1 radius-10 col-sm-4 col-12 order-sm-3 order-1 */}
              {width > 575 ? (
                <Sticky topOffset={10}>
                  {({ style }) => (
                    <div style={style}>
                      <TestStat stat={stat} currentUser={currentUser} moduleId={moduleId} testId={testId} />
                    </div>
                  )}
                </Sticky>
              ) : (
                <TestStat stat={stat} currentUser={currentUser} moduleId={moduleId} testId={testId} />
              )}
            </div>
          )}
        </div>
      </section>
    </StickyContainer >
  );
}

export default withRouter(TestResult);