import React, { useState, useEffect } from "react";
import { useModuleContext } from "../../context/ModuleProvider";
import axios from "axios";
import moment from "moment";
import queryString from "query-string";
import { useAuthContext } from "../../context/AuthContextProvider";
import { Link, useLocation, withRouter } from "react-router-dom";

function PaymentDetail() {
  let { url, currentModule, moduleId } = useModuleContext();
  let { currentUser } = useAuthContext();
  let [payment, setPayment] = useState();
  let [loading, setLoading] = useState();
  let location = useLocation();
  const { paymentId } = queryString.parse(location.search);
  const { zoomPaymentId } = queryString.parse(location.search);
  const { rid, khaltiTransactionId } = queryString.parse(location.search);

  let paymentDetailURL = "";
// 
  if (rid) {
    if(paymentId) {
      paymentDetailURL = `${url}/payment/detail?paymentId=${paymentId}&rid=${rid}`;
    }
    else {
      paymentDetailURL = `${url}/payment/test-detail?rid=${rid}`;
    }
  } else if (khaltiTransactionId) {
    paymentDetailURL = `${url}/payment/test-detail?khaltiTransactionId=${khaltiTransactionId}`;
  } else if (zoomPaymentId) {
    paymentDetailURL = `${url}/zoom-plan-payment/detail?paymentId=${zoomPaymentId}`;
  } else {
    paymentDetailURL = `${url}/payment/detail?paymentId=${paymentId}`;
  }

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: paymentDetailURL,
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      if (zoomPaymentId) {
        let data = { ...res.data.payment };
        if (res.data.liveGroup) {
          data = { ...data, groupTitle: res.data.liveGroup.title };
        }
        setPayment(data);
      } else {
        setPayment(res.data.payment);
      }
    });
  }, []);

  return (
    <div className="w-100">
      {/* {currentModule.expireDate && (
        <div>Your expireDate is {currentModule.expireDate}</div>
      )} */}
      <div
        className="row d-flex justify-content-center"
        style={{ height: "100vh", background: "#f8fcff" }}
      >
        {!loading ? (
          <div className="col-12 col-md-6">
            {/* <div className="back-element p-1 clearfix" style={{ position: 'relative' }}>
                <a href="/mbbs/" className="text-muted float-right">
                  <i className="fas fa-close fa-2x"></i>
                </a>
              </div> */}

            <div style={{ margin: "40px 10px" }} className="card p-3 d-flex">
              <img
                height="80px"
                width="70px"
                src="/images/check.png"
                alt="Verified"
                className="center m-auto pt-3 mt-3"
              />
              <div
                className="m-auto pt-3  pb-3 text-center"
                style={{ fontSize: "20px" }}
              >
                Payment Successful!
                <h2 className="mb-0 d-block">
                  THANK YOU{" "}
                  <span role="img" aria-label="smiley">
                    &#128578;
                  </span>
                </h2>
              </div>
              {payment && (
                <div>
                  <div className="d-flex justify-content-between pb-2">
                    <div>Name </div>
                    <div>{currentUser.name}</div>
                  </div>
                  <div className="d-flex justify-content-between pb-2">
                    <div>Date </div>
                    <div>
                      {moment.utc(payment.ent_date).local().format("lll")}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between pb-2">
                    <div>Payment Type </div>
                    <div>{payment.merchantType.toUpperCase()}</div>
                  </div>
                  {zoomPaymentId ? (
                    <div className="d-flex justify-content-between pb-2">
                      <div className="">Subscription Title</div>
                      <div className="">{payment.groupTitle}</div>
                    </div>
                  ) : (
                    <>
                      {!(rid || khaltiTransactionId) && (
                        <div className="d-flex justify-content-between pb-2">
                          <div className="">Subscription Period</div>
                          <div className="">
                            {payment.subscriptionPeriod} months
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div
                    className="d-flex justify-content-between py-2 "
                    style={{ fontWeight: "600", fontSize: "20px" }}
                  >
                    <div className="bold">Amount paid</div>
                    <div className="bold">Rs. {payment.amount}</div>
                  </div>
                  {zoomPaymentId ? (
                    <></>
                  ) : (
                    <>
                      {!(rid || khaltiTransactionId) && (
                        <div className="d-flex justify-content-between pb-2 ">
                          <div className="">Expire Date</div>
                          <div className="">
                            {moment
                              .utc(currentModule.expireDate)
                              .local()
                              .format("lll")}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <Link
                  to={`/${moduleId}/${
                    rid || khaltiTransactionId ? "test" : "dashboard"
                  }`}
                  className="btn btn-success py-3 w-100 radius-15"
                  style={{}}
                >
                  <i className="fa fa-check"></i>&nbsp;Done
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}

export default withRouter(PaymentDetail);
