import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import bootbox from "bootbox";
import $ from "jquery";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useForm } from "react-hook-form";
import Preloader from "../Preloader/Preloader";
import { useModuleContext } from "../../context/ModuleProvider";
import { useAuthContext } from "../../context/AuthContextProvider";
import {
  FormGroup,
  FormGroupArea,
  FormGroupSelect,
  ModalForm,
} from "../ui/ModalForm";

function QBankQuestion({ location, history }) {
  const server = process.env.REACT_APP_SERVER_URL;
  const { moduleId } = useModuleContext();
  const { currentUser } = useAuthContext();
  const [questions, setQuestions] = useState(null);
  const questionsRef = useRef(questions);
  const [attempt, setAttempt] = useState([]);
  const [submited, setSubmited] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [solvedCount, setSolvedCount] = useState(null);
  const [reportingQuestion, setReportingQuestion] = useState(null);
  const url = `${server}/${moduleId}`;

  const handleSubmit = () => {
    setSubmitting(true);
    if (attempt.length !== questions.length) {
      let unsolvedId;
      let newQuestions = questions.map((q) => {
        if (!q.selected) {
          !unsolvedId && (unsolvedId = q.id);
          return { ...q, error: "Please select the value before submiting." };
        }
        q.check = q.selected;
        return q;
      });
      setQuestions(newQuestions);
      questionsRef.current = newQuestions;
      let item = document.getElementById(`q${unsolvedId}`);
      item &&
        item.scrollIntoView({
          behavior: "smooth",
        });
      setSubmitting(false);
    } else {
      axios({
        method: "post",
        url: `${url}/qbank/submit/stat`,
        withCredentials: true,
        data: { attempt },
      }).then((res) => {
        let { error } = res.data;
        if (error) {
          bootbox.alert(error);
        } else {
          setSubmited(true);
          history.push(`/${moduleId}/dashboard`);
          bootbox.alert("Sucessfully qbank questions submitted.");
        }
      });
    }
  };

  const handleOption = (value, id) => {
    let newAttempt = attempt;
    let newQuestions = questionsRef.current.map((q) => {
      let already = false;
      if (q.id === id) {
        attempt.forEach((a) => {
          a.question_id === q.id && (already = true);
        });
        if (!already) {
          newAttempt.push({ question_id: q.id, selected: value, check: value });
          setAttempt(newAttempt);
        }
        return { ...q, selected: value, check: value, error: null };
      }
      return q;
    });
    setQuestions(newQuestions);
    questionsRef.current = newQuestions;
  };

  const addToRevision = (id) => {
    let question_id = id;
    let data = { question_id };

    axios({
      method: "post",
      url: `${url}/revision`,
      withCredentials: true,
      data,
    }).then((res) => {
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      } else if (res.data.created) {
        bootbox.alert("Added Question to the Revision");
      } else {
        bootbox.alert("Question is alredy in the revision section");
      }
    });
  };

  const addToPoll = (poll) => {
    let {
      optionA,
      optionB,
      optionC,
      optionD,
      question,
      subject: subjectId,
    } = poll;
    let data = {
      question,
      subjectId,
      options: [
        { option: optionA },
        { option: optionB },
        { option: optionC },
        { option: optionD },
      ],
    };
    axios({
      method: "post",
      url: `${url}/poll?subjectId=${subjectId}`,
      withCredentials: true,
      data,
    }).then((res) => {
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      } else {
        let newQuestions = questionsRef.current.map((q) => {
          if (q.id === poll.id) {
            return { ...q, poll: true };
          }
          return q;
        });
        setQuestions(newQuestions);
        questionsRef.current = newQuestions;
        if (res.data.created) {
          bootbox.alert("Question has been added to the poll");
        } else {
          bootbox.alert("Question has been already added to the poll");
        }
      }
    });
  };

  const reportQuestion = ({ title, description }) => {
    let data = {
      question_id: reportingQuestion.id,
      user_id: currentUser.id,
      module_id: moduleId,
      title,
      description,
    };
    axios({
      method: "post",
      url: `${url}/practice/report/${reportingQuestion.id}`,
      withCredentials: true,
      data,
    }).then((res) => {
      if (res.data.error) {
        return bootbox.alert(res.data.message);
      }
      $("#reportQuestion").hide();
      $(".modal-backdrop").hide();
      bootbox.alert("Question reported successfully.");
    });
  };

  useEffect(() => {
    let finalUrl = `${url}/qbank/start`;
    if (location.state) {
      let { units, subjects, limit } = location.state;
      let data = {};
      data.units = units || [];
      data.subjects = subjects || [];
      data.limit = limit || 10;
      axios({
        method: "post",
        url: `${finalUrl}`,
        withCredentials: true,
        data,
      }).then((res) => {
        let questions = res.data.questions;
        if (questions) {
          setQuestions(questions);
          questionsRef.current = questions;
        }
        setSolvedCount(res.data.count);
        setLoading(false);
      });
    } else {
      history.push(`/${moduleId}/qbank`);
    }
  }, [moduleId, location.state]);

  return !loading ? (
    questions ? (
      <div id="qbank" className="container mx-auto">
        <ReportModal reportQuestion={reportQuestion} />
        {questions.map((question, index) => {
          const answer = question.answer;
          let count = solvedCount + index + 1;
          return (
            <MemoizedQuestion
              key={question.id}
              handleOption={handleOption}
              question={question}
              count={count}
              answer={answer}
              addToRevision={() => addToRevision(question.id)}
              addToPoll={() => addToPoll(question)}
              setReportingQuestion={setReportingQuestion}
              // reportQuestion={(e) => reportQuestion(question.id)}
              selected={question.selected}
              submited={submited}
            />
          );
        })}

        <div className="d-flex justify-content-end pb-3">
          {!submitting ? (
            <button
              id="sub"
              onClick={() => handleSubmit()}
              className="btn btn-success "
            >
              Submit
            </button>
          ) : (
            <button id="sub" className="btn btn-success ">
              Submitting..
            </button>
          )}
        </div>
      </div>
    ) : (
      <div>Questions Not Found</div>
    )
  ) : (
    <Preloader />
  );
}

function ReportModal({ reportQuestion }) {
  const { register, handleSubmit, errors, setError, control } = useForm();

  return (
    <ModalForm id={"reportQuestion"} title={"Report Question"}>
      <form className="bg-white" onSubmit={handleSubmit(reportQuestion)}>
        <div
          className="px-4 mb-2"
          style={{
            height: "73vh",
            overflowY: "auto",
            boxShadow: "rgb(233 236 239) -1px -2px 12px 2px inset",
          }}
        >
          <FormGroup
            title="Title"
            id="title"
            register={register({ required: "title is required." })}
            error={errors.title}
          />
          {/* <FormGroupSelect
            // list={subject}
            list={[]}
            value="subject_id"
            display="subject_name"
            control={control}
            id="subjectId"
            error={errors.subjectId}
          /> */}

          <FormGroupArea
            id="description"
            title="Description"
            control={control}
            register={register({ required: "description is required." })}
            error={errors.description}
          />
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary">Report</button>
          </div>
        </div>
      </form>
    </ModalForm>
  );
}

function Question({
  question,
  handleOption,
  addToRevision,
  addToPoll,
  setReportingQuestion,
  answer,
  submited,
  count,
}) {
  let [selected, setSelected] = useState(question.selected);

  const handleChange = (e) => {
    let value = e.target.value;
    setSelected(value);
    handleOption(value, question.id);
  };

  return (
    <section
      id={`q${question.id}`}
      name={`q${question.id}`}
      className="content"
      style={{ paddingBottom: "16px" }}
    >
      <div className="row">
        <div className="col-12 px-sm-2 px-0">
          <div className="card border-none" style={{ boxShadow: "none" }}>
            <form className="radius-15" style={{ border: "none" }}>
              <div className="test-question">
                <span>Practice Question {count} of 2037</span>

                <h3 className="card-title pb-2">
                  <ReactMarkdown
                    children={question.question}
                    rehypePlugins={[rehypeRaw]}
                  />
                </h3>
                <small className="text-muted">
                  Choose the correct answer below:
                </small>
                {question.error && (
                  <div className="small text-danger">
                    {" "}
                    Please select option before submitting.{" "}
                  </div>
                )}
              </div>
              <Option
                value="1"
                name="A"
                questionId={question.id}
                handleChange={handleChange}
                option={question.optionA}
                selected={selected}
                answer={answer}
                submited={submited}
              />
              <Option
                value="2"
                name="B"
                questionId={question.id}
                handleChange={handleChange}
                option={question.optionB}
                selected={selected}
                answer={answer}
                submited={submited}
              />
              <Option
                value="3"
                name="C"
                questionId={question.id}
                handleChange={handleChange}
                option={question.optionC}
                selected={selected}
                answer={answer}
                submited={submited}
              />
              <Option
                value="4"
                name="D"
                questionId={question.id}
                handleChange={handleChange}
                option={question.optionD}
                selected={selected}
                answer={answer}
                submited={submited}
              />
              <br />
              <br />
              <div className="row">
                <div className="col-md-12 col-12">
                  <div
                    className="col-md-6 col-6"
                    style={{ paddingLeft: "20px", paddingBottom: "10px" }}
                  ></div>
                  <div
                    align="right"
                    className="p-2 col-md-6 col-6 pull-right"
                    style={{ marginTop: "-55px" }}
                  >
                    <div
                      className="btn flag"
                      style={{ color: "#f5378e", padding: "0px" }}
                      title="Flag this question"
                      data-toggle="modal"
                      data-target="#reportQuestion"
                      data-whatever="report question"
                      onClick={(e) => setReportingQuestion(question)}
                    >
                      <span className="fa-stack fa-lg">
                        <i className="fa fa-circle fa-stack-2x" />
                        <i className="fa fa-flag fa-stack-1x fa-inverse" />
                      </span>
                    </div>

                    <button
                      type="button"
                      className="btn bookmark"
                      style={{ color: "var(--warning)", padding: "0px" }}
                      onClick={addToRevision}
                    >
                      <span className="fa-stack fa-lg">
                        <i className="fa fa-circle fa-stack-2x" />
                        <i className="fa fa-bookmark fa-stack-1x fa-inverse" />
                      </span>
                    </button>

                    <div
                      className="btn add-poll"
                      id="save"
                      style={{ color: "#0db233", padding: "0px" }}
                      title="Add To Poll"
                      onClick={addToPoll}
                    >
                      <span className="fa-stack fa-lg">
                        <i className="fa fa-circle fa-stack-2x" />
                        <i className="fa fa-question fa-stack-1x fa-inverse" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

const MemoizedQuestion = React.memo(Question, (prevProps, nextProps) => {
  return (
    prevProps.selected === nextProps.selected &&
    prevProps.question.error === nextProps.question.error
  );
});

function Option({
  value,
  questionId,
  option,
  name,
  handleChange,
  selected,
  answer,
  submited,
  t,
}) {
  let wrong = submited && selected === value && answer !== value;
  let right = submited && answer === value;
  let addClass = wrong ? "wrong" : "";
  addClass = right ? "correct" : addClass;
  let id = `q${questionId}-` + "answer" + value;
  let c = "answer" + value;
  return (
    <div className={`form-check ${addClass}`}>
      <input
        className={`col-2 form-check-input option-input radio answer ${c}`}
        type="radio"
        name="answer"
        id={id}
        onChange={handleChange}
        defaultValue={value}
        checked={value === selected}
        disabled={submited}
      />
      <label className="col-10 form-check-label" htmlFor={id}>
        <ReactMarkdown children={option} rehypePlugins={[rehypeRaw]} />
      </label>
    </div>
  );
}

export default withRouter(QBankQuestion);
