import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

function Summary({ url, moduleId }) {
  let [stat, setStat] = useState();
  useEffect(() => {
    setStat({ correct: 0, total: 0 });
    axios({
      method: 'get',
      url: `${url}/dashboard/answer`,
      withCredentials: true,
    }).then(async (res) => {
      setStat(res.data.results);
    });
  }, [url]);
  return (
    <div className="row-12" id="stat">
      <div className="col-12 my-2 px-0" id="stat">
        <div className="bg-white pt-2 pb-2 pl-3 pr-2  chart_contain dash__shadow dash__border">
          <h6 className='font-weight-bold'>Quick Links</h6>
          <div className="row">
            <div className="col-md-6 col-6 pr-sm-2 pr-1 pl-sm-0 ">
              <NavLink to={`/${moduleId}/practice`}>
                <div className="info-box p-md-2 p-2 pl-5">
                  <span className="info-box-icon">
                    <i className="fas fa-photo-video" style={{ color: '#3498DB' }}></i>
                  </span>
                  <div className="info-box-content ">
                    <span className="link-title pl-0" style={{ fontSize: '18 px' }}>
                      Videos
                    </span>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="col-md-6 col-6  pr-sm-2 pr-1 pl-sm-0 pl-1">
              <NavLink to={`/${moduleId}/discussion`}>
                <div className="info-box p-md-2 p-2">
                  <span className="info-box-icon">
                    <i className="fas fa-comments" style={{ color: '#1ABC9C' }} />
                  </span>
                  <div className="info-box-content ">
                    <span className="link-title pl-0" style={{ fontSize: '18 px' }}>
                      Discussion
                    </span>
                    <span className="info-box-text text-muted "></span>
                  </div>
                </div>
              </NavLink>
            </div>
            {/* <div className="col-md-6 col-6  pr-sm-2 pr-1 pl-sm-0 pl-1">
              <div className="info-box p-md-2 p-2">
                <div className="info-box-content pl-0  pt-3">
                  <span className="info-box-number pl-0" style={{ fontSize: '22px' }}>
                    {stat && stat.total - stat.correct}{' '}
                  </span>
                  <span className="info-box-text text-muted">Total Wrong</span>
                </div>
                <span className="info-box-icon">
                  <i className="far fa-chart-bar" style={{ color: '#EB984E' }} />
                </span>
              </div>
            </div>
            <div className="col-md-6 col-6  pr-sm-2 pr-1 pl-sm-0 pl-1">
              <div className="info-box p-md-2 p-1">
                <div className="info-box-content pl-0  pt-3">
                  <span className="info-box-number pl-0" style={{ fontSize: '22px' }}>
                    {stat && stat.revision}
                  </span>
                  <span className="info-box-text text-muted">Total Revised</span>
                </div>
                <span className="info-box-icon">
                  <i className="far fa-chart-bar" style={{ color: '#BB8FCE' }} />
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>

    // <div className="row stat_dash mt-2">
    //   <div className="col-12">
    //     <h6 className="mb-2">Your Summary</h6>
    //   </div>
    //   <div className="col-md-4 col-sm-6 col-12">
    //     <Link to={`/${moduleId}/dashboard/taq`}>
    //       <div className="card mb-1 mb-sm-0 dash__border no-shadow">
    //         <div className="row card_image">
    //           <div className="dash__points dash__primary">
    //             <p className="mb-0">{stat && stat.total}</p>
    //           </div>
    //           <div className="card_title title-white">
    //             <p className="mb-0">Total Attempted Questions <i className="fas fa-arrow-right pl-2" /></p>
    //           </div>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    //   <div className="col-md-4 col-sm-6 col-12">
    //     <Link to={`/${moduleId}/dashboard/tcq`}>
    //       <div className="card mb-1 mb-sm-0 dash__border no-shadow">
    //         <div className="row card_image card_image2">
    //           <div className="dash__points dash__success">
    //             <p className="mb-0">{stat && stat.correct}</p>
    //           </div>
    //           <div className="card_title title-white">
    //             <p className="mb-0">Total Correct Answers <i className="fas fa-arrow-right pl-2" /></p>
    //           </div>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    //   <div className="col-md-4 col-sm-6 col-12">
    //     <Link to={`/${moduleId}/dashboard/twq`}>
    //       <div className="card mb-1 mb-sm-0 dash__border no-shadow">
    //         <div className="row card_image card_image3">
    //           <div className="dash__points dash__danger">
    //             <p className="mb-0">{stat && (stat.total - stat.correct)}</p>
    //           </div>
    //           <div className="card_title title-white">
    //             <p className="mb-0">Total Wrong Answers <i className="fas fa-arrow-right pl-2" /></p>
    //           </div>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    // </div>
  );
}

export default Summary;
