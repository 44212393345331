import React from "react";
import { Link } from "react-router-dom";

import { useModuleContext } from "../../context/ModuleProvider";

function NoMatch() {
  let { moduleId } = useModuleContext();

  console.log("NoMatch called");
  return (
    <div className="no-match">
      <div className="text">Opps! This page does not exist</div>
      <Link to={`/${moduleId}/dashboard`}>
        <button className="btn">Visit Smart Academy</button>
      </Link>
    </div>
  );
}

export default NoMatch;
