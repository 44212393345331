import React from 'react';
import { Link } from 'react-router-dom';
import { useModuleContext } from '../../context/ModuleProvider';

function PNotFound({ src = '/images/revision.gif', header = 'Sorry, not found.', title, link, linkTxt = 'Try Them' }) {
  let { moduleId } = useModuleContext();

  return (
    <section className="content" style={{ backgroundColor: 'white', marginTop: '-25px', height: '100vh' }}>
      <div className="row">
        <div className="col-12">
          <div className="text-center" style={{ paddingTop: '40px' }}>
            <img src="/images/revision.gif" alt="NOT FOUND" style={{ width: '350px' }} />
          </div>
          <br />
          <div className="text-center text-muted">
            <div>
              <h2 style={{ padding: '10px', fontFamily: 'inherit' }} className="text-header">
                {header}
              </h2>
            </div>
            {title}
            <br />
            <br />
            {link && (
              <Link style={{ marginBottom: '60px' }} to={`/${moduleId}/practice`} className="btn btn-dark">
                {linkTxt}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PNotFound;
