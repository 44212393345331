import React, { useState, useEffect, useRef } from 'react';
import { getProfileLink } from '../helper';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

function LiveMessage({ roomId, socket }) {
  let messagesRef = useRef([]);
  let [messages, setMessages] = useState([]);
  let [umessage, setUmessage] = useState('');
  let [info, setInfo] = useState('');
  let {
    currentUser: { id, name, imageId },
  } = useContext(AuthContext);
  let myUser = { id, name, imageId };

  const messageReceive = (message) => {
    console.log('Message received', message, messagesRef);
    let messages = messagesRef.current;
    let newMessage = [...messages, message];
    console.log(newMessage, messagesRef);
    messagesRef.current = newMessage;
    setMessages([...messages, message]);
  };
  const handleUmessgae = (e) => {
    e.preventDefault();
    socket.emit('ChatMessage', { text: umessage }, roomId);
    let messages = messagesRef.current;
    let newMessage = [...messages, { text: umessage, id: uuid(), from: myUser, time: moment() }];
    messagesRef.current = newMessage;
    setMessages(newMessage);
    setUmessage('');
  };

  useEffect(() => {
    socket.on('ChatMessage', (message) => messageReceive(message));
    socket.on('disconnect', () => {
      console.log('User disconnected.');
      // setInfo("User disconnected. Check your internet connection.");
    });
  }, []);

  return (
    <section className="msger mb-4">
      {info}
      <main className="msger-chat">
        <div className="msg left-msg">
          <div
            className="msg-img"
            style={{ backgroundImage: 'url(https://image.flaticon.com/icons/svg/145/145867.svg)' }}
          />
          <div className="msg-bubble">
            <div className="msg-info">
              <div className="msg-info-name">Ronesh</div>
              <div className="msg-info-time">
                12:45<span className="px-2">|</span>
                <span className="msg-info-day">19-04-2020</span>
              </div>
            </div>
            <div className="msg-text">Hi, welcome to Smart Academy! Go ahead and send us a message.</div>
          </div>
        </div>
        {id &&
          messages.map((m) => {
            return <Message key={m.id} message={m} userId={id} />;
          })}
      </main>
      <form onSubmit={handleUmessgae} className="msger-inputarea" style={{ bottom: '70px' }}>
        <input
          type="text"
          name="m-message"
          value={umessage}
          onChange={(e) => setUmessage(e.target.value)}
          className="msger-input form-control"
          placeholder="Enter your message..."
        />
        <button disabled={umessage === ''} type="submit" className="msger-send-btn">
          Send
        </button>
      </form>
    </section>
  );
}

function Message({ message, userId }) {
  let { from, text, id } = message;
  let time = moment.utc(message.time).local();
  let you = from && userId === from.id;
  let customClass = you ? 'right-msg' : 'left-msg';
  return (
    <div className={`msg ${customClass}`}>
      <div className="msg-img" style={{ backgroundImage: `url(${getProfileLink(from.imageId)})` }} />
      <div className="msg-bubble">
        <div className="msg-info">
          <div className="msg-info-name">{you ? 'you' : from.name}</div>
          <div className="msg-info-time">
            {time.format('hh:mm')}
            <span className="px-2">|</span>
            <span className="msg-info-day">{time.format('DD-MM-YYYY')}</span>
          </div>
        </div>
        <div className="msg-text">{text}</div>
      </div>
    </div>
  );
}

export default LiveMessage;
