import React, { useEffect, useState } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContextProvider';
import axios from 'axios';
import Swiper from 'swiper';
import $ from 'jquery';
import './Enroll.css';
import { FiLogOut } from "react-icons/fi";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;
let isMobile = window.innerWidth < 767;

function Home({ location, history }) {
  const { currentUser, logout } = useAuthContext();
  const [courseGroups, setCourseGroups] = useState([]);
  const [activeHash, setActiveHash] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${serverUrl}/home/course`,
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      let courseGroups = res.data.courseGroups;
      courseGroups && setCourseGroups(courseGroups);
    });
  }, []);

  useEffect(() => {
    let mainNavLinks = document.querySelectorAll('nav ul li a');

    const handleNav = (event) => {
      let fromTop = window.scrollY + 160;
      mainNavLinks.forEach((link) => {
        let hash = link.hash;
        let section = hash && document.getElementById(hash.substring(1));
        if (section && section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
          hash && history.replace(`/enroll/${hash}`, 7000);
          hash && setActiveHash(hash.substring(1));
        }
      });
    };
    if (location.hash) {
      let element = document.getElementById(location.hash.substring(1));
      element &&
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element ? element.offsetTop : 160,
        });
    }
    handleNav();
    window.addEventListener('scroll', handleNav);
    return () => window.removeEventListener('scroll', handleNav);
  }, [courseGroups]);

  useEffect(() => {
    var swiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      freeMode: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }, [courseGroups]);

  return (
    <section id="course-land">
      <div
        id="course-nav"
        className="shadow-sm sticky-top bg-white d-lg-flex p-2 flex-row justify-content-between align-items-end"
        style={{ position: 'fixed', width: '100%' }}
      >
        <div className="w-25">
          <a href="/" style={{ opacity: 1 }}>
            <div className="smart-logo">
              <img alt="smart Logo" height="50px" width="200px" src="/images/paradise.svg" />
            </div>
          </a>
        </div>
        <div className='d-flex align-items-center justify-content-center'>
          <Link to="/" type="button" className="btn btn-primary m-0 mt-4 mr-md-4 mr-2 py-3 px-4 float-right">
            <span style={{ fontSize: '14px' }}>My Courses</span>
          </Link>
          <Tippy content="Logout" placement="top">
            <Link className='mt-4 mr-md-4 mr-2 logout' to="/login" onClick={logout}>
              <FiLogOut fontSize={20} color='#DC3545' cursor={"pointer"} />
            </Link>
          </Tippy>
        </div>
      </div>
      <div className="w-100 pt-60 px-4 enroll-container">
        <div className="row">
          <div className="col-md-3 mob-none" style={{ position: 'fixed' }}>
            <div className="Institution">
              <div className="course-institution__title">
                <h5
                  style={{
                    fontWeight: 600,
                    fontSize: '24px',
                    color: '#2E4053',
                  }}
                >
                  Hi {currentUser && currentUser.name}!
                </h5>
                <br />
                <p className="lead">Find a course you want to learn : </p>
              </div>
              {/* <div className="course-institution__search">
                <div className="form-group has-search">
                  <div className="input-group course-search-box">
                    <input
                      type="text"
                      className="form-control form-control-lg border-0 bg-white px-3 py-3"
                      placeholder="Search course..."
                    />
                    <div className="course-input-group-prepend">
                      <button className="btn course-btn-primary" type="button">
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <nav className="course-scroll-section">
              <ul>
                {courseGroups.map((group) => {
                  let active = activeHash === group.groupId;
                  return (
                    <li key={group.id}>
                      <a href={`#${group.groupId}`} className={active ? 'current' : 'remove'}>
                        {group.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div className="col-md-9 desk-ml-25">
            <div className="courses-category">
              <main>
                {courseGroups.map((group) => {
                  return <CourseCategory key={group.id} group={group} />;
                })}
              </main>
            </div>
          </div>
          {courseGroups.map((group) => {
            return (
              group.courses &&
              group.courses.map((course) => {
                if (course.available) {
                  return <AvailableModel key={course.id} course={course} />;
                } else {
                  return <NotAvailableModel key={course.id} course={course} />;
                }
              })
            );
          })}
        </div>
      </div>
    </section >
  );
}

function AvailableModel({ course }) {
  let { module_id, name, description } = course;
  const goToLink = () => {
    axios({
      method: 'get',
      url: `${serverUrl}/${module_id}/access `,
      withCredentials: true,
    }).then((res) => {
      $(`#${module_id}-modal`).modal('hide');
      window.location.href = '/';
    });
  };

  return (
    // <div className="modal" id={`${module_id}-modal`}>
    //   <div className="modal-dialog modal-bottom">
    //     <div className="modal-content">
    //       {/* Modal Header */}
    //       <div className="modal-header" style={{ borderBottom: '0px' }}>
    //         <button type="button" className="close" data-dismiss="modal">
    //           ×
    //         </button>
    //       </div>
    //       <div className="modal-body">
    //         <div style={{ textAlign: 'center', marginTop: '-20px' }}>
    //           <img src="/images/backpack.png" alt="backpack" style={{ width: '40px' }} />
    //           <p
    //             className="modal-title text-center"
    //             style={{
    //               fontWeight: 700,
    //               paddingTop: '5px',
    //               paddingBottom: '10px',
    //             }}
    //           >
    //             {name}
    //           </p>
    //         </div>
    //         <br />
    //         <p style={{ padding: '20px' }}>
    //           {description
    //             ? description
    //             : `This is ${name} Course offered by Smart Academy. You can enroll now to learn more about the course and its content. Click the Enroll Now button below.`}
    //         </p>
    //         <hr />
    //         <div style={{ textAlign: 'right' }}>
    //           <div onClick={goToLink} className="btn btn-primary px-3">
    //             Enroll Now
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div
      className="modal fade"
      id={`${module_id}-modal`}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="subDetailTitle"
      aria-hidden="true"
      style={{ left: `${isMobile ? '-20px' : '0px'}` }}
    >
      <div
        className="modal-dialog"
        role="document"
        style={{
          width: '100%',
          height: `${isMobile ? '100%' : '95%'}`,
          marginTop: '-1px',
          position: 'absolute',
          left: `${isMobile ? '0' : '15vw'}`,
          top: `${isMobile ? '0' : '1em'}`,
        }}
      >
        <div className="modal-content" style={{ height: '100%', minWidth: '70vw' }}>
          <div className="modal-header" style={{ minHeight: '50px', padding: '.5em 2em' }}>
            <h5 className="modal-title" id="AskTitle">
              Course Details
            </h5>
            <button
              type="button"
              className="close btn btn-link btn-sm px-2 py-1 m-1"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{ minHeight: isMobile ? 'calc(100vh - 150px)' : 'calc(100vh - 175px)' }}>
            <div
              className="d-flex flex-row justify-content-lg-between"
              style={{ textAlign: 'left', padding: '10px 15px' }}
            >
              <div style={{ width: '12%' }}>
                <img src="/images/backpack.png" alt="backpack" style={{ width: '40px' }} />
              </div>
              <p
                className="modal-title text-center"
                style={{
                  fontWeight: 700,
                  paddingTop: '5px',
                  paddingBottom: '10px',
                }}
              >
                {name}
              </p>

              {!isMobile && (
                <div onClick={goToLink} className="btn btn-primary px-3" style={{ width: '15%' }}>
                  Enroll Now
                </div>
              )}
            </div>
            <br />
            <p style={{ padding: '10px 20px' }}>
              {description ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              ) : (
                `This is ${name} Course offered by Smart Academy. You can enroll now to learn more about the course and its content. Click the Enroll Now button to continue.`
              )}
            </p>
          </div>
          <div
            className="modal-footer"
            style={{ minHeight: isMobile ? '60px' : '75px', padding: isMobile ? '1em 2em' : '1em' }}
          >
            <div
              onClick={goToLink}
              style={{ width: `${isMobile ? '100%' : '20%'}` }}
              className="btn btn-block btn-primary px-3 py-3"
            >
              Enroll Now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NotAvailableModel({ course }) {
  return (
    <div className="modal" id={`${course.module_id}-modal`}>
      <div className="modal-dialog modal-bottom">
        <div className="modal-content">
          {/* Modal Header */}
          <div className="modal-header" style={{ borderBottom: '0px' }}>
            <button type="button" className="close" data-dismiss="modal">
              ×
            </button>
          </div>
          <div className="modal-body">
            <div style={{ textAlign: 'center', marginTop: '-30px' }}>
              <img src="/images/no-course.jpg" alt="Course Not Found" style={{ width: '300px' }} />
              <p
                className="modal-title text-center"
                style={{
                  fontWeight: 700,
                  paddingTop: '5px',
                  paddingBottom: '10px',
                }}
              >
                Course is under development! &nbsp;
              </p>
              <p>We'll notify you on launch date!</p>
            </div>
            <div style={{ textAlign: 'right', paddingTop: '10px' }}>
              {/* <a href="# " className="btn btn-outline-secondary">
                Close
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CourseCategory({ group }) {
  let { name, groupId, courses } = group;
  return (
    <section id={groupId}>
      <div className="Institution__name first-institution">
        <div className="Institution__name__title" style={{ paddingTop: '20px' }}>
          <h5 className="pl-md-4">{name}</h5>
          <div className="course-options">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {courses &&
                  courses.map((course) => {
                    return <SwiperSlide key={course.id} course={course} />;
                  })}
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function SwiperSlide({ course }) {
  let { name, src, module_id } = course;
  const [imgErrorCount, setImgErrorCount] = useState(0);
  return (
    <Link to="#" data-toggle="modal" data-target={`#${module_id}-modal`} className="swiper-slide">
      <img
        src={`${process.env.REACT_APP_SERVER_URL}/images/courses/${src}`}
        className="course_image"
        alt={name}
        onError={({ currentTarget }) => {
          if (imgErrorCount < 4) {
            currentTarget.onerror = null;
            currentTarget.src = `/images/defaultCourse.png`;
            setImgErrorCount((pre) => pre + 1);
          }
        }}
      ></img>
      <div className="swiper-contents-name">
        <h6
          style={{
            fontSize: '13px',
            marginBottom: '5px',
          }}
        >
          {name}
        </h6>
      </div>
    </Link>
  );
}

export default withRouter(Home);
