import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import bootbox from 'bootbox';
import { toast } from 'react-toastify';
export const AuthContext = React.createContext();

const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [firstLogin, setFirstLogin] = useState();
  const [loading, setLoading] = useState(true);
  let serverUrl = process.env.REACT_APP_SERVER_URL;

  const loadUser = async () => {
    return axios({
      method: 'get',
      url: `${serverUrl}/check`,
    })
      .then((res) => {
        if (res.data.user) {
          setCurrentUser(res.data.user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadUser();
  }, []);

  // const checkUser = async () => {
  //   return axios({
  //     method: 'get',
  //     url: `${serverUrl}/check`,
  //     withCredentials: true,
  //   });
  // };

  const login = (data, history) => {
    axios({
      method: 'post',
      url: `${serverUrl}/api/v2/auth/login`,
      withCredentials: true,
      data: data,
    })
      .then((res) => {
        if (res.data.token) {
          window.localStorage.setItem('token', res.data.token);
          setCurrentUser(res.data.user);
          setFirstLogin(true)
          const isMBBS = res.data.user.userModules.some(module => module.userModuleId === "MBBS");
          const isMDMS = res.data.user.userModules.some(module => module.userModuleId === 'MDMS');
          if (isMBBS) {
            history.replace('/MBBS/dashboard');
          }
          else if (isMDMS) {
            history.replace('/MDMS/dashboard');
          }
          else {
            history.replace('/enroll');
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  };

  const requestReset = (data) => {
    return axios({
      method: 'post',
      url: `${serverUrl}/auth/login`,
      withCredentials: true,
      data: data,
    });
  };

  const registerUser = (data) => {
    return axios({
      method: 'post',
      url: `${serverUrl}/auth/register`,
      withCredentials: true,
      data: data,
    });
  };

  const updateProfile = async (data, moduleId) => {
    let res = await axios({
      method: 'post',
      url: `${serverUrl}/${moduleId}/profile/`,
      withCredentials: true,
      data,
    });
    if (res.data.user) {
      // setCurrentUser(res.data.user);
      setTimeout(() => {
        window.location.href = `/${moduleId}/profile`;
      }, 1000);
    }
    return res.data;
  };

  const updateCurrentUser = (currentUser) => {
    setCurrentUser(currentUser);
  };

  const logout = async () => {
    window.localStorage.setItem('token', null);
    setCurrentUser(null);
  };

  const verifyUser = (data, history) => {
    axios({
      method: 'post',
      url: `${serverUrl}/auth/verify`,
      withCredentials: true,
      data: data,
    })
      .then((res) => {
        if (res.data.token) {
          window.localStorage.setItem('token', res.data.token);
          setCurrentUser(res.data.user);
          if (res.data.user.userModules.length) {
            history.replace('/');
          } else {
            history.replace('/enroll');
          }
        }
      })
      .catch((err) => {
        bootbox.alert(err.response && err.response.data && err.response.data.message);
      });
  };

  const verifyReset = (data, setVerified) => {
    axios({
      method: 'post',
      url: `${serverUrl}/auth/verify`,
      withCredentials: true,
      data: data,
    })
      .then((res) => {
        if (res.data.token) {
          window.localStorage.setItem('token', res.data.token);
          setVerified(true);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        firstLogin,
        setFirstLogin,
        loading,
        login,
        requestReset,
        verifyReset,
        logout,
        updateProfile,
        updateCurrentUser,
        loadUser,
        registerUser,
        verifyUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const state = useContext(AuthContext);
  return state;
};

export default AuthContextProvider;
