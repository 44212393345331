import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import Preloader from '../Preloader/Preloader';
import './Notification.css';
import { useModuleContext } from '../../context/ModuleProvider';

function NotificationDetail({ data, location, history }) {
  let { url, serverUrl } = useModuleContext();
  const id = queryString.parse(location.search).id;
  let [announcement, setAnnouncement] = useState(data);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) {
      setLoading(true);
      axios({
        method: 'get',
        url: `${url}/teacher/pushNotification/id?id=${id}`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        withCredentials: true,
      }).then((res) => {
        setLoading(false);
        setAnnouncement(res.data.notification);
      });
    }
  }, []);
  return (
    <div className="w-100 px-sm-3 px-2">
      {!loading ? (
        announcement ? (
          <div className="key__dates">
            <div className="timeline-detail">
              <div className="pb-4 px-3 pt-3 px-sm-4 pt-sm-4 pb-sm-5">
                <div className="text-muted small">
                  <b>Event Date:</b>&nbsp;
                  {moment(announcement.notifDate).format('MMMM Do YYYY')} &nbsp;,
                  <span className="timee pl-2">
                    <b>Posted:</b>&nbsp;
                    {moment(announcement.created_at).fromNow()}
                  </span>
                </div>
                <div className="desc-One">
                  <div className="mb-2 pr-5">
                    <h3 className="mb-0 mt-2">{announcement.title}</h3>
                    <h6 className="text-muted">{announcement.about}</h6>
                    <div>
                      {announcement.link && (
                        <a
                          href={`${serverUrl}/download/resources/announcement/${announcement.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <button
                            className="major__btn__type button--gray"
                            style={{
                              background: '#007bff1f',
                              color: '#007bff',
                              padding: '8px 25px',
                            }}
                          >
                            <i className="fas fa-download" />
                            &nbsp;Download Attatchemnt
                          </button>
                        </a>
                      )}
                    </div>
                    <hr />
                  </div>
                  {announcement.description}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Notification Not Found.</div>
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
}

export default withRouter(NotificationDetail);
