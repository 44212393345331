import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import bootbox from "bootbox";
import moment from "moment";
import "./TestInstruction.css";
import { useModuleContext } from "../../context/ModuleProvider";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function TestInstruction(props) {
  const { moduleId, url } = useModuleContext();
  let id = queryString.parse(props.location.search).testId;
  let [checked, setChecked] = useState(false);
  let [info, setInfo] = useState({});

  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/test/info?testId=${id}`,
      withCredentials: true,
    }).then((res) => {
      console.log(res.data);
      setInfo(res.data.info);
    });
  }, []);

  const handleTerm = () => {
    setChecked(!checked);
  };
  const handleStart = () => {
    if (checked) {
      props.history.push(`/${moduleId}/test/start?testId=${id}`);
    } else {
      bootbox.alert("Please agree to the terms and conditions!");
    }
  };
  return (
    <section className="content">
      <div className="row justify-content-center">
        <div className="col-sm-10 col-12 px-sm-2 px-0">
          <div className="card border-0" style={{ boxShadow: "none" }}>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-7 card-body land">
                <div className="mb-5">
                  <h6>Quiz</h6>
                  <h4 className="font-weight-bold d-block">
                    {info.type && capitalize(info.type)} Quiz
                  </h4>
                  <p className="font-weight-bold small">
                    Conducted on&nbsp;
                    {moment
                      .utc(info.start)
                      .local()
                      .format("MMM DD, YYYY | HH:mm:ss")}
                    &nbsp; to &nbsp;
                    {moment
                      .utc(info.end)
                      .local()
                      .format("MMM DD, YYYY | HH:mm:ss")}
                  </p>
                </div>

                <div className="row">
                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="flex-row mb-4">
                      <span className="w-50 text-nowrap">
                        <span className="icon-labels">
                          <i className="fa fa-clock-o" />
                        </span>
                        &nbsp;
                        <p className="font-weight-bold pl-3">
                          {info.duration} minutes
                        </p>
                      </span>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="flex-row mb-4">
                      <span className="w-50 text-nowrap">
                        <span className="icon-labels">
                          <i className="fa fa-check" />
                        </span>
                        &nbsp;
                        <p className="font-weight-bold pl-3">
                          {info.total} marks
                        </p>
                      </span>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="flex-row mb-4">
                      <span className="w-50 text-nowrap">
                        <span className="icon-labels">
                          <i className="fa fa-file-alt" />
                        </span>
                        &nbsp;
                        <p className="font-weight-bold pl-3">
                          {info.count} questions
                        </p>
                      </span>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="flex-row mb-4">
                      <span className="w-50 text-nowrap">
                        <span className="icon-labels">
                          <i className="fa fa-file-alt" />
                        </span>
                        &nbsp;<p className="font-weight-bold pl-3">English</p>
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-12 text-left my-2 my-sm-0">
                    <div className="row">
                      {/* <div className="col-sm-6 col-6">
                        <div
                          className="btn flag"
                          id="flag_1"
                          style={{ color: "#f5378e", padding: "0px" }}
                          title="Flag this question"
                        >
                          <span className="fa-stack fa-lg">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-flag fa-stack-1x fa-inverse" />
                          </span>
                        </div>
                        <p
                          className="pl-md-3 pl-sm-2 pl-1"
                          style={{ fontSize: "12px" }}
                        >
                          Flag a question
                        </p>
                      </div> */}
                      <div className="col-sm-6 col-6">
                        <div
                          className="btn bookmark"
                          id="bookmark_1"
                          style={{ color: "#00BBFF", padding: "0px" }}
                          title="Mark for review"
                        >
                          <span className="fa-stack fa-lg">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-bookmark fa-stack-1x fa-inverse" />
                          </span>
                        </div>
                        <p
                          className="pl-md-3 pl-sm-2 pl-1"
                          style={{ fontSize: "12px" }}
                        >
                          Bookmark a question
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="accordion mt-3" id="accordian">
                      <div className="option">
                        <input
                          type="checkbox"
                          id="toggle1"
                          className="toggle"
                        />
                        &nbsp;
                        <label
                          className="title rad-10"
                          htmlFor="toggle1"
                          style={{ marginTop: "-20px" }}
                        >
                          Instructions
                        </label>
                        <div className="content mb-3">
                          <ul>
                            <li className="mb-2">
                              Students must complete the multiple-choice exam
                              within the allocated time frame given for the
                              exam.
                            </li>
                            <li className="mb-2">
                              Students must complete the {info.count}-question
                              multiple-choice exam within the {info.duration}
                              -minute time frame allotted for the exam.
                            </li>
                            <li className="mb-2">
                              Students must not stop the session and then return
                              to it. This is especially important in the online
                              environment where the system will "time-out" and
                              not allow the student or you to reenter the exam
                              site.
                            </li>
                            <li className="mb-2">
                              <i>
                                Note: Answers are not saved by the system until
                                you click on submit button. If you have to log
                                back in to complete your exam, your prior
                                answers will not remain. So, please check for a
                                stable internet connection.
                              </i>
                            </li>
                          </ul>
                        </div>
                        <input
                          type="checkbox"
                          name="agreement"
                          checked={checked}
                          onChange={handleTerm}
                          className="form-check-inline p-2"
                        />
                        <p className="d-inline">
                          I agree to the
                          <Link
                            to="#"
                            data-toggle="modal"
                            data-target="#myModal"
                            className="font-weight-bold"
                          >
                            &nbsp;terms and conditions
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="m-2 w-100">
                    <div
                      onClick={handleStart}
                      id="start"
                      className="float-right float-sm-none"
                    >
                      <button className="btn btn-dark px-5 py-3">Start</button>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>

              <div className="col-12 col-sm-5 card-body land d-md-block d-none">
                <img
                  src={`/images/instructions.jpg`}
                  alt="text-icon-indicator"
                  className="img-fluid media-object w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* The Modal */}
      <div className="modal fade" id="myModal">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">Terms and conditions</h5>
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <b>AGREEMENT BETWEEN USER AND Aavishkar Ent.</b>
              <p align="justify">
                The Smart Academy website comprises of various Web pages owned
                and operated by Aavishkar Ent. Pvt. Ltd. [Company]. The Smart
                Academy website offers various services to you. This Agreement
                explains our obligations to you, and your obligations to us in
                relation to the services offered to you through the website
                [hereinafter "Smart Academy"].
              </p>
              <br />
              <p align="justify">
                You will be asked to:
                <br />
                Accept or agree to the Terms by clicking “I accept”, where this
                option is made available to you by Smart Academy. You understand
                and agree that Smart Academy will treat your acceptance of the
                Terms from that point onwards.
              </p>
              <br />
              <p align="justify">
                The terms and conditions of use, as described herein, shall not
                be modified by you. Your use of the Smart Academy constitutes
                your agreement to all terms, conditions and notices displayed on
                Smart Academy.
              </p>
              <br />
              <b>MODIFICATION OF THESE TERMS OF USE</b>
              <p align="justify">
                The Company reserves the right to change the terms, conditions,
                and notices under which the Smart Academy website is offered,
                including but not limited to the charges associated with the use
                of the Our Smart Academy website. Such modified terms shall be
                displayed on the website. It will be your responsibility to
                check and review such terms from time to time.
              </p>
              <br />
              <b>SERVICES OFFERED BY Smart Academy:</b>
              <p align="justify">
                Smart Academy is engaged in the business of assessment tests
                with various educational institutions. Hence you will be
                provided with services relating to online examinations,
                assessment tests.
              </p>
              <br />
              <p align="justify">
                For registration with Smart Academy, you will be asked to
                provide personal details like Name, Address, Contact Number and
                other details. On successful registration, you will be provided
                with a Login Id and Password. For details of how we collect and
                use your personal information please refer to our Privacy
                Policy. The privacy policy is an integral part of this
                agreement. You are encouraged to read all the details of the
                privacy policy and check for any amendments from time to time.
              </p>
              <br />
              <p align="justify">
                Apart from facilitating online assessment of education
                institutes Smart Academy also provides certain other services as
                follows.
              </p>
              <br />
              <p align="justify">
                1) SMS SERVICE: Smart Academy may provide alerts, information to
                you through Mobile. To avail this service specific request in to
                company is required to be made. However mobile facility is
                subject to following
              </p>
              <br />
              <p align="justify">
                In the event of change in your mobile number, you agree that you
                will update your profile information including your mobile
                number on Smart Academy.
              </p>
              <br />
              <p align="justify">
                By this agreement you agree that company will not be
                responsible, if information forwarded through SMS is not
                received in time due to communication failure from telecom
                operator network.
              </p>
              <br />
              <p align="justify">
                2) ADVERTISING: Smart Academy may provide advertising facility
                to third party; the outsiders can submit their advertisements
                related to their products or services on Smart Academy.
                Advertising facility provided by the company is subject to
                agreement that, Smart Academy would not be responsible for any
                liability, misinformation, errors associated with such
                advertising information.
                <br />
                You further agree that, Company would not accept any claims of
                business loss, damages to group or individual due to dealing
                with companies whose advertisement is published on Smart
                Academy.
                <br />
              </p>
              <br />
              <b>GENERAL CONDITIONS</b>
              <p align="justify">
                <b>Acceptance of the Terms And Conditions: </b>The Smart Academy
                website is offered to you conditioned on your acceptance without
                modification of the terms, conditions, and notices contained
                herein. Your use of the Smart Academy website constitutes your
                agreement to all such terms, conditions, and notices
              </p>
              <br />
              <p align="justify">
                <b>No Unlawful or Prohibited Use</b> As a condition of your use
                of the Smart Academy website, you warrant to Smart Academy that
                you will not use or allow access to others to use the Smart
                Academy website for any purpose that is unlawful or prohibited
                by these terms, conditions, and notices. You may not use or
                allow access to others to use the Smart Academy Site in any
                manner which could damage, disable, overburden, or impair the
                Smart Academy website or interfere with any other party's use
                and access of the Smart Academy website. You may not obtain or
                attempt to obtain any materials or information through any means
                not intentionally made available or provided for through the
                Smart Academy websites
              </p>
              <br />
              <p align="justify">
                <b>
                  Disclosure of information by the Splashgain technology
                  Solutions Pvt Ltd
                </b>
                Company reserves the right at all times to disclose any
                information as necessary to satisfy any applicable law,
                regulation, legal process or governmental request, or to edit,
                refuse to post or to remove any information or materials, in
                whole or in part, in Company’s sole discretion
              </p>
              <br />
              <p align="justify">
                <b>
                  Materials Provided To Eklavvya or Posted At Any Eklavvya
                  website
                </b>{" "}
                Splashgain does not claim ownership of the materials or question
                bank data you upload to Smart Academy (including data related to
                any online assessment) or post, upload, input or submit to any
                Smart Academy website or its associated services (collectively
                "Submissions"). However, by posting, uploading, inputting,
                providing or submitting your Submission you are granting Smart
                Academy, its affiliated companies and necessary sublicenses
                permission to use your Submission in connection with the
                operation of their Internet businesses including publish your
                name in connection with your Submission or use it to manage
                online assessment process. Smart Academy does not disclose/
                distribute/ share any information which is confidential to your
                subscription or account (including question bank, exam applicant
                data etc)
              </p>
              <br />
              <p align="justify">
                <b>Guarantee as to rights related to submissions</b> By posting,
                uploading, inputting, providing or submitting your Submission
                you warrant and represent that you own or otherwise control all
                of the rights to your Submission as described in this section
                including, without limitation, all the rights necessary for you
                to provide, post, upload, input or submit the Submissions.
              </p>
              <br />
              <p align="justify">
                <b>SERVICE ELIGIBILITY</b> You represent and warrant that you
                (a) have not previously been suspended or removed from Smart
                Academy (b) are not a direct competitor of Smart Academy; (c) do
                not have more than one Smart Academy account at any given time;
                and (d) that you have full power and authority to enter into
                this Agreement and in doing so will not violate any other
                agreement to which you are a party.
              </p>
              <br />
              <p align="justify">
                <b>SECURE PASSWORD</b> You are requested to keep your password
                secure. You are not allowed to user other user’s account and
                also do not let other users use your account. You are
                responsible for all the activities or transactions done through
                your account. You should report of any incident of account
                breach at support@splashgain.com
              </p>
              <p align="justify">
                You are prohibited from transferring, selling or trading your
                Smart Academy account or any other information to any other
                party. You agree that, you will take those steps that are
                essential to ensure that Login ID and Password remains in your
                possession and is not made public. You further agree that, if
                your account or account information with Smart Academy is
                tampered or leaked by hacking or otherwise by any third party
                then, Smart Academy shall not be responsible for it.
              </p>
              <p align="justify">
                <b>TERMINATION OF YOUR ACCOUNT</b> You agree that in case of
                violation of terms and conditions and improper usage of your
                account, your account would be terminated by Smart Academy.Smart
                Academy holds all the rights to terminate your account in case
                of non compliance of regulations mentioned in this document
              </p>
              <br />
              <p align="justify">
                <b>PRIVACY POLICY</b> You should carefully read our full Privacy
                Policy before deciding to open account at Smart Academy. By
                registering onSmart Academy, you agree that you have accepted
                all the terms mentioned in the privacy policy of Smart Academy
              </p>
              <br />
              <p align="justify">
                <b>LIABILITY DISCLAIMER</b> THE INFORMATION, SOFTWARE, PRODUCTS,
                AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE Smart Academy
                website MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS.
                CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. Smart
                Academy AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
                CHANGES IN THE Smart Academy website AT ANY TIME. ADVICE
                RECEIVED VIA THE Smart Academy website SHOULD NOT BE RELIED UPON
                FOR PERSONAL, MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU
                SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE
                TAILORED TO YOUR SITUATION.
              </p>
              <br />
              <p align="justify">
                Smart Academy AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT
                THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
                ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
                RELATED GRAPHICS CONTAINED ON THE Smart Academy website FOR ANY
                PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL
                SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF
                ANY KIND. Smart Academy AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL
                WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
                SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL
                IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
              </p>
              <p align="justify">
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                SHALL Smart Academy AND/OR ITS SUPPLIERS BE LIABLE FOR ANY
                DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
                DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN
                ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE Smart
                Academy website, WITH THE DELAY OR INABILITY TO USE THE Smart
                Academy website OR RELATED SERVICES, THE PROVISION OF OR FAILURE
                TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE Smart Academy
                website, OR OTHERWISE ARISING OUT OF THE USE OF THE Smart
                Academy website, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
                STRICT LIABILITY OR OTHERWISE, EVEN IF Smart Academy OR ANY OF
                ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
                BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
                THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
                DISSATISFIED WITH ANY PORTION OF THE Smart Academy website, OR
                WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY
                IS TO DISCONTINUE USING THE Smart Academy website.
              </p>
              <br />
              <p align="justify">
                <b>TERMINATION/ACCESS RESTRICTION</b> Smart Academy reserves the
                right, in its sole discretion, to terminate your access to the
                Smart Academy website and the related services or any portion
                thereof at any time, without notice. GENERAL To the maximum
                extent permitted by law, this agreement is governed by the laws
                of the Govt of India. And you hereby consent to the exclusive
                jurisdiction and venue of courts in Pune, India. in all disputes
                arising out of or relating to the use of the Smart Academy
                website. Use of the Smart Academy website is unauthorized in any
                jurisdiction that does not give effect to all provisions of
                these terms and conditions, including without limitation this
                paragraph. You agree that no joint venture, partnership,
                employment, or agency relationship exists between you andSmart
                Academy as a result of this agreement or use of the Smart
                Academy website. Smart Academy's performance of this agreement
                is subject to existing laws and legal process, and nothing
                contained in this agreement is in derogation of Smart Academy's
                right to comply with governmental, court and law enforcement
                requests or requirements relating to your use of the Smart
                Academy website or information provided to or gathered by Smart
                Academy with respect to such use. If any part of this agreement
                is determined to be invalid or unenforceable pursuant to
                applicable law including, but not limited to, the warranty
                disclaimers and liability limitations set forth above, then the
                invalid or unenforceable provision will be deemed superseded by
                a valid, enforceable provision that most closely matches the
                intent of the original provision and the remainder of the
                agreement shall continue in effect. Unless otherwise specified
                herein, this agreement constitutes the entire agreement between
                the user and Smart Academy with respect to the Smart Academy
                website and it supersedes all prior or contemporaneous
                communications and proposals, whether electronic, oral or
                written, between the user and Smart Academy with respect to the
                Smart Academy website. A printed version of this agreement and
                of any notice given in electronic form shall be admissible in
                judicial or administrative proceedings based upon or relating to
                this agreement to the same extent an d subject to the same
                conditions as other business documents and records originally
                generated and maintained in printed form. It is the express wish
                to the parties that this agreement and all related documents are
                drawn up in English.
              </p>
              <p align="justify">
                <b>COPYRIGHT AND TRADEMARK NOTICES:</b> All contents of the
                Smart Academy website are copyrighted by the Company. All rights
                are reserved solely with the Company.
              </p>
              <p align="justify">
                <b>TRADEMARKS</b> The names of actual companies and products
                mentioned herein may be the trademarks of their respective
                owners. The example companies, organizations, products, people
                and events depicted herein are fictitious. No association with
                any real company, organization, product, person, or event is
                intended or should be inferred. Any rights not expressly granted
                herein are reserved.
              </p>
              <p align="justify">
                <b>
                  Notices and Procedure for Making Claims of Copyright
                  Infringement
                </b>{" "}
                For any claims of copyright infringement under Indian copyright
                law or any license related enquiries or issues or any other
                legal issues, please send an email to
                mail@aavishkarnepal.com.np. The email should state the subject
                very clearly and should contain all the relevant details such as
                name, designation and contact details of the sender. The
                information sought should be very clear without ambiguity.
                Kindly note that this email id is strictly for legal issues
                only. ALL INQUIRIES NOT RELEVANT TO THE FOLLOWING PROCEDURE WILL
                RECEIVE NO RESPONSE.
              </p>
              <br />
              <p align="justify">
                If you have any query about above mentioned terms and conditions
                then please drop us email at
              </p>
              <p>
                <a
                  href="mailto:mail@aavishkarnepal.com.np"
                  target="_blank"
                  className="Undeline"
                  rel="noopener noreferrer"
                >
                  mail@aavishkarnepal.com.np
                </a>
                or contact us at
              </p>
              <br />
              <p>
                <b>
                  Aavishkar Ent. Pvt. Ltd.,
                  <br />
                  Naya Bazar, Balaju
                  <br />
                  Kathmandu
                  <br />
                  Nepal{" "}
                </b>
              </p>
              <p>
                Please Note that, this disclaimer/terms of service notification
                is subject to change without notice.
                <br />
                Copyright Notice
                <br />
                © Aavishkar Enterprises Private Limited
                <label id="year" />
                .
                <br />
                All rights reserved.
                <br />
              </p>
            </div>
            {/* Modal footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(TestInstruction);
