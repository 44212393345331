import React, { useEffect, useState } from "react";
import axios from "axios";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import RNotFound from "./RNotFound";
import ReactMarkdown from "react-markdown";
import Preloader from "../Preloader/Preloader";
import { useModuleContext } from "../../context/ModuleProvider";
import bootbox from "bootbox";
import "./Revision.css";

function Revision({ location }) {
  const { url } = useModuleContext();
  const [revision, setRevision] = useState(null);
  const [removing, setRemoving] = useState(null);
  let { subjectId } = queryString.parse(location.search);

  useEffect(() => {
    axios({
      method: "get",
      url: `${url}/revision`,
    }).then((res) => {
      setRevision(res.data);
    });
  }, [url, subjectId]);

  const deleteRevision = (revisionId) => {
    setRemoving(true);
    axios({
      method: "delete",
      url: `${url}/revision?id=${revisionId}`,
    })
      .then((res) => {
        setRemoving(false);
        if (!res.data.deleted) {
          bootbox.alert(res.data.message);
        } else {
          let newRevision = revision.filter(
            (item) => item.revision_id !== revisionId
          );
          setRevision(newRevision);
          bootbox.alert(res.data.message);
        }
      })
      .catch((error) => {
        setRemoving(false);
      });
  };

  return revision ? (
    revision.length !== 0 ? (
      <section className="content">
        <div className="row">
          <div className="col-12 px-sm-2 px-0">
            {revision.map((item, index) => {
              return (
                <QuestionCard
                  key={item.revision_id}
                  index={index}
                  revision={item}
                  removing={removing}
                  deleteRevision={deleteRevision}
                  len={revision.length}
                />
              );
            })}
          </div>
        </div>
      </section>
    ) : (
      <RNotFound />
    )
  ) : (
    <Preloader />
  );
}

function QuestionCard({
  revision,
  index,
  len,
  removing,
  deleteRevision,
  reportQuestion,
}) {
  let { question, optionA, optionB, optionC, optionD, answer } =
    revision.regularQuestion;
  return (
    <div
      className="card border-none mx-2 radius-10"
      style={{ boxShadow: "none" }}
    >
      <div className="px-sm-3 px-2">
        <div className="test-question mb-2">
          <span>
            Revision Question {revision.revision_id}{" "}
            {`(${index + 1} of ${len})`}
          </span>
          <h3 className="card-title pb-2">
            <ReactMarkdown source={question} escapeHtml={false} />
          </h3>
        </div>
        <div className="form-check">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer1"
            defaultValue={1}
            checked={1 === parseInt(answer)}
            disabled
          />
          <label className="col-10 form-check-label" htmlFor="answer1260">
            <ReactMarkdown source={optionA} escapeHtml={false} />
          </label>
        </div>
        <div className="form-check pb-0">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer2"
            defaultValue={2}
            disabled
            checked={2 === parseInt(answer)}
          />
          <label className="col-10 form-check-label" htmlFor="answer1260">
            <ReactMarkdown source={optionB} escapeHtml={false} />
          </label>
        </div>
        <div className="form-check pb-0">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer3"
            defaultValue={3}
            checked={3 === parseInt(answer)}
            disabled
          />
          <label
            className="col-10 form-check-label answer"
            htmlFor="answer1260"
          >
            <ReactMarkdown source={optionC} escapeHtml={false} />
          </label>
        </div>
        <div className="form-check pb-0">
          <input
            className="col-2 form-check-input option-input radio answer"
            type="radio"
            id="answer4"
            defaultValue={4}
            checked={4 === parseInt(answer)}
            disabled
          />
          <label className="col-10 form-check-label" htmlFor="answer1260">
            <ReactMarkdown source={optionD} escapeHtml={false} />
          </label>
        </div>
        <br />
        <div className="box mb-4 mx-3">
          <div>
            <strong>
              <div className="d-block text-success small">
                The correct answer is:{" "}
              </div>
              <ReactMarkdown
                source={revision.regularQuestion[getOptionByValue(answer - 1)]}
                escapeHtml={false}
              />
            </strong>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            disabled={removing}
            onClick={() => deleteRevision(revision.revision_id)}
          >
            Remove
          </button>

          <button
            className="btn btn-primary ml-2"
            onClick={() => reportQuestion}
          >
            Report
          </button>
        </div>
      </div>
    </div>
  );
}

const getOptionByValue = (value) => {
  const options = ["optionA", "optionB", "optionC", "optionD"];
  return options[value];
};
export default withRouter(Revision);
