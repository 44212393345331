import React from 'react';

function DataNotFound() {
  return (
    <section className="content" style={{ backgroundColor: 'white', marginTop: '-25px', height: '100vh' }}>
      <div className="row">
        <div className="col-12">
          <div className="text-center" style={{ paddingTop: '40px' }}>
            <img src="/images/revision.gif" alt="NOT FOUND" style={{ width: '350px' }} />
          </div>
          <br />
          <div className="text-center text-muted">
            <div>
              <h2 style={{ padding: '10px', fontFamily: 'inherit' }} className="text-header">
                Sorry, No Data found.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DataNotFound;
