import React from 'react';
import './PopupModal.css';
import notice from '../../../public/images/notice.webp';
import { RxCross2 } from 'react-icons/rx';

const PopupModal = ({ closeModal, moduleId, setFirstLogin }) => {
    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <RxCross2
                        className="closeIcon"
                        onClick={() => { closeModal(false); setFirstLogin(false); }}
                    />
                </div>
                <div className="body">
                    <div className="imageWrapper">
                        <img src={notice} alt="notice" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupModal;
