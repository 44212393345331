import React, { useState, useEffect, useRef } from "react";
import { useModuleContext } from "../../context/ModuleProvider";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../context/AuthContextProvider";
import PaymentList from "./PaymentList";
import "./Payment.css";
import { toast } from "react-toastify";

function ZoomPayment() {
  let { url, moduleId, currentModule } = useModuleContext();
  let [loading, setLoading] = useState(false);
  let [submitting, setSubmitting] = useState(false);
  let [proceedError, setProceedError] = useState();
  const { register, handleSubmit, errors } = useForm();
  const { register: registerReferral, handleSubmit: submitReferral } =
    useForm();
  let { updateCurrentUser, currentUser } = useAuthContext();
  let clientUrl = process.env.REACT_APP_CLIENT_URL;

  let [subscriptionTypes, setSubscriptionTypes] = useState(false);
  let [selectedType, setSelectedType] = useState();
  let [referredType, setReferredType] = useState();
  let [selectedPayment, setSelectedPayment] = useState();

  const [referredPrice, setReferredPrice] = useState();
  const [referredError, setReferredError] = useState();
  const [referralCode, setReferralCode] = useState();

  const [zoomPay, setZoomPay] = useState({});

  let esewaPath =
    process.env.REACT_APP_ENV === "production"
      ? "https://esewa.com.np/epay/main"
      : "https://uat.esewa.com.np/epay/main";
  let su = `${clientUrl}/${moduleId}/esewa/zoom-success`;
  let fu = `${clientUrl}/${moduleId}/payment-failed?fu=zoom`;

  let history = useHistory();
  let khlatiRef = useRef();

  const paymentTypes = [
    { id: "khalti", title: "Khalti", logo: "/images/subscribe/khalti.png" },
    { id: "esewa", title: "Esewa", logo: "/images/subscribe/esewa.png" },
  ];

  let currentType = referredPrice ? referredType : selectedType;
  let amount = currentType && currentType.amount;

  // let amount = selectedType && selectedType.amount;
  let time = moment().unix();
  // let pid = selectedType && `${selectedType.id}-${selectedType.amount}-${time}`;
  let pid =
    selectedType &&
    `${selectedType.id}-${currentUser.id}-${selectedType.module_id}-${selectedType.amount}-${time}-${referralCode}`;

  var params = {
    tAmt: amount,
    amt: amount,
    psc: 0,
    pdc: 0,
    txAmt: 0,
    pid: pid,
    scd: process.env.REACT_APP_ESEWA_SCD,
    su: su,
    fu: fu,
  };

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: `${url}/zoom-plan-payment/live-group-list`,
      withCredentials: true,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.data) {
          let data = res.data.data.filter((el) => el.remaining_seats > 0);
          setSubscriptionTypes(data);
        } else {
          setSubscriptionTypes([]);
        }
        if (res.data.payment) {
          let data = { ...res.data.payment };
          if (res.data.liveGroup) {
            data = {
              ...data,
              groupTitle: res.data.liveGroup.title,
              groupAmount: res.data.liveGroup.amount,
            };
          }
          setZoomPay(data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [currentUser]);

  const handlePayment = (data) => {
    if (data.payment === "khalti") {
      //checkout.show({ amount: selectedType.amount * 100 });
      khaltiPaymentGateway();
    } else if (data.payment === "esewa") {
      esewaPayment();
    }
  };

  const esewaPayment = () => {
    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", esewaPath);
    for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    form.submit();
  };

  const khaltiPaymentGateway = () => {
    let currentType = referredPrice ? referredType : selectedType;

    let khaltiPath =
      process.env.REACT_APP_ENV === "production"
        ? "https://khalti.com/api/v2/epayment/initiate/"
        : "https://a.khalti.com/api/v2/epayment/initiate/";

    let time = moment().unix();
    let purchase_order_id =
      selectedType &&
      `${selectedType.id}-${selectedType.period}-${currentUser.id}-${selectedType.module_id}-${selectedType.amount}-${time}-${referralCode}`;

    const payload = {
      return_url: `${clientUrl}/${moduleId}/khalti/zoom-success`,
      website_url: `${clientUrl}/${moduleId}/dashboard`,
      amount: currentType.amount * 100,
      purchase_order_id,
      purchase_order_name: selectedType.title,
    };

    axios
      .post(`${url}/zoom-plan-payment/initiate-khalti-pay`, {
        khaltiPath,
        payload,
      })
      .then((res) => {
        window.location.href = res && res.data.payment_url;
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong! Please try again later!");
      });
  };

  const handleClose = function () {
    if (history.length > 1) {
      // this will take you back if there is history
      history.goBack();
    } else {
      history.pushState(`${moduleId}/dashboard`);
    }
  };

  const handleReferral = (data) => {
    let code = data.referralCode.toUpperCase();

    if (!code) {
      return alert("Please enter your referral code.");
    }

    if (selectedType) {
      axios
        .get(`${url}/referral/${code}`)
        .then((response) => {
          if (!response.data.data) {
            setReferralCode();
            setReferredError(true);
            setReferredPrice(null);
          } else {
            let newAmount = (
              selectedType.amount *
              (1 - response.data.data.discountPercent / 100)
            ).toFixed(2);

            setReferredType({
              ...selectedType,
              amount: newAmount,
            });

            setReferralCode(code);

            setReferredError(false);
            setReferredPrice(newAmount);
          }
        })
        .catch((err) => {
          if ((err.response.status = 400)) {
            setReferredError(true);
          }
        });
    } else {
      alert("Please select a subscription package first.");
    }
  };

  let checkout = khlatiRef.current;

  return (
    <div className="payment_main">
      <div className="container">
        <div
          className="row d-flex justify-content-center align-content-center align-items-center"
          style={{
            minHeight: "100vh",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div className="col-12 text-left px-0">
            <div
              className="back-element d-sm-none d-block py-2"
              style={{ position: "relative" }}
            >
              <div onClick={handleClose} className="text-muted">
                <i className="fas fa-arrow-left"></i>
                <p style={{ marginLeft: "8px" }}>Close</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 d-md-block d-none">
            <article className="payment__img">
              <div className="swiper-slide">
                <img
                  src="/images/examination.png"
                  alt="Video courses"
                  loading="lazy"
                />
                <div className="pt-5 text-center">
                  <h5 className="d-block fw-500 pr-0 mb-0">
                    Subject wise video &amp; tutorials
                  </h5>
                  <p className="small text-muted">
                    Live MCQ Tests, discussion & polls
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div className="col-md-8 col-sm-12 px-md-2 px-0">
            <div className="form-box px-md-4 px-sm-2 px-0">
              <form onSubmit={handleSubmit(handlePayment)}>
                <main className="payment">
                  <article className="payment__form my-sm-4 my-1">
                    <h1
                      className="payment__question mb-1"
                      style={{ fontSize: "20px", boxShadow: "none" }}
                    >
                      <img
                        src="/images/nameOnline.png"
                        width="100px"
                        alt="Video courses"
                        loading="lazy"
                      />{" "}
                      Zoom Class Plans
                    </h1>
                    <div class="row d-flex justify-content-between mb-2">
                      <span
                        className="d-block small"
                        style={{ fontSize: "12px" }}
                      >
                        Please choose a plan to proceed
                      </span>
                      {/* <a
                        href="#subDetail"
                        className="d-block text-muted font-weight-light text-underline small pl-2 no-wrap"
                        data-toggle="modal"
                        data-target="#subDetail"
                      >
                        <i className="fa fa-info-circle" />
                        &nbsp;What's included?
                      </a> */}
                    </div>

                    <div className="alert alert-warning border-0" role="alert">
                      <small style={{ fontSize: "12px" }}>
                        Payment is attributed only to selected test and
                        duration.
                      </small>
                    </div>

                    {zoomPay.status === "Success" && zoomPay.groupTitle ? (
                      <div className="fw-500">
                        You have taken {zoomPay.groupTitle} - RS:
                        {zoomPay.amount}
                      </div>
                    ) : (
                      <>
                        <p className="mb-2 fw-500">Select a plan:</p>

                        <SubscriptionList
                          subscriptionTypes={subscriptionTypes}
                          selectedType={selectedType}
                          setSelectedType={setSelectedType}
                          register={register}
                          errors={errors}
                        />

                        <p className="fw-500">Pay with:</p>
                        <PaymentList
                          subscriptionTypes={subscriptionTypes}
                          paymentTypes={paymentTypes}
                          selectedPayment={selectedPayment}
                          setSelectedPayment={setSelectedPayment}
                          register={register}
                          errors={errors}
                        />
                        {(referredPrice || referredError) && (
                          <DiscountBox
                            price={referredPrice}
                            error={referredError}
                          />
                        )}

                        <ProceedToPay
                          register={register}
                          proceedError={proceedError}
                          submitting={submitting}
                          handleClose={handleClose}
                          handleReferral={handleReferral}
                          submitReferral={submitReferral}
                          registerReferral={registerReferral}
                        />
                      </>
                    )}
                    {/* <DiscountBox /> */}
                  </article>
                </main>
              </form>
            </div>
          </div>

          {/* modal box for subscription details */}
          <div
            className="modal fade"
            id="subDetail"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="subDetailTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog"
              role="document"
              style={{
                width: "100%",
                height: "100%",
                marginTop: "1px",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="modal-content" style={{ height: "100%" }}>
                <div className="modal-header" style={{ minHeight: "65px" }}>
                  <h5 className="modal-title" id="AskTitle">
                    NameOnline Subscription Details | 3 mo / 6 mo / 12 mo
                  </h5>
                  <button
                    type="button"
                    className="close btn btn-link btn-sm px-2 py-1 m-1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div
                  className="modal-body"
                  style={{ minHeight: "calc(100vh - 84px)" }}
                >
                  Your subscription plan includes:
                  <ul className="fa-ul my-2">
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>
                      400+ hours of High Quality Video lessons
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>
                      Highly yield interactive courses
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>
                      Videos by top rated Mentors
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>
                      Interactive discussion, polls &amp; ranking
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>
                      Updated coursework, MCQ's & Mock tests
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>
                      CEE oriented tutorials
                    </li>
                    <li>
                      <i className="fa-li fa fa-check-square text-success"></i>
                      Unlimited Access of NameOnline
                    </li>
                  </ul>
                  <div className="alert alert-warning my-4 pl-0" role="alert">
                    <ul className="fa-ul my-2">
                      <li>
                        <i className="fa-li fa fa-angle-right text-secondary"></i>
                        Billed as a One Time payment based upon your subscribed
                        plans.
                      </li>
                      <li>
                        <i className="fa-li fa fa-angle-right text-secondary"></i>
                        Get more discounts on purchase of 6mo & 12mo packages.
                      </li>
                    </ul>
                  </div>
                  <div className="disclaimer pt-4">
                    <i>
                      Note: The resources and materials in the NameOnline app is
                      exclusively produced by the Name Institute. Please contact
                      Name Institute's administrator for course related queries.
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DiscountBox({ price, error }) {
  return (
    <div>
      {price && (
        <div
          className="alert alert-info mb-4 d-md-flex justify-content-between"
          role="alert"
        >
          <div>
            Congratulations!{" "}
            <span role="img" aria-label="confetti">
              🎊
            </span>{" "}
            Your code is applied. You can Proceed & Pay now.
          </div>
          <div className="mt-4 mt-md-0">Discounted Price: Rs. {price}</div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger mb-4 " role="alert">
          Sorry! The referral code is not available or has already expired.
          Please check your code and try again!
        </div>
      )}
    </div>
  );
}

function ProceedToPay({
  register,
  proceedError,
  submitting,
  handleClose,
  handleReferral,
  submitReferral,
  registerReferral,
}) {
  return (
    <div className="payment__btns mt-2">
      <div className="input-group mb-2">
        <input
          type="text"
          name="referralCode"
          className="form-control mb-4 mb-md-0 referral-input"
          placeholder="Have a referral code?"
          form="referralForm"
          ref={registerReferral()}
          style={{ textTransform: "uppercase" }}
        />

        <div className="input-group-append">
          <button
            type="submit"
            form="saveForm"
            onClick={submitReferral(handleReferral)}
            className="btn btn-danger mr-2 btn-primary ml-1 ml-md-2"
          >
            Apply
          </button>
          <button
            type={submitting ? "button" : "submit"}
            className="btn btn-dark btn--primary"
          >
            {submitting ? "Processing" : "Proceed & Pay"}
            &nbsp;
            <i className="fa fa-chevron-right" />
          </button>
          {proceedError && proceedError}

          <button
            type="button"
            onClick={handleClose}
            className="btn btn-danger ml-2 btn--primary"
          >
            <i className="fa fa-chevron-left" /> &nbsp; Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

function SubscriptionList({
  subscriptionTypes,
  selectedType,
  setSelectedType,
  register,
  errors,
}) {
  if (!subscriptionTypes) {
    return <div>Live groups not found.</div>;
  }
  if (subscriptionTypes.length === 0) {
    return <div>Not any live group added.</div>;
  }
  return (
    <>
      {subscriptionTypes &&
        subscriptionTypes.map((type) => {
          let { id, title, amount, description } = type;
          let selected = selectedType ? selectedType.id === id : false;
          return (
            <div key={id} className="">
              <div className="row">
                <div className="col-md-8 col-sm-7 col-6 py-sm-2 py-1 px-md-3 px-1 pr-sm-3 pr-2">
                  <input
                    type="radio"
                    className="burmanRadio__input"
                    id={`sub-${id}`}
                    onChange={(e) => {
                      setSelectedType(type);
                    }}
                    value={id}
                    checked={selected}
                    name="subscription"
                    ref={register({
                      required: "Subscription field is required.",
                    })}
                  />
                  <label
                    htmlFor={`sub-${id}`}
                    className="burmanRadio__label mb-0"
                  >
                    <span className="fw-500">
                      {title} {description ? ` -  ${description}` : ""}
                    </span>
                    &nbsp;
                  </label>
                </div>
                <div
                  className="col-md-4 col-sm-5 col-6 py-sm-2 py-1 px-md-3 px-0 px-1 border-faint"
                  htmlFor={`sub-${id}`}
                >
                  <div className="text-right">
                    <h5 className="mb-0 pr-0" style={{ fontWeight: 500 }}>
                      &nbsp; Rs. {amount}
                    </h5>
                    <p className="text-muted d-block mb-0 pr-0">
                      Total (Incl. of all charges)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className="text-danger ml-2 small">
        {errors.subscription && errors.subscription.message}
      </div>
    </>
  );
}

export default ZoomPayment;
