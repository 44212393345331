import React, { useContext, useState } from 'react';
// import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
// import SocialLogin from './SocialLogin';
import { AuthContext } from '../../context/AuthContextProvider';
import bootbox from 'bootbox';

function ResetForm({ history, location }) {
  let serverUrl = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);

  const [send, setSend] = useState(false);
  const [error, setError] = useState(null);

  const [verified, setVerified] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm();

  const [showPass, setShowPass] = useState(false);

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  let watchPass = watch('password');

  const { requestReset, verifyReset } = useContext(AuthContext);

  const submitForm = async (data) => {
    if (!send) {
      try {
        const res = await requestReset(data);
        if (res && res.data.error) {
          setError(res.data.message);
        } else {
          setSend(true);
        }
      } catch (error) {
        let response = error.response && error.response;
        let data = response && response.data;
        let message = data && data.message;
        bootbox.alert(message || 'Something went wrong');
      }
    } else {
      if (!verified) {
        await verifyReset(data, setVerified);
      } else {
        setLoading(true);
        axios({
          method: 'post',
          url: `${serverUrl}/auth/resetPass`,
          withCredentials: true,
          data: data,
        })
          .then((res) => {
            setLoading(false);
            bootbox.alert(res.data.message);
            history.replace('/login');
          })
          .catch((err) => {
            setLoading(false);
            bootbox.alert(err.response.data.message);
          });
      }
    }
  };
  return (
    <form className="authForm" id="loginForm" onSubmit={handleSubmit(submitForm)}>
      <div className="instructions__login">
        <div className="overlay__img d-sm-none" style={{ marginTop: '-120px', marginBottom: '50px' }}>
          <Link to="/">
            <img style={{ position: 'relative', width: '300px' }} alt="smart Logo" src="/images/paradise.svg" />
          </Link>
          <br />
          <p style={{ fontSize: '16px', marginLeft: '1.2em' }}>Leading Edu-Tech Company </p>
        </div>

        <h2 className="Welcom pb-0 mb-2">Reset Password?</h2>
        {!verified ? (
          <p className="small text-muted pl-1">Let's verify the account belongs to you.</p>
        ) : (
          <p className="small text-muted pl-1">Enter the new password you want to change.</p>
        )}
      </div>
      <div className="error">{error}</div>

      {!verified && (
        <>
          <div className={errors.phone ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                required: 'Phone number is required',
                pattern: {
                  value: /^9\d{9}|[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                  message: 'Invalid phone number',
                },
              })}
              disabled={send}
              name="phone"
              type="text"
              id="phone"
              placeholder="Phone number"
            />
            <label className="input-icon-label" htmlFor="email">
              <i className="fas fa-mobile-alt"></i>
            </label>
          </div>
          <div className="input-error">{errors.phone && errors.phone.message}</div>

          {send && (
            <div className={errors.token ? 'errorfield inputGroup' : 'inputGroup'}>
              <input
                ref={register({ required: 'Otp code is required' })}
                name="token"
                type="text"
                id="token"
                placeholder="Otp code"
              />
              <label className="input-icon-label" htmlFor="token">
                <i className="fas fa-mobile-alt"></i>
              </label>
            </div>
          )}
          <div className="input-error">{errors.token && errors.token.message}</div>
        </>
      )}

      {verified && (
        <>
          <div className={errors.password ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                required: 'Password is required',
                pattern: {
                  value: /^.{6,}$/,
                  message: 'Password must have at least 6 characters',
                },
              })}
              name="password"
              type={showPass ? 'text' : 'password'}
              // disabled={send}
              id="password"
              placeholder="New Password"
            />
            <label className="input-icon-label" htmlFor="email">
              <i class={`fa ${showPass ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={togglePassword}></i>
            </label>
          </div>
          <div className="input-error">{errors.password && errors.password.message}</div>

          <div className={errors.password ? 'errorfield inputGroup' : 'inputGroup'}>
            <input
              ref={register({
                required: 'Confirm Password is required',
                validate: (value) => {
                  return value === watchPass || 'Password does not match!';
                },
              })}
              name="confirm"
              type={showPass ? 'text' : 'password'}
              // disabled={send}
              id="confirm"
              placeholder="Confirm Password"
            />
            <label className="input-icon-label" htmlFor="email">
              <i class={`fa ${showPass ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={togglePassword}></i>
            </label>
          </div>
          <div className="input-error">{errors.confirm && errors.confirm.message}</div>
        </>
      )}

      <div className="inputGroup pt-3 pb-3 center-block">
        <button type="submit" id="login" className="btn btn-dark rounded shadow register modified">
          {loading ? 'Loading...' : verified ? 'Reset Password' : send ? 'Verify OTP' : 'Request OTP'}
        </button>
        <Link to="/login">
          <button className="btn btn-dark rounded shadow d-block mt-3" id="register">
            Go back to login&nbsp;<i className="fas fa-arrow-circle-left"></i>
          </button>
        </Link>
      </div>
      {/* <div className="forget-footer">
        <Link to="/login">Login</Link>
        <Link to="/login/register">Register</Link>
      </div>
      <SocialLogin /> */}
    </form>
  );
}

export default withRouter(ResetForm);
